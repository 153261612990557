import {setupDocumentMetadataComponent, setupVideoComponent} from "../setups/vue-components";
import {changeHistory} from "../utils/history_utils";

export const renderVideo = () => {
    const videoKey = 'video'

    const urlSearchParams = new URLSearchParams(window.location.search)
    const isVideo = urlSearchParams.has(videoKey)

    if (isVideo) {
        const videoPartialId = urlSearchParams.get(videoKey)
        changeHistory({[videoKey]: ''}, false)
        setupVideoComponent(document, videoPartialId)
        setupDocumentMetadataComponent(document, videoPartialId)
    } else {
        setupVideoComponent()
        setupDocumentMetadataComponent()
    }
}