<template>
  <div class="archive_month_picker">
    <Button
        variant="secondary"
        type="button"
        @click="toggleShow"
        :toggle-text="false"
        :text="$t('genios.archive.monthPicker.buttonText')"
    >
      <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="icon"/>
    </Button>
    <div
        v-if="show"
        class="archive_month_picker__picker"
        v-click-outside="onClickOutside"
    >
      <div class="archive_month_picker__picker__year">
        <Button
            border="none"
            variant="icon"
            type="button"
            :disabled="oldestYearReached()"
            @click="yearDecrement"
            :toggle-text="false"
            style="background: inherit"
        ><ion-icon src="/icons/custom/chevron-back-outline.svg"></ion-icon></Button>
        <input
            v-if="yearInput"
            type="number"
            @blur="noYearInput"
            v-click-outside="noYearInput"
            v-model="selectedYear"/>
        <span
            v-else
            @click="yearInput=true;selectedMonth=null"
        >{{ selectedYear }}</span>
        <Button
            border="none"
            variant="icon"
            type="button"
            @click="yearIncrement"
            :toggle-text="false"
            style="background: inherit;"
        >
          <ion-icon src="/icons/custom/chevron-forward-outline.svg"/>
        </Button>
      </div>
      <div class="archive_month_picker__picker__months">
        <div
            class="archive_month_picker__picker__months__month"
            v-for="month in Array.from(Array(12).keys())"
        >
          <Button
              border="none"
              :variant="month === selectedMonth ? 'primary' : 'secondary'"
              :disabled="!checkInRange(month)"
              type="button"
              @click="selectMonth(month)"
              :text="monthsText(month)"
              :toggle-text="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Button from "../styled/Button.vue";
import i18n from "../plugins/Translations.vue";
import moment from "moment";

export default {
  name: "ArchiveMonthPicker",
  components: {Button},
  props: {
    firstMonth: String,
    latestMonth: String,
    oldestMonth: String
  },
  data() {
    return {
      selectedMonth: this.getInitialMonth(),
      selectedYear: this.getInitialYear(),
      show: false,
      yearInput: false
    }
  },
  computed: {
    icon: function () {
      return this.show ? 'rotate-180' : '';
    },
    latestMonthMoment: function () {
      return moment(this.latestMonth, 'YYYYMM').endOf('month');
    },
    oldestMonthMoment: function () {
      return moment(this.oldestMonth, 'YYYYMM').startOf('month');
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    monthsText: function (month) {
      return i18n.t('genios.calendar.month.' + (month + 1) + '.long')
    },
    toggleShow: function () {
      this.show = !this.show;
    },
    selectMonth: function (month) {
      this.selectedMonth = month;
      const momentOfChoice = moment();
      momentOfChoice.set("year", this.selectedYear);
      momentOfChoice.set("month", this.selectedMonth);
      momentOfChoice.add(1, "month");
      console.log(momentOfChoice.format('YYYYMM'));
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('before', momentOfChoice.format('YYYYMM'));
      window.location.href = newUrl.href;
    },
    yearIncrement: function () {
      const latestYear = moment(this.latestMonth, 'YYYYMM').format('YYYY');
      if (this.selectedYear < latestYear) {
        this.selectedMonth = null;
        this.selectedYear++;
      }
    },
    yearDecrement: function () {
      this.selectedMonth = null;
      this.selectedYear = Math.max(0, (this.selectedYear - 1));
    },
    onClickOutside: function () {
      this.show = false;
    },
    noYearInput: function () {
      this.yearInput = false;
    },
    checkInRange: function (month) {
      let momentToCheck = moment();
      momentToCheck.set("year", this.selectedYear);
      momentToCheck.set('month', month);
      momentToCheck = momentToCheck.startOf('month');
      return momentToCheck.isSameOrBefore(this.latestMonthMoment) && momentToCheck.isSameOrAfter(this.oldestMonthMoment);
    },
    getInitialYear: function () {
      if (this.firstMonth) {
        return moment(this.firstMonth, 'YYYYMM').year();
      }
      return new Date().getFullYear();
    },
    getInitialMonth: function () {
      if (this.firstMonth) {
        return moment(this.firstMonth, 'YYYYMM').month();
      }
      return null;
    },
    oldestYearReached: function () {
      const startOfSelectedYear = moment(this.selectedYear, 'YYYY').startOf('year');
      return startOfSelectedYear.isSameOrBefore(this.oldestMonthMoment);
    }
  }
}
</script>

<style scoped>
</style>
