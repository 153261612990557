<template>
  <div class="lds-ring" :style="`width: ${size - 8}px; height: ${size - 8}px`">
    <div v-for="{} in [1,2,3,4]" :style="`width: ${size - 8}px; height: ${size - 8}px; margin: 0;`"/>
  </div>
</template>

<script>
export default {
  name: "ButtonLoader",
  props: {
    size: {
      type: [String, Number],
      default: "100"
    }
  }
}
</script>