<template>
  <div class="checked_documents_container" v-click-outside="$emit('close-widget')">
    <overlay-scrollbars
        :options="{
            scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
            overflowBehavior: { x: 'hidden'}
           }"
    >
      <div class="checked_documents_list">
        <div class="checked_document_line"
             v-for="item in displayValues"
             :key="item.docId">
          <div class="checked_document_title" :title="item.source">{{ itemName(item) }}</div>
          <Button
              type="button"
              name="close"
              @click="removeItem(item.docId)"
              icon="close-outline"
              variant="icon"
          />
        </div>
      </div>
    </overlay-scrollbars>
    <Button
        name="removeAll"
        :text="$t('genios.removeAll')"
        @click="removeAll"
        variant="secondary"
        border="none"
        color="#CC1D33 !important"
        decoration="underline"
        :toggle-text="false"
    />
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";
import {checkSingleDocument} from "../../../functions/checks";
import {rememberCheckedDocument, rememberCheckedSources} from "../../../functions/components/selectionComponent";
import vClickOutside from "v-click-outside";

export default {
  name: "CheckedDocuments",
  components: {Button, 'overlay-scrollbars': OverlayScrollbarsComponent},
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    usedInSourceView: {
      type: Boolean,
      required: true
    },
    sharedSelectedItems: {
      type: Array,
      required: true
    },
    displayValues: {
      type: Array,
      required: true
    }
  },
  methods: {
    itemName(item) {
      if (this.usedInSourceView) {
        return item.source;
      } else {
        return item.title;
      }
    },
    removeItem(docId) {
      checkSingleDocument(docId, false);
      this.deleteSelectedItem(docId);
      if (!this.showCounter) {
        this.$emit("close-widget")
      }
    },
    deleteSelectedItem(docId) {
      if (this.usedInSourceView) {
        rememberCheckedSources(docId, false, "");
      } else {
        rememberCheckedDocument(docId, false, "", "");
      }
    },
    removeAll() {
      this.sharedSelectedItems.slice().forEach(element => {
        checkSingleDocument(element.docId, false);
        this.deleteSelectedItem(element.docId);
      });
      this.$emit("close-widget")
    },
  }
}
</script>

<style scoped>

</style>