<template>

  <fragment>
    <form id="sourceForm" @submit="saveElements">
      <div class="tooltip tooltip-icon my-sources__add-btn" v-if="showContent">
        <ButtonDiv
            variant="icon"
            icon="add-outline"
            @click="changeMode(SourceMode.CREATE)"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{
            $t("genios.tooltip.icons.plus")
          }}
        </div>
      </div>

      <div class="watchlist_dialog">
        <spinner v-if="isLoading"></spinner>
        <div v-else>
          <div v-if="mySourcesData.length === 0 && showContent">{{ $t("genios.mySources.emptyList") }}</div>
          <div
              v-else
              v-for="(source, index) in selectedSourceList"
              :key="index + '__' + source.name"
              class="filter__item"
          >
            <checkbox-form-element
                :element="{...source, id: 'check' + index, fieldLabel:source.name}"
                :passed-value="isSelected(source.name)"
                @modified="toggleSelection(source.name)"/>


            <div class="my-sources__item__action-bar" v-if="showContent">
              <div class="tooltip tooltip-icon">
                <button-div
                    variant="icon"
                    class="my-sources__icon"
                    icon="pencil-outline"
                    @click="changeMode(SourceMode.UPDATE, source.name)"
                />
                <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">
                  {{ $t("genios.tooltip.icons.pen") }}
                </div>
              </div>
              <div class="tooltip tooltip-icon">
                <button-div
                    variant="icon"
                    icon="trash-outline"
                    class="my-sources__icon"
                    :loading="removeLoader === source.name"
                    @click="removeMySource($event, source.name)"
                />
                <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">
                  {{ $t("genios.tooltip.icons.garbageCan") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-select
            v-if="mySourceCollection.length > 0"
            class="watchlistSelection"
            :searchable="false"
            :clearable="false"
            v-model="newSelected"
            :value="newSelected"
            label="name"
            @close="$emit('close-select-el')"
            :options="mySourceCollection">
          <template #open-indicator="{ attributes }">
            <ion-icon v-bind="attributes" src="/icons/custom/chevron-down-outline.svg"/>
          </template>
        </v-select>

      </div>

      <div v-if="showContent && noDocumentsSelected" class="my-sources__error">
        {{ $t("genios.mySources.noSelection") }}
      </div>

      <div v-else class="info">{{ info }}</div>

      <div v-if="errorCode !== null" class="my-sources__error">{{ $t(errorCode) }}</div>

      <div class="watchlist_dialog__button_line">
        <Button
            name="close"
            variant="secondary"
            @click="$emit('close-sources')"
            :text="$t('genios.close')"
            :toggle-text="false"
        />
        <Button
            name="save"
            variant="primary"
            @click="saveElements"
            :disabled="this.selectedItems.length === 0"
            :text="$t(primaryBtnTextCode)"
            :toggle-text="false"
            :loading="btnLoader"
            position="left"
            style="min-width: 70px;"
        />
      </div>
    </form>
  </fragment>

</template>


<script>

import axios from "axios";
import vSelect from "vue-select";

import Spinner from "../styled/Spinner.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";
import ButtonDiv from "../styled/ButtonDiv.vue";
import {
  ActionType,
  getMediaTiles,
  SourceMode,
  updateMySourcesInSharedState
} from "../../../functions/components/mySourceComponent";
import {deleteMySource} from "../../../functions/services/mySourcesService";
import {setupMySourcesIcon} from "../../../functions/setups";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";

export default {
  name: "MySourcesAddComponent",
  components: {ButtonDiv, Button, Spinner, vSelect, CheckboxFormElement},
  props: {
    sharedState: Object,
    dbShortcut: {
      type: String,
      required: false,
      default: null
    },
    currentSourceMode: {
      type: String,
      required: true
    },
    mySourcesData: {
      type: Array,
      required: true
    },
    primaryBtnTextCode: {
      type: String,
      required: true
    },
    textCode: {
      type: String,
      required: true
    },
    showContent: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mySourceCollection: [],
      selectedSourceList: [],
      selectedItems: [],
      errorCode: null,
      newSelected: i18n.t("genios.mySources.selectWatchlist"),
      defaultNrCheckBox: 3,    // 3 -->  3 (or 4 if just one more is available) checkboxes are shown at the beginning
      removeLoader: '',
      btnLoader: false
    };
  },
  mounted() {
    this.loadMySources(this.mySourcesData);
  },
  computed: {
    SourceMode() {
      return SourceMode;
    },
    noDocumentsSelected() {
      return this.sharedState.selectedSources == null || this.sharedState.selectedSources < 1;
    },
    info() {
      return i18n.tc(this.textCode, this.sharedState.selectedSources.length, {count: this.sharedState.selectedSources.length})
    }
  },
  watch: {
    newSelected: function (value) {
      const defaultValue = i18n.t("genios.mySources.selectWatchlist");
      if (value !== defaultValue) {
        this.selectedSourceList.push(value);
        this.addOrRemove(this.mySourceCollection, value);
        this.toggleSelection(value.name);
        this.newSelected = defaultValue;
      }
    },
    mySourcesData(newValue, oldValue) {
      //when logging in we need to update this.mySourceCollection because sharedState.mySources where loaded

      if (!_.isEqual(newValue, oldValue)) {
        this.loadMySources(newValue)
      }
    }
  },
  methods: {
    loadMySources(data) {
      this.mySourceCollection = _.cloneDeep(data)
      // just show the max of 'defaultNrCheckBox' checkboxes ;  the others via select-box
      const watchlistSize = this.mySourceCollection.length;
      for (let i = 0; i < watchlistSize; i++) {
        this.selectedSourceList.push(this.mySourceCollection.shift());
        if (i >= (this.defaultNrCheckBox - 1) && this.mySourceCollection.length > 1) {
          break;
        }
      }

      this.errorCode = null;
    },
    toggleSelection(watchlistId) {
      this.addOrRemove(this.selectedItems, watchlistId);
    },
    isSelected(watchlistId) {
      return this.selectedItems.indexOf(watchlistId) > -1;
    },
    saveElements(event) {
      event.preventDefault();
      event.stopPropagation();

      this.btnLoader = true

      const {data, ...rest} = this.buildAxiosConfig();

      axios({data, ...rest}).then((response) => {
        response.data.forEach(list => {
          updateMySourcesInSharedState(list);
        });

        const mediaTiles = getMediaTiles(this.dbShortcut)
        setupMySourcesIcon(mediaTiles);

        this.errorCode = null;

        this.$emit('save-source');
        this.$emit('close-sources', event);
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorSave";
        console.error("Save-Watchlistentries " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      }).finally(() => {
        this.btnLoader = false
        this.updateBubbles()
      });
    },
    async updateBubbles() {
      //trick to not reload whole page just for bubbles
      fetch(window.location.href).then((response) => {
        response.text().then((text) => {
            const newDoc = new DOMParser().parseFromString(text, 'text/html')
            const newBubbles = newDoc?.body?.querySelector('.media_type_selector__flexWrapper')
            const oldBubbles = document.querySelector('.media_type_selector__flexWrapper');
            if(newBubbles && oldBubbles) {
                oldBubbles.outerHTML = newBubbles.outerHTML
            }
        })
      })
    },
    addOrRemove(array, value) {
      const index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    },
    changeMode(mode, value) {
      this.$emit('update-mode', {mode, value});
    },
    buildAxiosConfig() {
      return {
        method: 'put',
        url: "/api/mySources/updateLists",
        data: this.currentSourceMode === SourceMode.DELETE ? this.updateLists() : this.addToLists()
      };

    },
    addToLists() {
      let data = [];

      let mySourceNames = this.selectedItems;
      mySourceNames.forEach(sourceName => {
        let itemData = {
          name: undefined,
          sourceTextIds: undefined
        };

        itemData.name = sourceName;

        const selectedSourceItem = this.selectedSourceList.find(list => list.name === sourceName) || null
        const subTags = !!selectedSourceItem ? selectedSourceItem.subTags : []

        itemData.sourceTextIds = subTags.map(item => item.textId) || []; //add old

        const newDBs = !!this.dbShortcut ? [this.dbShortcut] : this.sharedState.selectedSources.map(source => source.docId?.split("__")[0])

        itemData.sourceTextIds.push(...newDBs); // add new

        itemData.sourceTextIds = _.uniq(itemData.sourceTextIds);

        data.push(itemData);
      })

      return data
    },
    updateLists() {
      let data = [];

      let selectedItems = this.selectedItems;

      let newList = this.selectedSourceList.filter(item => selectedItems.includes(item.name));

      newList.forEach(item => {
        item.subTags = item.subTags.filter(db => db.textId !== this.dbShortcut);
      });

      newList.forEach(item => {
        let itemData = {
          name: undefined,
          sourceTextIds: undefined
        };

        itemData.name = item.name;

        itemData.sourceTextIds = item.subTags.map(source => source.textId);

        data.push(itemData);
      });

      return data;
    },
    async removeMySource(event, sourceName) {
      event.stopPropagation();
      this.removeLoader = sourceName;
      try {
        const {status} = await deleteMySource(sourceName);
        if (status === 200) {
          const elToRemove = this.selectedSourceList.find(item => item.name === sourceName);
          this.selectedSourceList = this.selectedSourceList.filter(item => item.name !== sourceName);
          updateMySourcesInSharedState(elToRemove, ActionType.REMOVE)
          const mediaTiles = getMediaTiles()
          setupMySourcesIcon(mediaTiles).then();
        }
      } catch (e) {
        console.log("removeMySource:error => ", e);
        console.dir(e);
      } finally {
        this.removeLoader = '';
      }
    },
  }
}

</script>

<style scoped>

#sourceForm {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#sourceForm .button__primary, #sourceForm .button__secondary {
  display: flex !important;
}

.filter__item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.filter__item .form_element {
  flex: 1;
}
.my-sources__item__action-bar{
  margin-bottom: 20px;
}
#sourceForm .form_element__label{
  gap: 10px;
}

</style>
