<template>
  <div>
    <Button
        variant="secondary"
        :text="$t(filter.title)"
        icon="/icons/custom/chevron-down-outline.svg"
        class="source__button"
        :class="showForm === filter.name ? 'active': ''"
        :rotate-icon="showForm === filter.name"
        :disabled="!filter.active"
        :toggle-text="false"
        @click="updateShowForm"
    />
    <transition name="grow">
      <standard-popup-component
          v-show="showForm === filter.name"
          variant="standard"
          class="source__popup"
          :ref="`sourcePopup__${filter.name}`"
          tabindex="-1"
          @close-popup="$emit('update-show-form', '')"
      >
        <source-content :filter="filter" :shared-state="sharedState" @close-window="$emit('update-show-form', '')"/>
      </standard-popup-component>
    </transition>
  </div>
</template>

<script>
import StandardPopupComponent from "../../styled/StandardPopupComponent.vue";
import Button from "../../styled/Button.vue";
import SourceContent from "./SourceContent.vue";
import {browseFiltersMethodsMixin} from "../../../../functions/mixins/browseFilters";

export default {
  name: "SourceFilterElement",
  components: {
    SourceContent,
    Button,
    StandardPopupComponent
  },
  mixins: [browseFiltersMethodsMixin]
}
</script>