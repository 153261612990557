import datepicker from "js-datepicker";
import moment from "moment";

import i18n from "../../vue/components/plugins/Translations.vue";

import {closeTransparentPopupModal} from "../closing";
import {setupLoginComponent} from "../setups/vue-components";
import {openTransparentPopupModal} from "../opens";
import {PaymentMode} from "../components/payment";
import qs from "qs";
import {sourceIconState, SourceMode} from "../components/mySourceComponent";

export const buildDatePicker = function (id, element, selectCallback, maxDate = null) {
    // noinspection JSUnusedLocalSymbols
    const picker = datepicker(element, {
        id: id,
        disableYearOverlay: true,
        position: 'c',
        startDay: 1,
        customDays: [
            i18n.t("genios.calendar.day.1.short"),
            i18n.t("genios.calendar.day.2.short"),
            i18n.t("genios.calendar.day.3.short"),
            i18n.t("genios.calendar.day.4.short"),
            i18n.t("genios.calendar.day.5.short"),
            i18n.t("genios.calendar.day.6.short"),
            i18n.t("genios.calendar.day.7.short")
        ],
        customMonths: [
            i18n.t("genios.calendar.month.1.long"),
            i18n.t("genios.calendar.month.2.long"),
            i18n.t("genios.calendar.month.3.long"),
            i18n.t("genios.calendar.month.4.long"),
            i18n.t("genios.calendar.month.5.long"),
            i18n.t("genios.calendar.month.6.long"),
            i18n.t("genios.calendar.month.7.long"),
            i18n.t("genios.calendar.month.8.long"),
            i18n.t("genios.calendar.month.9.long"),
            i18n.t("genios.calendar.month.10.long"),
            i18n.t("genios.calendar.month.11.long"),
            i18n.t("genios.calendar.month.12.long")
        ],
        customOverlayMonths: [
            i18n.t("genios.calendar.month.1.short"),
            i18n.t("genios.calendar.month.2.short"),
            i18n.t("genios.calendar.month.3.short"),
            i18n.t("genios.calendar.month.4.short"),
            i18n.t("genios.calendar.month.5.short"),
            i18n.t("genios.calendar.month.6.short"),
            i18n.t("genios.calendar.month.7.short"),
            i18n.t("genios.calendar.month.8.short"),
            i18n.t("genios.calendar.month.9.short"),
            i18n.t("genios.calendar.month.10.short"),
            i18n.t("genios.calendar.month.11.short"),
            i18n.t("genios.calendar.month.12.short")
        ],
        overlayPlaceholder: i18n.t("genios.year"),
        overlayButton: i18n.t("genios.calendar.jump"),
        maxDate: maxDate,
        onSelect: instance => {
            if (instance.dateSelected) {
                instance.lastDate = instance.dateSelected;
                selectCallback(moment(instance.dateSelected).format('DD.MM.YYYY'))
            } else if (instance.lastDate) {
                // prevent toggling
                instance.setDate(instance.lastDate, true);
                instance.hide();
                selectCallback(moment(instance.dateSelected).format('DD.MM.YYYY'))
            }
        },
        onShow: instance => {
            function offset(el) {
                let rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
            }

            let inputOffset = offset(element);

            // move to just below input (or above if not enough room below)
            if (inputOffset.top < (window.innerHeight - 300)) {
                instance.calendarContainer.style.top = (inputOffset.top + 45) + "px";
            } else {
                instance.calendarContainer.style.top = Math.max(0, (inputOffset.top - 300)) + "px";
            }
            instance.calendarContainer.style.left = Math.max(0, Math.min(window.innerWidth - 300, (inputOffset.left - 20))) + "px";

            setIonIconDatePicker(instance);

            document.body.appendChild(instance.calendarContainer);
        },
        onMonthChange: instance => {
            setIonIconDatePicker(instance);
        },
        formatter: (input, date, instance) => {
            input.value = date.toLocaleDateString('de-DE');
        }
    });

    const setIonIconDatePicker = instance => {
        const forward = instance.calendarContainer.querySelector(".qs-arrow.qs-right");
        const back = instance.calendarContainer.querySelector(".qs-arrow.qs-left");
        back.innerHTML = "<ion-icon src='/icons/custom/chevron-back-outline.svg'><ion-icon/>"
        forward.innerHTML = "<ion-icon src='/icons/custom/chevron-forward-outline.svg'><ion-icon/>"
    }

    picker.calendarContainer.style.setProperty('font-size', '14px');
    picker.calendarContainer.style.setProperty('font-family', 'Open-Sans');

    picker.setDateFromString = function (dateString, from = true) {
        if (dateString) {
            const inputDate = moment(dateString, 'D.M.YYYY', true);
            const inputMonth = moment(dateString, 'M.YYYY', true);
            const inputYear = moment(dateString, 'YYYY', true);
            if (inputDate.isValid()) {
                const newDate = inputDate.toDate();
                this.setDate(newDate, true);
                this.lastDate = newDate;
            } else if (inputMonth.isValid()) {
                const month = from ? inputMonth : inputMonth.endOf('month');
                const newDate = month.toDate();
                this.setDate(newDate, true);
                this.lastDate = newDate;
            } else if (inputYear.isValid()) {
                const year = from ? inputYear : inputYear.endOf('year');
                const newDate = year.toDate();
                this.setDate(newDate, true);
                this.lastDate = newDate;
            } else {
                this.setDate();
            }
        } else {
            this.setDate();
        }
    };

    return picker;
}

export const buildHistoryState = function () {
    const searchResultDiv = document.querySelector("#partialSearchResult");
    const browseDiv = document.querySelector("#partialSourceListContainer");
    const mode = (!!searchResultDiv) ? "search" : (!!browseDiv) ? "browse" : "";

    const mainScrollPosition = !!window.pageOverlayScrollbar ? window.pageOverlayScrollbar.scroll().position : {
        x: 0,
        y: 0
    }

    return {
        mode: mode,
        sharedState: window.sharedState,
        mainScrollPosition: mainScrollPosition,
        results: mode === "search" ? searchResultDiv.outerHTML : undefined,
        browse: mode === "browse" ? browseDiv.outerHTML : undefined
    }
}

//strange
export const runFunctionAfterLoginDialog = function (messageCode, callbackFunction = function () {
}) {
    if (!!window.sharedState && !!window.sharedState.loginStatus && window.sharedState.loginStatus.loggedIn) {
        callbackFunction();
    } else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", messageCode, function () {
            closeTransparentPopupModal();
            callbackFunction();
        });
    }
}

export const buildPaymentApiCall = (documentIds, mode, orderResponse, params) => {
    let fetchUrl = `/api/retrieveDocument/${documentIds[0]}`
    let config = {}
    switch (mode) {
        case PaymentMode.multiDocuments:
            fetchUrl = `/api/retrieveDocuments`
            params.ids = documentIds
            break
        case PaymentMode.remoteAgency:
            fetchUrl = `/remoteAgencies/orderCompanyInfo/${documentIds[0]}`
            params = qs.stringify(params)
            config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            break
        case PaymentMode.ePaper:
            fetchUrl = `/api/orderEPaper`
            params.issueId = documentIds[0]
            params.issueDate = orderResponse.issueDate
            params = JSON.stringify(params)
            config = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            }
            break
        case PaymentMode.attachment:
            fetchUrl = `/attachment/${documentIds[0]}?type=${orderResponse.attachmentType}`
            params = JSON.stringify(params)
            config = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            }
            break
        case PaymentMode.video:
            fetchUrl = `/video/${documentIds[0]}?type=${orderResponse.type}&videoId=${orderResponse.videoId}`
            params = JSON.stringify(params)
            config = {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            }
            break
    }

    return {fetchUrl, params, config}
}

export const buildInfoForMySourcesComponent = (data, iconType) => {
    const info = {
        showContent: false,
        currentMode: SourceMode.ADD,
        primaryBtnTextCode: "genios.watchlist.add",
        textCode: "genios.mySources.singleAdd",
        data: data || window.sharedState.mySources || []
    }

    if (iconType === sourceIconState.SERVER) {
        info.currentMode = SourceMode.DELETE
        info.primaryBtnTextCode = "genios.delete"
        info.textCode = "genios.mySources.removeText"
        info.data = window.sharedState.currentMySources.data || data
    } else if (iconType === sourceIconState.OUTLINE) {
        if (info.data.length === 0) {
            info.textCode = "genios.mySources.singleEmptyList"
        }
    }


    return info
}

