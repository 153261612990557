<template>
  <div class="payment__form">
    <div class="payment__form__line_1">
      <div>
        <TextFormElement
            :passed-value="creditCard.information.company.value"
            :element="creditCard.information.company"
            :focus="focus"
            :error="getError('company').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'company',value:$event})"
        />
        <Error v-show="getError('company').value" :error="getError('company').text"/>
      </div>
      <div>
        <TextFormElement
            :passed-value="creditCard.information.department.value"
            :element="creditCard.information.department"
            :focus="focus"
            @focus="focus = $event"
            @modified="updateForm({name: 'department',value:$event})"
        />
      </div>
    </div>
    <div class="payment__form__line_2">
      <div>
        <RadioGroupComponent
            :group="salutationRadioBtns"
            :initial-value="creditCard.information.salutation"
            direction="row"
            @update="updateForm({name: 'salutation', value: $event})"
        />
        <Error v-show="getError('salutation').value" :error="getError('salutation').text"/>
      </div>
    </div>

    <div class="payment__form__line_1">
      <div>
        <TextFormElement
            max="30"
            :passed-value="creditCard.information.firstName.value"
            :element="creditCard.information.firstName"
            :focus="focus"
            :error="getError('firstName').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'firstName',value:$event})"
        />
        <Error v-show="getError('firstName').value" :error="getError('firstName').text"/>
      </div>
      <div>
        <TextFormElement
            :passed-value="creditCard.information.lastname.value"
            :element="creditCard.information.lastname"
            :focus="focus"
            :error="getError('lastname').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'lastname',value:$event})"
        />
        <Error v-show="getError('lastname').value" :error="getError('lastname').text"/>
      </div>
    </div>
    <div class="payment__form__line_3">
      <div>
        <TextFormElement
            :passed-value="creditCard.information.street.value"
            :element="creditCard.information.street"
            :focus="focus"
            :error="getError('street').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'street',value:$event})"
        />
        <Error v-show="getError('street').value" :error="getError('street').text"/>
      </div>
      <div>
        <TextFormElement
            :passed-value="creditCard.information.streetNumber.value"
            :element="creditCard.information.streetNumber"
            :focus="focus"
            :error="getError('streetNumber').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'streetNumber',value:$event})"
        />
        <Error v-show="getError('streetNumber').value" :error="getError('streetNumber').text"/>
      </div>
    </div>

    <div class="payment__form__line_4">
      <div>
        <TextFormElement
            :passed-value="creditCard.information.zipcode.value"
            :element="creditCard.information.zipcode"
            :focus="focus"
            :error="getError('zipcode').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'zipcode',value:$event})"
        />
        <Error v-show="getError('zipcode').value" :error="getError('zipcode').text"/>
      </div>
      <div>
        <TextFormElement
            :passed-value="creditCard.information.city.value"
            :element="creditCard.information.city"
            :focus="focus"
            :error="getError('city').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'city',value:$event})"
        />
        <Error v-show="getError('city').value" :error="getError('city').text"/>
      </div>
      <div>
        <DropdownFormElement
            :element="creditCard.information.countryCode"
            :passedValue="creditCard.information.countryCode.options.find(option => option.value === 'DE')"
            :error="getError('countryCode').value"
            @modified="updateForm({name: 'countryCode', value: $event.value})"
        />
        <Error v-show="getError('countryCode').value" :error="getError('countryCode').text"/>
      </div>
    </div>

    <div class="payment__form__line_5" v-if="showTaxIdNumber">
      <div>
        <TextFormElement
            :passed-value="creditCard.information.taxIdNumber.value"
            :element="creditCard.information.taxIdNumber"
            :focus="focus"
            :error="getError('taxIdNumber').value"
            @focus="focus = $event"
            @modified="updateForm({name: 'taxIdNumber',value:$event})"
        />
        <Error v-show="getError('taxIdNumber').value" :error="getError('taxIdNumber').text"/>
      </div>
    </div>

  </div>
</template>

<script>
import i18n from "../../plugins/Translations.vue";
import TextFormElement from "../../FormElements/TextFormElement.vue";
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import RadioGroupComponent from "../../FormElements/radio/RadioGroupComponent.vue";

import Error from "../../styled/Error.vue";

import en_locale from "i18n-iso-countries/langs/en.json";
import de_locale from "i18n-iso-countries/langs/de.json";
import {isUndefined} from "lodash";
import {isCountryWithVatId} from "../../../../functions/components/payment";

export default {
  name: "InvoiceFormElement",
  components: {RadioGroupComponent, Error, TextFormElement, DropdownFormElement, i18n},
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      focus: null,
      salutationRadioBtns: i18n.t('orderingProcess.mainForm.paymentMethod.form.salutation.options').map(item => {
        return {
          id: `radio_${item.value}`,
          fieldLabel: item.text,
          value: item.value
        }
      }),
      creditCard: {
        information: {
          company: {
            id: 'company',
            field: 'company',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.company'),
            fieldType: 'text',
            value: ''
          },
          department: {
            id: 'department',
            field: 'department',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.companyDepartment'),
            fieldType: 'text',
            value: ''
          },
          salutation: '',
          firstName: {
            id: 'firstName',
            field: 'firstName',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.firstname'),
            fieldType: 'text',
            value: ''
          },
          lastname: {
            id: 'lastname',
            field: 'lastname',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.surname'),
            fieldType: 'text',
            value: ''
          },
          street: {
            id: 'street',
            field: 'street',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.street'),
            fieldType: 'text',
            value: ''
          },
          streetNumber: {
            id: 'streetNumber',
            field: 'streetNumber',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.houseNumber'),
            fieldType: 'text',
            value: ''
          },
          zipcode: {
            id: '"zipcode"',
            field: '"zipcode"',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.zipcode'),
            fieldType: 'text',
            value: ''
          },
          city: {
            id: 'city',
            field: 'city',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.city'),
            fieldType: 'text',
            value: ''
          },
          countryCode: {
            id: 'countryCode',
            value: '',
            options: this.buildCountryCodeOptions(),
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.countryCode')
          },
          taxIdNumber: {
            id: 'taxIdNumber',
            field: 'taxIdNumber',
            fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.form.taxIdNumber'),
            fieldType: 'text',
            value: ''
          },
        }
      }
    }
  },
  computed: {
    showTaxIdNumber() {
      return isCountryWithVatId(this.creditCard.information.countryCode.value)
    }
  },
  methods: {
    updateForm(value) {
      if (!isUndefined(this.creditCard.information[value.name].value)) {
        this.creditCard.information[value.name].value = value.value;
      }
      this.$emit('update', value);
    },
    getError(error) {
      const item = this.errors.find(item => item.object === error)
      if (item !== undefined)
        return {
          value: true,
          text: item.text
        }
      return {
        value: false,
        text: ''
      }
    },
    buildCountryCodeOptions() {
      const countryList = window.navigator.language === "de" ? de_locale : en_locale;
      return Object.entries(countryList.countries).map(([key, value]) => {
        return {
          text: Array.isArray(value) ? value[0] : value,
          value: key
        }
      });
    }
  },
  watch: {
    errors(newValue) {
      this.errors = newValue
    }
  }
};
</script>
