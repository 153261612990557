<template>
  <div>
    <div class="media_preview__main" :id="documentNumber">

      <!--        id="36129A__content"-->
      <div class="media_preview__main__id">
        <div class="media_preview__main_content">
          <div class="media_preview__main_content__preview">
            <div class="media_preview__main_content__preview__image">
              <img alt="" loading="lazy" :src="img">
            </div>
            <!--      TODO: Commented in FUNKTIONEN-10947, uncomment when this button will be ready to use -->
          </div>
          <div class="media_preview__main_content__fields">
            <div class="media_preview__main_content__fields__text">
              <span class="media_preview__main_content__fields__text__tag_tile hide">{{ documentType }}</span>
              <div class="media_preview__main_content__fields__text__top">
                <div class="media_preview__main_content__fields__name popup" js-handler="miniPopup">
                  <div>{{ sourceName }}</div>
                  <button type="button" aria-label="info">
                    <ion-icon name="information-circle-outline" aria-label="information circle outline" role="img"
                              class="md hydrated"/>
                  </button>
                  <div class="description popuptext">
                    <div class="popuptext-content">
                      <div class="popuptext-content__close">
                        <div class="popuptext-content__title">{{ $t("documentMetadata.sourceDescription.title") }}</div>
                        <button type="button" class="button__icon">
                          <ion-icon name="close-outline" aria-label="close outline" role="img"
                                    class="md hydrated"></ion-icon>
                        </button>

                      </div>
                      <div class="border-line"></div>
                      <div class="popuptext-content__logo">
                        <img alt="" loading="lazy" :src="sourceImg"
                             onerror="this.style.display='none'">
                      </div>
                      <div class="border-line"></div>
                      <div class="popuptext-content__text" v-html="sourceDescription"/>
                    </div>
                  </div>
                </div>
                <div class="media_preview__main_content__fields__field_D">
                  {{ author }}
                </div>
                <div class="spacer"></div>
                <field-i :field-i-list="field_IList"/>
              </div>
            </div>
          </div>
        </div>
        <div class="border-line"></div>

        <div class="media_preview__extra_line">
          <div>
            {{ date }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="showVideoList">
      <h3>{{ $t("documentMetadata.videoListTitle") }}</h3>
      <video-play-list
          v-for="(video, index) in videoContent"
          :key="database +'__' +video.partialId"
          :index="index + 1"
          :item="video"
          :document-id="documentId"
          :document-number="documentNumber"
          :current-item="currentVideo"
          :documentUrl="documentUrl"
      />
    </div>

    <div v-if="showAttachmentsList">
      <div class="border-line"></div>
      <h3>{{ $t("documentMetadata.attachmentListTitle") }}</h3>
      <attachments-list
          v-for="(attachment, index) in attachments"
          :key="attachment.docId +'__' + index"
          :attachment="attachment"
          :index="index + 1"
      />
    </div>

    <metadata-footer
        :medata-footer-list="medataFooterList"
        :document-url="documentUrl"
        :copyright="copyright"
    />
  </div>
</template>

<script>
import VideoPlayList from "../video/VideoPlayList.vue";
import MetadataFooter from "./MetadataFooter.vue";
import AttachmentsList from "./AttachmentsList.vue";
import FieldI from "./fieldI.vue";
import eventBus from "../../../eventBus";
import {AttachmentType} from "../../../functions/consts";
import {getVideoPrices} from "../../../functions/services";
import {setupMiniPopup} from "../../../functions/setups";

export default {
  name: "DocumentMetadata",
  components: {FieldI, AttachmentsList, MetadataFooter, VideoPlayList},
  props: {
    category: {
      type: String,
      required: true
    },
    keywords: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    courseLength: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    documentNumber: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    documentType: {
      type: String,
      required: true
    },
    sourceName: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    videoContent: {
      type: Array,
      required: true
    },
    documentUrl: {
      type: String,
      required: true
    },
    videoPartialId: {
      type: String,
      required: false
    },
    attachments: {
      type: [Array, String],
      default: []
    },
    quizQuestions: {
      type: String,
      required: true
    },
    copyright: String,
    sourceDescription: String
  },
  data() {
    return {
      currentVideo: this.videoContent.find(item => item.partialId === this.videoPartialId) || this.videoContent[0],
      footerData: {
        category: this.category || null,
        keywords: this.keywords || null,
        quizQuestions: this.quizQuestions || null,
        documentNumber: this.documentNumber || null,
      },
      field_I: {
        date: this.date || null,
        language: this.language || null,
        courseLength: this.courseLength || null,
      }
    }
  },
  computed: {
    img() {
      return `/img?db=${this.database}&doc_id=${this.documentNumber}`
    },
    database() {
      return this.documentId.split('__')[0]
    },
    showVideoList() {
      return this.videoContent.length > 1
    },
    showAttachmentsList() {
      return this.attachments.length > 0
    },
    legacyDocId() {
      return this.database + "__" + this.documentNumber;
    },
    medataFooterList() {
      const res = []
      for (const item in this.footerData) {
        const data = this.footerData[item]
        if (!!data) {
          const obj = {
            code: item,
            value: data
          }
          res.push(obj)
        }
      }
      return res
    },
    field_IList(){
      const res = []
      for (const item in this.field_I) {
        const data = this.field_I[item]
        if (!!data) {
          const obj = {
            code: item,
            value: data
          }
          res.push(obj)
        }
      }
      return res
    },
    sourceImg() {
      return `/resource/logos-big/${this.database.toLowerCase()}.png`
    }
  },
  methods: {
    async updateVideoPrices() {
      const requestBody = this.videoContent.map(video => {
        const videoId = `${this.database}__${video.partialId}`
        return {
          attachmentType: AttachmentType.VIDEO,
          documentId: videoId,
          priceCategory: "",
          database: this.database
        }
      })
      const data = await getVideoPrices(requestBody);
      console.log("videoPrices => ", data)
      const videos = document.querySelectorAll('[js-handler*="updateVideoPrices"]')
      videos.forEach(video => {
        const videoLegacyId = video.dataset.videoLegacyDocId, priceInfo = data[videoLegacyId]
        if (!!priceInfo) {
          const {paid, visited} = priceInfo
          if (!!paid) video.classList.add("paid")
          if (!!visited) video.classList.add("visited")
        }
      })
    }
  },
  async mounted() {
    // await this.updateVideoPrices()
    setupMiniPopup(this.$el)
    eventBus.$on("update-video", async (video) => {
      this.currentVideo = video
      // await this.updateVideoPrices()
    })
  },
}
</script>

<style scoped>

</style>