import Vue from "vue";

export const notImplementedVueAlert = function (vue) {
    vue.$fire({
        html: "<p>Vielen Dank, dass Sie die neue BETA-Version von Genios.de testen!</p>" +
            "<h4>Diese Funktionalität ist aktuell noch in Arbeit.</h4>" +
            "<p>Wir freuen uns aber über Ihr Feedback gerne an <a href='mailto: relaunch@genios.de'>relaunch@genios.de</a>.</p>" +
            "Herzlichst Ihr<br> \n" +
            "Friedrich Weniger<br>" +
            "Leitung Produktmanagement", type: "warning",
        confirmButtonColor: '#EF7C00',
        customContainerClass: 'container-shadow'
    });
}

export const setupNotImplementedAlert = function (node, vue) {
    if (!!node) {
        const notImplementedElements = node.querySelectorAll(".not_implemented");
        let fireVue = vue;
        notImplementedElements.forEach(x => {
            if (!vue) {
                if (!window.notImplementedVue) {
                    window.notImplementedVue = new Vue({name: "notImplementedVue"});
                }
                fireVue = window.notImplementedVue;
            }
            x.addEventListener("click", () => notImplementedVueAlert(fireVue));
        });
    }
}

export const horizontalScrollRecommendations = function () {

    const articles = document.querySelector('.recommendation__articles');
    const scroller = document.querySelector('.recommendation__article');
    const leftArrow = document.getElementById('recommendation_leftArrow');
    const rightArrow = document.getElementById('recommendation_rightArrow');

    if (articles != null) {
        if (rightArrow) {
            rightArrow.onclick = function () {
                const max = scroller.scrollWidth - articles.offsetWidth;
                scroller.style.transform = "translateX(-" + max + "px)";
            }
        }
        if (leftArrow) {
            leftArrow.onclick = function () {
                scroller.style.transform = "translateX(" + 0 + "px)";
            };
        }
    }
}
