<template>
  <div class="payment__form">
    <div class="payment__form__line_1">
      <TextFormElement
          :passed-value="company.value"
          :element="company"
          :focus="focus"
          :error="!!(errors.company)"
          @focus="focus = $event"
          @modified="updateForm({company: $event})"
      />
      <TextFormElement
          :passed-value="companyDepartment.value"
          :element="companyDepartment"
          :focus="focus"
          :error="!!(errors.companyDepartment)"
          @focus="focus = $event"
          @modified="updateForm({companyDepartment: $event})"
      />
    </div>
    <div class="payment__form__line_2">
      <DropdownFormElement
          :element="salutation"
          :passedValue="salutation.options[0]"
          :error="!!(errors.salutation)"
          @modified="updateForm({salutation: $event.value})"
          ref="salutation"
      />
      <TextFormElement
          :passed-value="firstName.value"
          :element="firstName"
          :focus="focus"
          :error="!!(errors.firstName)"
          @focus="focus = $event"
          @modified="updateForm({firstName: $event})"
      />
      <TextFormElement
          :passed-value="surname.value"
          :element="surname"
          :focus="focus"
          :error="!!(errors.surname)"
          @focus="focus = $event"
          @modified="updateForm({surname: $event})"
      />
    </div>
    <div class="payment__form__line_3">
      <TextFormElement
          :passed-value="street.value"
          :element="street"
          :focus="focus"
          :error="!!(errors.street)"
          @focus="focus = $event"
          @modified="updateForm({street: $event})"
      />
      <TextFormElement
          :passed-value="houseNumber.value"
          :element="houseNumber"
          :focus="focus"
          :error="!!(errors.houseNumber)"
          @focus="focus = $event"
          @modified="updateForm({houseNumber: $event})"
      />
    </div>

    <div class="payment__form__line_4">
      <TextFormElement
          :passed-value="zipcode.value"
          :element="zipcode"
          :focus="focus"
          :error="!!(errors.zipcode)"
          @focus="focus = $event"
          @modified="updateForm({zipcode: $event})"
      />
      <TextFormElement
          :passed-value="city.value"
          :element="city"
          :focus="focus"
          :error="!!(errors.city)"
          @focus="focus = $event"
          @modified="updateForm({city: $event})"
      />
      <DropdownFormElement
          :passedValue="countryCode.options.find(option => option.value === 'DE')"
          :element="countryCode"
          :error="!!(errors.countryCode)"
          @modified="updateForm({countryCode: $event.value})"
          ref="countryCode"
      />
    </div>

    <div class="payment__form__line_5">
      <TextFormElement
          :passed-value="email.value"
          :element="email"
          :focus="focus"
          :error="!!(errors.email)"
          @focus="focus = $event"
          @modified="updateForm({email: $event})"
      />
    </div>

  </div>
</template>

<script>
import i18n from "../../plugins/Translations.vue";
import en_locale from "i18n-iso-countries/langs/en.json";
import de_locale from "i18n-iso-countries/langs/de.json";

import TextFormElement from "../../FormElements/TextFormElement.vue";
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import {isUndefined} from "lodash";

export default {
  name: "FullInfoComponent",
  components: {TextFormElement, DropdownFormElement},
  props: {
    errors: Object,
    values: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      focus: null,
      form: {
        countryCode: "DE",
        ...this.values
      },
      company: {
        id: 'company',
        field: 'company',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.company'),
        fieldType: 'text',
        value: ''
      },
      companyDepartment: {
        id: 'companyDepartment',
        field: 'companyDepartment',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.companyDepartment'),
        fieldType: 'text',
        value: ''
      },
      salutation: {
        id: 'salutation',
        fieldValue: '',
        options: i18n.t('orderingProcess.requestReason.form.salutation.options').map(item => {
          return {text: item, value: item}
        }),
        fieldLabel: i18n.t('orderingProcess.requestReason.form.salutation.title')
      },
      firstName: {
        id: 'firstName',
        field: 'firstName',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.firstname'),
        fieldType: 'text',
        value: ''
      },
      surname: {
        id: 'surname',
        field: 'surname',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.surname'),
        fieldType: 'text',
        value: ''
      },
      street: {
        id: 'street',
        field: 'street',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.street'),
        fieldType: 'text',
        value: ''
      },
      houseNumber: {
        id: 'houseNumber',
        field: 'houseNumber',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.houseNumber'),
        fieldType: 'text',
        value: ''
      },
      zipcode: {
        id: 'zipcode',
        field: 'zipcode',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.postcode'),
        fieldType: 'text',
        value: ''
      },
      city: {
        id: 'city',
        field: 'city',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.city'),
        fieldType: 'text',
        value: ''
      },
      countryCode: {
        id: 'countryCode',
        fieldValue: '',
        options: this.buildCountryCodeOptions(),
        fieldLabel: i18n.t('orderingProcess.requestReason.form.countryCode.title')
      },
      email: {
        id: 'email',
        field: 'email',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.email'),
        fieldType: 'text',
        value: ''
      }
    }
  },
  mounted() {
    const isValue = !!Object.keys(this.values).length
    if (isValue) this.setValues(this.values)

    this.$nextTick(() => {
      this.validate();
      this.updateForm({})
    })
  },
  methods: {
    setValues(newValue) {
      Object.entries(newValue).forEach(([key, value]) => {
        if (!isUndefined(this[key])) {
          if (!!this[key].options) {
            const valueIndex = this[key].options.findIndex(item => item.value === newValue[key])
            this.$refs[key].currentOption = this[key].options[valueIndex]
          }

          this[key].value = value
        }
      })
    },
    updateForm(value) {
      this.form = {
        ...this.form,
        ...value
      };
      this.$emit('update', this.form);
      this.validate();
    },
    validate() {
      const errors = {};
      if (!this.form.salutation) {
        errors.salutation = i18n.t('orderingProcess.requestReason.form.errors.salutation.missing');
      }
      if (!this.form.firstName) {
        errors.firstName = i18n.t('orderingProcess.requestReason.form.errors.firstname.missing');
      }
      if (!this.form.surname) {
        errors.surname = i18n.t('orderingProcess.requestReason.form.errors.surname.missing');
      }
      if (!this.form.street) {
        errors.street = i18n.t('orderingProcess.requestReason.form.errors.street.missing');
      }
      if (!this.form.houseNumber) {
        errors.houseNumber = i18n.t('orderingProcess.requestReason.form.errors.houseNumber.missing');
      }
      if (!this.form.zipcode) {
        errors.zipcode = i18n.t('orderingProcess.requestReason.form.errors.postcode.missing');
      }
      if (!this.form.city) {
        errors.city = i18n.t('orderingProcess.requestReason.form.errors.city.missing');
      }
      if (!this.form.countryCode) {
        errors.countryCode = i18n.t('orderingProcess.requestReason.form.errors.countryCode.missing');
      }
      if (!this.form.email) {
        errors.email = i18n.t('orderingProcess.requestReason.form.errors.email.missing');
      }
      this.$emit('validation', errors);
    },
    buildCountryCodeOptions() {
      const countryList = window.navigator.language === "de" ? de_locale : en_locale;
      return Object.entries(countryList.countries).map(([key, value]) => {
        return {
          text: Array.isArray(value) ? value[0] : value,
          value: key
        }
      });
    }
  },
}
</script>
