import axios from "axios";
import {API_MY_SOURCES_CREATE, API_MY_SOURCES_DELETE, API_MY_SOURCES_UPDATE} from "./endpoints";

export const createMySource = async body => {
    try {
        const {data} = await axios.post(API_MY_SOURCES_CREATE, body)
        return data
    } catch (e) {
        console.log("createMySource:error => ", e.message)
        console.dir(e)
    }
}

export const updateMySource = async (sourceName, body) => {
    try {
        await axios.put(API_MY_SOURCES_UPDATE, body, {
            params:{
                sourceName
            }
        })
    } catch (e) {
        console.log("createMySource:error => ", e.message)
        console.dir(e)
    }
}

export const deleteMySource = async (sourceName) => {
    try {
        return await axios.delete(API_MY_SOURCES_DELETE, {
            params:{
                sourceName
            }
        })
    } catch (e) {
        console.log("createMySource:error => ", e.message)
        console.dir(e)
    }
}
