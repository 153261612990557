<template>
  <div v-click-outside="onClickOutside" id="settings_menu_button" class="settings_menu_container">
    <Button
        name="setting"
        :text="$t('genios.customizeView.name')"
        variant="secondary"
        border="0px solid black"
        decoration="underline"
        @click="toggleSettingsPopup"
        :text-style="400"
        :click-effect="false"
        type="button"
    >
      <ion-icon src="/icons/custom/chevron-down-outline.svg" class="settings_text" :class="expanded?'rotate-180':''"/>
      <span class="settings_icon">
        <ion-icon name="settings-outline"></ion-icon>
      </span>
    </Button>

    <div class="button_submenu" v-bind:class="{hide: !expanded}">
      <p>{{ $t("genios.customizeView.name") }}</p>
      <div class="button_submenu__item">
        <div class="button_submenu__item__input">
          <dropdown-form-element
              :element="pageSizeSelect"
              class="fixedWidth"
              @focus="$emit('focus', $event)"
              @modified="getSelectData({pageSize: $event.value})"
              :passed-value="getPageSizeOption()"
          />
        </div>
      </div>
      <div class="button_submenu__item">
        <div class="button_submenu__item__input">
          <dropdown-form-element
              :element="sortBySelect"
              class="fixedWidth"
              @focus="$emit('focus', $event)"
              @modified="getSelectData({sortBy: $event.value})"
              :passed-value="getSortOption()"
          />
            <!-- The following buttons should really be radio-buttons, but FW insists that it is correct to have these hideous buttons instead  -->
          <Button type="button" name="order" aria-label="sort order"
                  :title="$t('genios.customizeView.sortBy.asc')"
                  @click="setSortDesc(false)"
                  class="arrow-up-outline"
                  variant="icon"
          >
            <!-- The style stuff is a hack - but this is because FW insists on having these non-standard elements -->
            <ion-icon
                name="arrow-down-outline"
                src="/icons/custom/numerical_sorting_2.svg"
                :style="sortDescending?'':'color:var(--primary-color) !important;'"
            />
          </Button>
          <Button type="button" name="order" aria-label="sort order" :title="$t('genios.customizeView.sortBy.desc')"
                  @click="setSortDesc(true)"
                  class="arrow-up-outline"
                  variant="icon"
          >
            <ion-icon
                name="arrow-up-outline"
                src="/icons/custom/numerical_sorting.svg"
                :style="sortDescending?'color:var(--primary-color) !important;':''"
            />
          </Button>
        </div>
      </div>
      <div class="button_submenu__item" style="display: none;">
        <!--      TODO: show again once the resultListType is implemented-->
        <div class="button_submenu__item__input radio-genios-style">
          <div>
            <input type="radio" id="resultListTypeStandard" name="resultListType" value="DEFAULT"
                   v-model="resultListType">
            <label for="resultListTypeStandard">{{ $t("genios.customizeView.resultListType.DEFAULT") }}</label>
          </div>
          <div>
            <input type="radio" id="resultListTypeHideSurroundings" name="resultListType" value="HIDE_SURROUNDINGS"
                   v-model="resultListType">
            <label for="resultListTypeHideSurroundings">{{
                $t("genios.customizeView.resultListType.HIDE_SURROUNDINGS")
              }}</label>
          </div>
          <div>
            <input type="radio" id="resultListTypeTitleOnly" name="resultListType" value="TITLE_ONLY"
                   v-model="resultListType">
            <label for="resultListTypeTitleOnly">{{ $t("genios.customizeView.resultListType.TITLE_ONLY") }}</label>
          </div>
        </div>
      </div>
      <div class="button_line">
        <Button
            name="reset"
            type="reset"
            variant="secondary"
            :text="$t('genios.close')"
            @click="closeMenu"
            :toggle-text="false"
        />
        <Button
            name="submit"
            type="submit"
            variant="primary"
            :text="$t('genios.ok')"
            @click="applySettings"
            :toggle-text="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "./plugins/Translations.vue";
import vClickOutside from 'v-click-outside'
import DropdownFormElement from "./FormElements/DropdownFormElement.vue";
import Button from "./styled/Button.vue";
import {getAttributeSortBy, getAttributeSortOrder} from "../../functions/getters";
import {replaceSearchResult} from "../../functions/replacing";

export default {
  name: "SearchSettingsComponent",
  props: {
    sharedState: Object
  },
  components: {Button, DropdownFormElement},
  data() {
    return {
      expanded: false,
      pageSize: parseInt(this.sharedState.pageSize),
      pageSizeSelect: {
        id: 'search_result_page_size',
        field: 'pageSize',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: [10, 25, 50].map(i => {
          return {
            text: i + i18n.t('genios.customizeView.pageSize.xResults'),
            value: i
          }
        })
      },
      sortBy: this.sharedState.sort,
      sortBySelect: {
        id: 'search_result_sort_by',
        field: 'sortBy',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: ['BY_DATE', 'BY_RELEVANCE'].map(i => {
          return {
            text: i18n.t('genios.customizeView.sortBy.' + i),
            value: i
          }
        })
      },
      sortDescending: this.sharedState.order === "desc",
      resultListType: this.sharedState.resultListType ? this.sharedState.resultListType : "DEFAULT",
      selected: null
    };
  },
  mounted() {
    this.$nextTick(function () {
      let sortBy = getAttributeSortBy();
      this.sortBy = sortBy || "BY_RELEVANCE";
      let sortOrder = getAttributeSortOrder() || "desc";
      this.sortDescending = sortOrder === "desc";
      this.pageSize = parseInt(this.getAttributePageSize());
      this.resultListType = this.sharedState.resultListType;
    })
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getAttributePageSize() {

      const searchResultDiv = document.getElementById("partialSearchResult");
      let attributePageSize = "";
      if (typeof (searchResultDiv) !== 'undefined' && searchResultDiv !== null) {
        attributePageSize = searchResultDiv.getAttribute("page-size");
      }

      let pageSize
      if (attributePageSize.length !== 0) {
        pageSize = attributePageSize;
      }

      return pageSize;
    },
    getPageSizeOption() {
      const index = this.pageSizeSelect.options.findIndex(item => item.value === this.pageSize)
      return index === -1 ? this.pageSizeSelect.options[0] : this.pageSizeSelect.options[index]
    },
    getSortOption() {
      const index = this.sortBySelect.options.findIndex(item => item.value === this.sortBy)
      return index === -1 ? this.sortBySelect.options[0] : this.sortBySelect.options[index]
    },
    getSelectData(value) {
      this.selected = {...this.selected, ...value}
    },
    applySettings() {
      if (this.selected == null) {
        this.selected = {
          sort: window.sharedState.sort,
          pageSize: window.sharedState.pageSize
        }
      }
      this.closeMenu();
      this.resetOffset()
      window.sharedState.sort = this.selected?.sortBy || window.sharedState.sort;
      window.sharedState.pageSize = this.selected?.pageSize || window.sharedState.pageSize;
      window.sharedState.resultListType = this.resultListType || 'DEFAULT';
      window.sharedState.order = this.sortDescending ? 'desc' : 'asc'
      replaceSearchResult();
    },
    toggleSettingsPopup() {
      this.expanded = !this.expanded;
    },
    closeMenu() {
      this.expanded = false;
    },
    onClickOutside() {
      this.closeMenu()
    },
    resetOffset() {
      window.sharedState.offset = 0;
    },
    setSortDesc: function (sortDescending) {
      this.sortDescending = sortDescending;
    }
  }
}
</script>

<style scoped>
.arrow-up-outline {
  margin-left: -8px;
}
</style>
