<template>
  <div class="payment__template" id="orderRequestForm">
    <h1 class="payment__title">{{ $t("orderingProcess.orderRequest.title") }}</h1>
    <div class="payment__grid">
      <div class="payment__grid__item img">
        <img :src="formImg" alt="picture">
        <div>
          <p class="payment__message"><span>{{ $t("orderingProcess.orderRequest.form.companyNumber") }}</span>:
            {{ orderRequestModel.companyId }}</p>
          <p class="payment__message">{{ orderRequestModel.companyName }}</p>
          <p class="payment__message">{{ orderRequestModel.street }}</p>
          <p class="payment__message">{{ orderRequestModel.codeCity }}</p>
          <p class="payment__message">{{ orderRequestModel.country }}</p>
        </div>
      </div>

      <div class="payment__grid__item title">
        <p class="payment__message" v-if="orderRequestModel.isRefunded">{{
            $t("orderingProcess.orderRequest.refunded")
          }}</p>
        <p class="payment__message" v-else>{{ $t("orderingProcess.orderRequest.subtitle") }}</p>
      </div>

      <div class="payment__grid__item full-width">
        <p class="payment__message">{{ $t("orderingProcess.orderRequest.info") }}</p>
      </div>

      <div class="payment__grid__item price-info">
        <p class="payment__message">{{ orderRequestModel.orderRequestLabel }}: {{ orderPrice }}</p>
      </div>

      <div class="payment__grid__item border"/>

      <div class="payment__grid__item form">
        <div class="payment__form">
          <p class="payment__message">{{ $t("orderingProcess.orderRequest.form.contacts") }}</p>
          <TextFormElement
              :passed-value="primaryEmail.value"
              :element="primaryEmail"
              :focus="focus"
              :error="getErrorObj('primaryEmail').isError"
              @focus="focus = $event"
              @modified="updateForm({...primaryEmail, value: $event})"
          />
          <Error
              v-if="getErrorObj('primaryEmail').isError"
              :error="getErrorObj('primaryEmail').message"
          />
          <TextFormElement
              :passed-value="alternativeEmail.value"
              :element="alternativeEmail"
              :focus="focus"
              :error="getErrorObj('alternativeEmail').isError"
              @focus="focus = $event"
              @modified="updateForm({...alternativeEmail, value: $event})"
          />
          <Error
              v-if="getErrorObj('alternativeEmail').isError"
              :error="getErrorObj('alternativeEmail').message"
          />
        </div>
      </div>

      <div class="payment__form" v-if="isForeignCompanies">
        <p class="payment__subtitle">{{ $t("orderingProcess.orderRequest.form.legitimateInterest") }}</p>
        <p class="payment__message">{{ $t("orderingProcess.orderRequest.form.message") }}</p>
        <RadioGroupComponent
            :group="reasons"
            :initial-value="reasons[0].value"
            direction="column"
            @update="updateForm({...creditRatingReason, value: $event})"/>
      </div>

      <div class="payment__grid__item research-purpose" v-if="orderRequestModel.isDubReport">
        <ResearchPurpose
            :errors="errors"
            @update-form="updateForm($event)"
        />
      </div>

    </div>
    <p class="payment__hint" id="mandatory-field">
      <span>{{ $t("orderingProcess.orderRequest.form.mandatory") }}</span>
    </p>

    <div class="payment__actions">
      <Button
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
      />

      <Button
          type="button"
          variant="primary"
          :text="$t('orderingProcess.orderRequest.form.buttonText', {prise: orderPrice})"
          :toggle-text="false"
          @click="submit"
      />
    </div>

    <spinner v-if="loading"/>
    <AlternativeArticles
        v-else
        v-for="article in alternativeDocuments"
        :key="article.documentId"
        v-bind="article"
        :is-cref="orderRequestModel.isCref"
        :loading="loading"
    />
  </div>
</template>

<script>
import axios from "axios";

import i18n from "../../plugins/Translations.vue";
import TextFormElement from "../../FormElements/TextFormElement.vue";
import RadioGroupComponent from "../../FormElements/radio/RadioGroupComponent.vue";
import Button from "../../styled/Button.vue";
import AlternativeArticles from "./AlternativeArticles.vue";
import Spinner from "../../styled/Spinner.vue";
import TextAreaFormElement from "../../FormElements/TextAreaFormElement.vue";
import ResearchPurpose from "./ResearchPurpose.vue";
import Error from "../../styled/Error.vue";
import {validateFields} from "../../../../functions/forms/validators";
import {formMixin} from "../../../../functions/mixins/form";
import {getDocumentPrices} from "../../../../functions/services";

export default {
  name: "OrderRequestComponent",
  components: {
    ResearchPurpose,
    TextAreaFormElement,
    AlternativeArticles,
    Button,
    RadioGroupComponent,
    i18n,
    TextFormElement,
    Spinner,
    Error
  },
  props: {
    orderRequestModel: {
      type: Object
    },
    code: {
      type: String
    },
    orderDataId: {
      type: [String, Number],
      required: true
    }
  },
  mixins: [formMixin],
  data() {
    return {
      alternativeDocuments: [],
      form: {},
      errors: [],
      focus: null,
      loading: false,
      primaryEmail: this.generateField({
        id: 'primaryEmail',
        field: 'primaryEmail',
        fieldLabel: i18n.t('orderingProcess.orderRequest.form.primaryEmail'),
        fieldType: 'text',
        value: window.sharedState.defaultUserEmail || ''
      }, {
        required: true,
        type: "email"
      }),
      alternativeEmail: this.generateField({
        id: 'alternativeEmail',
        field: 'alternativeEmail',
        fieldLabel: i18n.t('orderingProcess.orderRequest.form.alternativeEmail'),
        fieldType: 'text',
        value: ''
      }, {
        type: "email"
      }),
      creditRatingReason: this.generateField({
        id: 'creditRatingReason',
        field: 'creditRatingReason',
        fieldLabel: 'creditRatingReason',
        fieldType: 'radio',
        value: ''
      })
    }
  },
  async created() {
    await this.getAlternativeDocumentsPrices()
  },
  mounted() {
    this.setupDefaultForm({
      primaryEmail: {
        ...this.primaryEmail,
        value: window.sharedState.defaultUserEmail
      },
      creditRatingReason: {
        ...this.creditRatingReason,
        value: this.reasons[0].value
      }
    })
  },
  computed: {
    isForeignCompanies() {
      return !this.orderRequestModel.foreignCompanies
    },
    orderPrice() {
      return Number(this.orderRequestModel.orderPrice).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
    },
    formImg() {
      return this.orderRequestModel.isCrefOrder ? "/icons/genios_request_reason_docs_CREF.png" : "/icons/genios_request_reason_docs.png"
    },
    reasons() {
      const reasons = i18n.t("orderingProcess.orderRequest.form.reasons")
      return reasons.map(item => {
        const [key, value] = Object.entries(item)[0]
        const isExist = this.orderRequestModel.reasons.find(item => item === key) || null
        if (isExist) {
          return {
            id: `radio_${key}`,
            fieldLabel: value,
            value: key
          }
        }
      }).filter(item => item)
    }
  },
  methods: {
    submit() {
      this.errors = validateFields(this.form);

      if (!this.errors.length) {
        const {buy} = this.$parent

        const params = {...this.generateFormValues(this.form)}

        buy({params})
      }
    },
    async getAlternativeDocumentsPrices() {
      this.loading = true
      try {
        const documents = this.orderRequestModel.alternativeDocuments.map(item => {
          return {
            ...item,
            database: item.documentId.split('__')[0],
            priceCategory: ''
          }
        })
        const data = getDocumentPrices(documents)
        for (const item in data) {
          const element = this.orderRequestModel.alternativeDocuments.find(el => el.documentId === item)
          this.alternativeDocuments.push({...element, ...data[item]})
        }
      } catch (error) {
        console.log("getAlternativeDocumentsPrices:error => ", error)
        console.dir(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
#mandatory-field {
  text-align: right;
}
</style>