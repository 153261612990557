<template>
  <div class="payment__form">
    <TextFormElement
        :passed-value="researchPurpose.value"
        :element="researchPurpose"
        :focus="focus"
        @focus="focus = $event"
        @modified="updateForm({...researchPurpose, value: $event}, true)"
    />
    <TextAreaFormElement
        :passed-value="annotation.value"
        :element="annotation"
        :focus="focus"
        :error="getErrorObj(annotation.id).isError"
        @focus="focus = $event"
        @modified="updateForm({...annotation, value: $event}, true)"
    />
    <Error
        v-if="getErrorObj(annotation.id).isError"
        :error="getErrorObj(annotation.id).message"
    />
  </div>
</template>

<script>

import TextFormElement from "../../FormElements/TextFormElement.vue";
import TextAreaFormElement from "../../FormElements/TextAreaFormElement.vue";
import i18n from "../../plugins/Translations.vue";
import Error from "../../styled/Error.vue";
import {formMixin} from "../../../../functions/mixins/form";

export default {
  name: "ResearchPurpose",
  components: {
    Error,
    TextAreaFormElement,
    TextFormElement
  },
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  mixins: [formMixin],
  data() {
    return {
      focus: null,
      researchPurpose: this.generateField({
        id: 'researchPurpose',
        field: 'researchPurpose',
        fieldLabel: i18n.t('orderingProcess.orderRequest.researchPurpose.inputField'),
        fieldType: 'text',
        value: ''
      }),
      annotation: this.generateField({
            id: 'annotation',
            field: 'annotation',
            fieldLabel: i18n.t('orderingProcess.orderRequest.researchPurpose.textareaField'),
            fieldType: 'text',
            value: ''
          },
          {
            max: 240
          })
    }
  }
}
</script>