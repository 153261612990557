<template>
  <div>
    <div v-if="!isCollapsed" class="drawer">
      <div class="drawer-header">
        <div class="drawer-title">{{ title }}</div>
        <div class="collapse-icon" @click="toggleNav">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
      </div>
      <div
          v-for="(item, index) in menuItems"
          :key="index"
          class="menu-item-wrapper"
      >
        <div
            class="navigation-options"
            @click="handleItemClick(item)"
            :class="{ 'selected': isParentOrSelfSelected(item), 'hovered': hoverItem === item.title || hoverItem && item.subItems && item.subItems.includes(hoverItem) }"
            @mouseover="hoverItem = item.title"
            @mouseleave="hoverItem = null"
        >
          <router-link v-if="item.to" :to="item.to" class="nav-link">{{ item.title }}</router-link>
          <div v-else>{{ item.title }}</div>
          <ion-icon v-if="item.subItems" :name="item.expanded ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
        </div>
        <div v-if="item.expanded && item.subItems" class="sub-items">
          <div
              class="navigation-sub-options"
              v-for="(subItem, index) in item.subItems"
              :key="index"
              @click.stop="selectSubItem(item, subItem)"
              :class="{ 'selected': selectedItem === subItem.title, 'hovered': hoverItem === subItem, 'last-sub-item': index === item.subItems.length - 1 }"
              @mouseover="hoverItem = subItem"
              @mouseleave="hoverItem = null"
          >
            <router-link v-if="subItem.to" :to="subItem.to" class="nav-link">{{ subItem.title }}</router-link>
            <div v-else>{{ subItem.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="collapsed-drawer">
        <div class="expand-icon" @click="toggleNav">
          <ion-icon name="filter-outline"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../../../../resources/static/css/navigation-drawer.scss';

export default {
  name: 'NavigationDrawerComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    menuItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapsed: false,
      selectedItem: '',
      hoverItem: null,
      parentSelectedItem: null
    };
  },
  methods: {
    toggleNav() {
      this.isCollapsed = !this.isCollapsed;
    },
    selectItem(item) {
      this.selectedItem = item.title;
      this.parentSelectedItem = null;
      this.$emit('item-selected', { selectedItem: item.title });
    },
    selectSubItem(parentItem, subItem) {
      this.selectedItem = subItem.title;
      this.parentSelectedItem = parentItem.title;
      this.$emit('item-selected', { selectedItem: subItem.title, parentItem: parentItem.title });
    },
    handleItemClick(item) {
      if (item.subItems) {
        this.toggleItem(item);
      } else {
        this.selectItem(item);
      }
    },
    toggleItem(item) {
      item.expanded = !item.expanded;
    },
    isParentOrSelfSelected(item) {
      if (this.selectedItem === item.title || this.parentSelectedItem === item.title) {
        return true;
      }
      if (item.subItems) {
        return item.subItems.some(subItem => subItem.title === this.selectedItem);
      }
      return false;
    },
    findInitialSelectedItem() {
      const currentRoute = this.$route.path;
      for (let item of this.menuItems) {
        if (item.to && item.to === currentRoute) {
          this.selectItem(item);
          return;
        }
        if (item.subItems) {
          for (let subItem of item.subItems) {
            if (subItem.to === currentRoute) {
              this.selectSubItem(item, subItem);
              item.expanded = true;
              return;
            }
          }
        }
      }
    }
  },
  watch: {
    '$route.path': 'findInitialSelectedItem'
  },
  mounted() {
    this.findInitialSelectedItem();
  }
};
</script>
