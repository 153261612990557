import {fetchMySources} from "../fetching";
import {setupMySourcesIcon} from "../setups";
import {buildInfoForMySourcesComponent} from "../builds";
import {setupMySourcesComponent} from "../setups/vue-components";
import {setupMySourceHandler} from "../setups/handlers";

export const SourceMode = Object.freeze({
    ADD: "ADD",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
})

export const ActionType = Object.freeze({
    UPDATE_TAGS: "UPDATE_TAGS",
    CREATE: "CREATE",
    RENAME: "RENAME",
    REMOVE: "REMOVE"
})

export const JS_HANDLER = "mySourcesHandler"

export const SINGLE_POPUP_ID = "my-sources-handler-popup"

export const sourceIconState = Object.freeze({
    LOADING: 'LOADING',
    SERVER: "SERVER",
    OUTLINE: "OUTLINE"
})

export const updateMySourcesInSharedState = (mySourceToUpdate, action = ActionType.UPDATE_TAGS) => {
    //mySourceToUpdate object has to consist of {name, sourceTextIds}

    window.sharedState.currentMySources = {
        db: null,
        data: []
    }

    switch (action) {
        case ActionType.REMOVE: {
            const index = window.sharedState.mySources.findIndex(item => item.name === mySourceToUpdate.name)
            if (index >= 0)
                window.sharedState.mySources.splice(index, 1)
            break
        }
        case ActionType.CREATE: {
            window.sharedState.mySources.unshift(mySourceToUpdate)
            break
        }
        case ActionType.RENAME: {
            const mySource = window.sharedState.mySources.find(item => item.name === mySourceToUpdate.name);
            if (!!mySource)
                mySource.name = mySourceToUpdate?.newName
            break
        }
        case ActionType.UPDATE_TAGS:
        default: {
            const mySource = window.sharedState.mySources.find(item => item.name === mySourceToUpdate.name);
            if (!!mySource)
                mySource.subTags = mySourceToUpdate.subTags;
            break
        }
    }
};

export const isOutOfViewport = elem => {
    // Get element's bounding
    const bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    const out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;

    out.x = bounding.x
    out.y = bounding.y

    out.bottomRect = bounding.bottom
    out.height = bounding.bottom
    out.leftRect = bounding.left
    out.rightRect = bounding.right
    out.topRect = bounding.bottom
    out.width = bounding.width

    return out;
}

export const setupFirstLoadModuleOfMySourcesFunctionality = async (needLoad = true) => {
    const mediaTiles = getMediaTiles()

    // needLoad needs in case of reloading part of page and not the whole one.
    // In this case sharedState.mySources will be loading, that's why no necessary to load once more
    if (needLoad) {
        //load my sources
        fetchMySources().then(() => {
            setupMySourceHandler();
            setupMySourcesIcon(mediaTiles)
        })
    } else {
        setupMySourceHandler().then();
        setupMySourcesIcon(mediaTiles).then()
    }
}

export const setupFullLoadModuleMySources = async (selectedItem = null) => {
    const mediaTiles = getMediaTiles()
    setupLoaderToServerIcons(mediaTiles).then()

    fetchMySources().then(() => {
        setupMySourceHandler();
        setupMySourcesIcon(mediaTiles, selectedItem)
    })
}

export const setupAfterLoginModuleMySources = async () => {
    const mediaTiles = getMediaTiles()
    setupLoaderToServerIcons(mediaTiles).then()

    fetchMySources().then(() => {
        setupMySourcesIcon(mediaTiles)
    })
}


export const getMediaTiles = (dbShortcut = null, {excludedItem = ""} = {}) => {
    let selector = !!dbShortcut ? `.media_tile.element[data-db=${dbShortcut}]` : `.media_tile.element:not([data-db='${excludedItem}'])`;
    return document.querySelectorAll(selector) || []
}

export const setupServerOutlineIcons = mediaTiles => {
    mediaTiles.forEach(tile => {
        const iconBtn = tile.querySelector(`[js-handler="${JS_HANDLER}"]`) || null
        if (!!iconBtn) {
            setServerIconState(sourceIconState.OUTLINE, iconBtn)
        }
    })
}

export const setupLoaderToServerIcons = async mediaTiles => {
    mediaTiles.forEach(tile => {
        const iconBtn = tile.querySelector(`[js-handler="${JS_HANDLER}"]`) || null
        if (!!iconBtn) {
            setServerIconState(sourceIconState.LOADING, iconBtn)
        }
    })
}

export const setupServerIcons = mediaTiles => {
    mediaTiles.forEach(tile => {
        const iconBtn = tile.querySelector(`[js-handler="${JS_HANDLER}"]`) || null
        if (!!iconBtn) {
            setServerIconState(sourceIconState.SERVER, iconBtn)
        }
    })
}

export const toggleActivationMySourcesIcons = (mediaTiles, value) => {
    mediaTiles.forEach(tile => {
        const iconBtn = tile.querySelector(`[js-handler="${JS_HANDLER}"]`)
        iconBtn.disabled = value
    })
}

export const setServerIconState = (state = sourceIconState.OUTLINE, iconBtn) => {
    if (!iconBtn) {
        throw ReferenceError("argument {iconBtn} in {setServerIconState} function is mandatory")
    } else {
        switch (state) {
            case sourceIconState.LOADING: {
                iconBtn.classList.add("button-loader")
                iconBtn.classList.remove("button-server")
                iconBtn.classList.remove("button-server-outline")
                iconBtn.disabled = true
                break
            }
            case sourceIconState.SERVER: {
                iconBtn.classList.remove("button-loader")
                iconBtn.classList.add("button-server")
                iconBtn.classList.remove("button-server-outline")
                iconBtn.disabled = false
                break
            }
            default:
            case sourceIconState.OUTLINE: {
                iconBtn.classList.remove("button-loader")
                iconBtn.classList.remove("button-server")
                iconBtn.classList.add("button-server-outline")
                iconBtn.disabled = false
                break
            }
        }
    }
}

export const createMySourcesPopup = db => {
    const mySourceComponent = document.createElement("div")
    mySourceComponent.setAttribute("id", `${SINGLE_POPUP_ID}_${db}`)
    return mySourceComponent
}

export const setupMySourcesPopupCoordinates = (parent, db) => {
    const popup = parent.querySelector(`#${SINGLE_POPUP_ID}_${db}`)
    const out = isOutOfViewport(popup)
    if (out.left)
        popup.style.left = Math.ceil(out.x) - 40 + "px"
}

export const standardSetupOfSinglePopup = ({data, parent, tile}, iconType) => {
    parent.append(createMySourcesPopup(tile.dataset.db))

    const info = buildInfoForMySourcesComponent(data, iconType)

    setupMySourcesComponent(parent, {...info, dbShortcut: tile.dataset.db})

    setupMySourcesPopupCoordinates(parent, tile.dataset.db)

    return info
}

export const closeAllMySourcesPopups = selectedPopup => {
    const allMySourcesPopups = document.querySelectorAll(`[id^=${SINGLE_POPUP_ID}`) || []

    allMySourcesPopups.forEach(popup => {
        const db = selectedPopup?.dataset.db || null
        if (db !== popup.dataset.db)
            popup.parentNode.removeChild(popup)
    })
}