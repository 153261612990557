export const formMixin = {
    computed: {
        isFormEmpty(){
            return !!Object.keys(this.form)
        },
    },
    methods: {
        getErrorObj(id) {
            const errorObj = this.errors.find(item => item.field === id)
            return {
                isError: !!errorObj === true,
                message: errorObj?.message || ''
            }
        },
        generateFormValues(form) {
            let res = {}
            for (const item in form)
                res[item] = form[item].value

            if(!!Object.keys(res) === false)
                return form

            return res
        },
        generateField({id, fieldLabel, ...rest}, constraints = {}) {
            if (!id)
                throw new ReferenceError("generateField: 'id' is missing. It is a required field fo this function!");
            if (!fieldLabel)
                throw new ReferenceError("generateField: 'fieldLabel' is missing. It is a required field fo this function!");

            const res = {
                id,
                fieldLabel,
                ...rest,
                constraints: {}
            }

            const {required, type, min, max} = constraints

            if (!!required)
                res.constraints.required = required
            if (!!min)
                res.constraints.min = min
            if (!!max)
                res.constraints.max = max

            if (!!type)
                res.constraints.type = type

            return res
        },
        updateForm(obj, emit = false) {
            if (emit)
                this.$emit('update-form', {...obj, value: obj.value})
            else
                this.form[obj.id] = {...obj}
        },
        setupDefaultForm(values){
            this.form = {...values}
        }
    }
}