<template>
  <fragment>

    <div class="tooltip tooltip-icon my-sources__add-btn">
      <button-div
          variant="icon"
          icon="remove-outline"
          @click="updateMode"
      />
      <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{
          $t("genios.tooltip.icons.minus")
        }}
      </div>
    </div>

    <div>

      <text-form-element
          class="my-sources__text-field"
          :passed-value="sourceName.value"
          :element="sourceName"
          :focus="focus"
          @modified="updateForm({...sourceName, value: $event})"
          @focus="focus = $event"
          @keyup.enter="createOrUpdateSource"
      />
      <error
          v-if="getErrorObj(sourceName.id).isError"
          :error="getErrorObj(sourceName.id).message"
      />

      <span class="my-sources__info">{{ sourceInfo }}</span>
    </div>

    <div class="watchlist_dialog__button_line">
      <Button
          name="close"
          variant="secondary"
          @click="$emit('close-sources')"
          :text="$t('genios.close')"
          :toggle-text="false"
      />
      <Button
          name="save"
          variant="primary"
          :text="$t('genios.watchlist.add')"
          :toggle-text="false"
          :loading="loader"
          position="left"
          :disabled="loader === ''"
          @click="createOrUpdateSource"
      />
    </div>
  </fragment>
</template>

<script>

import Button from "../styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import i18n from "../plugins/Translations.vue";
import {formMixin} from "../../../functions/mixins/form";
import ButtonDiv from "../styled/ButtonDiv.vue";
import {validateFields} from "../../../functions/forms/validators";
import Error from "../styled/Error.vue";
import {
  ActionType,
  getMediaTiles,
  SourceMode,
  updateMySourcesInSharedState
} from "../../../functions/components/mySourceComponent";
import {createMySource, updateMySource} from "../../../functions/services/mySourcesService";

import {setupMySourcesIcon} from "../../../functions/setups";

export default {
  name: "CreateNewSource",
  components: {
    Error,
    ButtonDiv,
    TextFormElement,
    Button
  },
  mixins: [formMixin],
  props: {
    sourceValue: {
      type: String,
      default: ''
    },
    currentSourceMode: {
      type: String,
      required: true
    },
    sourceTextIds: {
      type: Array,
      required: true
    },
    sourceTitles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      focus: "sourceName",
      errors: [],
      form: {},
      sourceName: this.generateField({
        id: 'sourceName',
        field: 'sourceName',
        fieldLabel: i18n.tc('genios.mySources.newSource', !this.sourceValue.length),
        fieldType: 'text',
        value: this.sourceValue
      }, {
        required: true,
        min: 1
      }),
      loader: false
    }
  },
  computed: {
    SourceMode() {
      return SourceMode
    },
    sourceInfo() {
      return i18n.t("genios.mySources.clickInfo", {currentInfo: i18n.tc("genios.mySources.currentInfo", !this.sourceValue.length)})
    }
  },
  methods: {
    updateMode() {
      this.$emit('update-mode', {mode: SourceMode.ADD});
    },
    async createOrUpdateSource() {
      if (Object.keys(this.form).length > 0) {
        this.loader = true
        this.errors = validateFields(this.form)
        if (!this.errors.length) {
          const sourceName = this.form[this.sourceName.id].value

          switch (this.currentSourceMode) {
            case SourceMode.CREATE:
              await this.create(sourceName)
              break
            case SourceMode.UPDATE:
              await this.update(sourceName)
              break
          }
          this.loader = false
          const mediaTiles = getMediaTiles(this.dbShortcut)
          setupMySourcesIcon(mediaTiles).then();
          this.updateMode()
        }
      }
    },
    async create(name) {
      try {
        const body = {
          name,
          sourceTextIds: this.sourceTextIds
        }
        const res = await createMySource(body);
        updateMySourcesInSharedState(res, ActionType.CREATE)
      } catch (e) {
        throw new ReferenceError(e.message)
      }
    },
    async update(newSourceName) {
      try {
        const body = {
          name: newSourceName,
          sourceTextIds: this.sourceTextIds
        }
        await updateMySource(this.sourceValue, body)
        updateMySourcesInSharedState({...body, name: this.sourceValue, newName: newSourceName}, ActionType.RENAME)
      } catch (e) {
        throw new ReferenceError(e.message)
      }
    }
  },
  beforeDestroy() {
    this.$emit('update-source-value')
  }
}
</script>

<style scoped>

</style>