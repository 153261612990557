<template>
  <div class="mini_list__container__links__link_row" @click="itemClicked">
    <div class="mini_list__container__links__link_row__title" :class="{selected: selected, paid: item.paid}">
      <div v-if="['FIRMENPROFIL', 'BONITAETEN', 'VERFLECHTUNGEN'].includes(category)">
        <div class="mini_list__container__links__link_row__title__first_line one_line_text">{{ item.title }}</div>
        <div class="mini_list__container__links__link_row__title__second_line">
          <div class="mini_list__container__links__link_row__title__second_line__tiny_icon">
            <img alt="" loading="lazy" :src="tinyIconUrl">
          </div>
          <div class="mini_list__container__links__link_row__title__second_line__producer_title one_line_text">
            {{ item.producer }}
          </div>
        </div>
      </div>
      <div v-else-if="category === 'JAHRESABSCHLUSS'">
        <div class="two_line_text">
          <img class="two_line_text__img" alt="" loading="lazy" :src="tinyIconUrl">
          <div class="two_line_text__text">{{ item.text }}</div>
        </div>
      </div>
      <div v-else-if="category === 'HANDELSREGISTER'">
        <div class="mini_list__container__links__link_row__title__first_line one_line_text">{{ item.title }}</div>
        <div class="mini_list__container__links__link_row__title__second_line">
          <div class="mini_list__container__links__link_row__title__second_line__tiny_icon">
            <img alt="" loading="lazy" :src="tinyIconUrl">
          </div>
          <div> {{ item.releaseDate }}</div>
          <div class="mini_list__container__links__link_row__title__second_line__category">
            {{ item.commercialRegisterCategory }}
          </div>
        </div>
      </div>
      <div v-else-if="['BRANCHENINFORMATIONEN', 'PRESSE'].includes(category)">
        <div class="mini_list__container__links__link_row__title__first_line">
          <div class="mini_list__container__links__link_row__title__first_line__tiny_icon">
            <img alt="" loading="lazy" :src="tinyIconUrl">
          </div>
          <div class="mini_list__container__links__link_row__title__first_line__producer_title one_line_text">
            {{ item.producer }}
          </div>
        </div>
        <div class="mini_list__container__links__link_row__title__second_line one_line_text">{{ item.title }}</div>
      </div>
    </div>
    <div class="mini_list__container__links__link_row__price">
      <span>{{ preview(category, item.database) }}</span>
    </div>
  </div>
</template>

<script>
import i18n from "../plugins/Translations.vue";
import {changeUserInteractedAction, updateSelectedItemAction} from "../../../functions/components/fidoComponent";

export default {
  name: "LinkItemComponent",
  components: {},
  props: {
    id: String,
    item: Object,
    category: String,
    store: {
      type: Object,
      required: true
    }
  },
  methods: {
    itemClicked: function() {
      updateSelectedItemAction(this.store, {itemId: this.id, paid: this.item.paid})
      changeUserInteractedAction(this.store, true);
      this.scrollPreviewIntoView();
      setTimeout(
          () => {
            this.scrollPreviewIntoView();
          }, 1000
      )
      setTimeout(
          () => {
            this.scrollPreviewIntoView();
          }, 2000
      )
    },
    scrollPreviewIntoView: function() {
      let docElement = document.querySelector('#' + this.id);
      if (docElement) {
        docElement.querySelector(".media_preview__scroll_padding")?.scrollIntoView(true);
      }
    },
    preview: function (category, dbShortcut) {
      if (category === "BONITAETEN") {
        return i18n.t("genios.FIDO.search.button.infosAndPrices")
      } else if (category === "VERFLECHTUNGEN" && dbShortcut === "BBGW") {
        return i18n.t("genios.FIDO.search.button.infosAndPrices");
      } else if (category === "FIRMENPROFIL" && dbShortcut === "CREF") {
        return i18n.t("genios.FIDO.search.button.infosAndPrices");
      } else {
        if (this.item.paid) {
          if (this.item.price === "paid" || this.item.price === "bezahlt") {
            return i18n.t("genios.FIDO.search.button.paid");
          } else {
            return i18n.t("genios.FIDO.search.button.show");
          }
        } else {
          return i18n.t("genios.FIDO.search.button.infosAndPrices")
        }
      }
    }
  },
  computed: {
    selected: function () {
      return this.store.selectedItem.itemId === this.id
    },
    tinyIconUrl() {
      return "/resource/logos-tiny/" + this.item.database.toLowerCase() + ".png"
    }
  }
}
</script>
