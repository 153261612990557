import {sortBy} from "lodash";

export const browseFiltersMethodsMixin = {
    props: {
        showForm: {
            type: String,
            required: true
        },
        element: {
            type: Object,
            required: true
        },
        sharedState: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            filter: this.generateFilter(this.element)
        }
    },
    methods: {
        generateFilter(element) {
            const {filterList} = element

            const activeFilters = window.sharedState.browseActiveFilters[element.name]

            const res = []
            if (!!filterList && Object.keys(filterList).length > 0)
                for (const key of Object.keys(filterList)) {
                    const data = filterList[key]
                    res.push({
                        id: key,
                        fieldLabel: key,
                        field: key,
                        value: activeFilters.includes(key),
                        count: data.count
                    })
                }

            const checkedArr = sortBy(res.filter(item => item.value), ["value", (el) => el.id.toLowerCase(), "count"])
            const notCheckedArr = sortBy(res.filter(item => !item.value), ["value", (el) => el.id.toLowerCase(), "count"])

            return {
                ...element,
                filterList: [...checkedArr, ...notCheckedArr],
                filterListMap: filterList
            }
        },
        updateShowForm(event) {
            event.preventDefault()
            const value = this.showForm === this.filter.name ? '' : this.filter.name

            this.$emit('update-show-form', value)

            this.$nextTick(() => {
                if (!!value && !!event.target.nextElementSibling) {
                    event.target.nextElementSibling.focus()
                }
            })
        }
    },
    watch: {
        element() {
            if (this.showForm !== this.filter.name)
                this.filter = this.generateFilter(this.element)
        }
    }
}