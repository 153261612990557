<template>
  <div class="alternative_article__container">
    <p class="alternative_article__title">{{ title }}</p>
    <AlternativeArticlesList
        :button-text="buttonText"
        :date="date"
        :doc-type="docType"
        :document-id="documentId"
        :img="img"
        :name="name"
        :source-description="sourceDescription"
        :source-img="sourceImg"
        :text="text"
        @buy-article="buyAlternativeDocument(documentId)"
    />

    <AlternativeArticlesGrid
        id="alternative_grid"
        :button-text="buttonText"
        :date="date"
        :doc-type="docType"
        :document-id="documentId"
        :img="img"
        :name="name"
        :source-description="sourceDescription"
        :source-img="sourceImg"
        :text="text"
        @buy-article="buyAlternativeDocument(documentId)"
    />
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import i18n from "../../plugins/Translations.vue"
import AlternativeArticlesGrid from "./AlternativeArticlesGrid.vue";
import AlternativeArticlesList from "./AlternativeArticlesList.vue";

export default {
  name: "AlternativeArticles",
  components: {AlternativeArticlesList, AlternativeArticlesGrid, Button, i18n},
  props: {
    uid: String,
    sourceDescription: String,
    name: String,
    text: String,
    docType: String,
    date: String,
    price: Number,
    dbShortcut: String,
    documentId: String,
    isCref: Boolean
  },
  data() {
    return {}
  },
  computed: {
    img() {
      const [db, id] = this.uid.split("__")
      return `/img?db=${db}&doc_id=${id}`
    },
    sourceImg() {
      return `/resource/logos-big/${this.dbShortcut.toLowerCase()}.png`
    },
    documentPrice() {
      return Number(this.price).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
    },
    buttonText() {
      return i18n.t('orderingProcess.orderRequest.alternative.alternativeButtonText', {price: this.documentPrice})
    },
    title() {
      const category = this.isCref ? "Firmenprofil" : "Bonitätsauskunft"
      return i18n.tc("orderingProcess.orderRequest.alternative.title", this.isCref, {category})
    }
  },
  methods: {
    buyAlternativeDocument(documentId) {
      this.$parent.$parent.buy({documentId, params: {orderDataId: null, mode: "full"}})
    }
  }
}
</script>

<style scoped>

</style>