<template>
  <div class="radio__group" :class="direction">
    <RadioFormComponent
        v-for="radio in group"
        @modified="updateRadioValue"
        :group-value="value"
        :element="radio"
        :key="radio.id"
    />
  </div>

</template>

<script>
import RadioFormComponent from "./RadioFormComponent.vue"

export default {
  name: "RadioGroupComponent",
  components: {RadioFormComponent},
  props: {
    group:{
      type: Array,
      required: true
    },
    direction: {
      type: String,
      default: 'column'
    },
    initialValue: String
  },
  data() {
    return {
      value: this.initialValue
    }
  },
  methods: {
    updateRadioValue(value) {
      this.value = value;
      this.$emit('update', value);
    }
  }
}
</script>

<style>
.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}
</style>
