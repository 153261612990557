<template>
  <div class="payment__login" v-if="!isLoginForm" @keyup.enter="lightUserAuth">
    <h1 class="payment__title"> {{ title }}</h1>

    <h3 class="payment__subtitle" v-if="code === 'ERROR_REGISTRATION_USER_EXISTS'">
      {{ $t('orderingProcess.mainForm.loginSubtitleUserExist') }}
    </h3>
    <h3 class="payment__subtitle"
        v-else-if="code === 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED'"
        v-html="$t('orderingProcess.mainForm.loginSubtitleUserNotConfirmed')"
    >
    </h3>
    <h3 class="payment__subtitle" v-else>
      {{ $t('orderingProcess.mainForm.loginSubtitle') }}
    </h3>

    <TextFormElement
        :passedValue="email.value"
        :focus="focus"
        :element="email"
        :error="email.error !== '' || loginFailed.status"
        @modified="email.value = $event"
        @focus="focus = $event"
    />
    <Error v-show="email.error" :error="email.error"/>
    <Error v-show="code === 'ERROR_REGISTRATION_VALIDATION'"
           :error="$t('orderingProcess.mainForm.lightUserError.invalid')"/>

    <div v-if="registrationError" class="payment__login__user-exist">
      <Error v-show="loginFailed.text !== 'click' && !loginFailed.status"
             :error="$t('orderingProcess.mainForm.lightUserError.' + (code==='ERROR_REGISTRATION_USER_EXISTS'? 'userExist': 'notConfirmed'))"/>
      <TextFormElement
          :passedValue="password.value"
          :focus="focus"
          :element="password"
          :error="password.error !== '' || loginFailed.status"
          html-input-field-type="password"
          @modified="password.value = $event"
          @focus="focus = $event"
      />
      <Error v-show="password.error" :error="password.error"/>

      <Error v-show="loginFailed.status" :error="loginFailed.text"/>

      <a :href="getPasswordForgottenPath()"
         v-if="code !== 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED'"
         class="password_forgotten">
        {{ $t('genios.login.labels.passwordForgot') }}
      </a>
    </div>

    <div class="payment__steps__bottom">
      <p class="payment__steps__bottom__description" v-if="!sharedState.loginStatus.loggedIn">
        {{
          registrationError ? $t('orderingProcess.mainForm.loginFooter.noAccount') : $t('orderingProcess.mainForm.loginFooter.hasAccount')
        }}
        <span @click="updateState(code === 'ERROR_REGISTRATION_USER_EXISTS')">
        {{ $t('orderingProcess.mainForm.loginFooter.link') }}
      </span>
      </p>
      <Button
          variant="primary"
          :text="code === 'SHOW_LIGHT_REGISTRATION_FORM' ? $t('orderingProcess.mainForm.button') : $t('genios.login.buttons.login')"
          :toggle-text="false"
          @click="lightUserAuth"
          ref="submitBtn"
          :disabled="isLoading"
          position="left"
      >
        <ButtonLoader
            v-if="isLoading"
            :size="$refs.submitBtn.$el.clientHeight"
        />
      </Button>
    </div>

  </div>

  <LoginComponent
      v-else
      :callback="callback" :shared-state="sharedState"
      :display-reason="displayReason"
  >
    <span class="payment__footer">
      {{ $t('orderingProcess.mainForm.loginFooter.noAccount') }}
      <span class="payment__link__secondary" @click="updateState">
        {{ $t('orderingProcess.mainForm.loginFooter.link') }}
      </span>
    </span>
  </LoginComponent>
</template>

<script>
import TextFormElement from "../../FormElements/TextFormElement.vue";
import LoginComponent from "../../Login/LoginComponent.vue";
import Button from "../../styled/Button.vue";
import ButtonLoader from "../../styled/ButtonLoader.vue";
import i18 from "../../plugins/Translations.vue"
import axios from "axios";
import Error from "../../styled/Error.vue";
import {getUser} from "../../../../functions/getters";
import {checkLogin} from "../../../../functions/checks";
import {getPasswordForgottenPath} from "../../../../functions/opens";
import {buildPaymentApiCall} from "../../../../functions/builds";
import eventBus from "../../../../eventBus";
import {setupFullLoadModuleMySources} from "../../../../functions/components/mySourceComponent";

export default {
  name: "UserEmailPasswordComponent",
  components: {Error, Button, TextFormElement, i18, ButtonLoader, LoginComponent},
  props: {
    orderDataId: {
      type: [String, Number],
      required: true
    },
    documentIds: {
      type: Array,
      require: true
    },
    sharedState: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orderResponse: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      displayReason: "genios.login.reasons.login",
      focus: null,
      isLoginForm: false,
      isLoading: false,
      loginFailed: {
        status: false,
        text: i18.t("genios.login.errors.failed")
      },
      email: {
        id: 'email',
        fieldLabel: i18.t('orderingProcess.mainForm.email'),
        fieldType: 'text',
        value: '',
        error: ''
      },
      password: {
        id: 'password',
        fieldLabel: i18.t('orderingProcess.mainForm.password'),
        fieldType: 'text',
        value: '',
        error: ''
      },
      code: 'SHOW_LIGHT_REGISTRATION_FORM'
    }
  },
  computed: {
    registrationError() {
      return this.code === 'ERROR_REGISTRATION_USER_EXISTS' || this.code === 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED'
    }
  },
  methods: {
    getPasswordForgottenPath() {
      return getPasswordForgottenPath()
    },
    updateState(isDefault = false) {
      if (isDefault) {
        this.code = "SHOW_LIGHT_REGISTRATION_FORM"
        this.isLoginForm = false
        this.email.value = ''
        this.password.value = ''
        this.loginFailed.status = false
      } else {
        this.isLoginForm = !this.isLoginForm
      }
      const title = this.isLoginForm ? i18.t('orderingProcess.mainForm.loginTitle') : i18.t('orderingProcess.mainForm.guestTitle')
      this.$emit('update-title', title)
    },
    callback() {
      const {buy} = this.$parent.$parent.$parent
      buy({params: {email: this.email.value}})
    },
    async lightUserAuth() {
      this.isLoading = true
      const checkValidation = this.validation(this.email.value, this.password.value)

      if (checkValidation) {
        const {mode} = this.$root
        // , {redirectUrl} = getParams()

        let params = {
          email: this.email.value,
          orderDataId: this.orderDataId,
          // redirectUrl
        }

        if (this.registrationError) {
          await this.login()
        } else {
          try {
            const {
              fetchUrl,
              params: buildParams,
              config
            } = buildPaymentApiCall(this.documentIds, mode, this.orderResponse, params)

            const response = await axios.post(fetchUrl, buildParams, config)

            const {data} = response

            if (data.code === 'ERROR_REGISTRATION_USER_EXISTS' || data.code === 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED') {
              this.code = data.code
              this.$emit('update-title', i18.t('orderingProcess.mainForm.loginTitle'))
              // this.$refs.submitBtn.$el.innerText = i18.t('genios.login.buttons.login')
            } else if (data.code === "ERROR_REGISTRATION_VALIDATION") {
              this.code = data.code
            } else {
              window.sharedState.defaultUserEmail = this.email.value
              sessionStorage.setItem('defaultUserEmail', this.email.value)
              window.sharedState.loginStatus = await checkLogin()
              await getUser()
              this.$emit('update-title', i18.t('orderingProcess.mainForm.paymentMethod.title'))
              this.$emit('update-step', {...data, issueDate: this.orderResponse?.issueDate})
              eventBus.$emit('update-login-status', window.sharedState)
            }
          } catch (e) {
            console.log("lightUserAuth.error => ", e)
            console.dir(e)
          }
        }
      }
      this.isLoading = false
    },
    async login() {
      this.loginFailed.status = false
      this.loginFailed.text = "click"
      const data = new URLSearchParams();
      data.append("username", this.email.value);
      data.append("password", this.password.value);
      data.append("embedded", "true");
      try {
        const response = await fetch('/login/login', {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          redirect: 'error',
          referrerPolicy: 'no-referrer',
          body: data
        });
        const loginStatus = await response.json();
        if (response.status === 200) {
          if (loginStatus?.error) {
            this.loginFailed.status = loginStatus.error
            this.loginFailed.text = i18.t("genios.login.errors.failed")
          } else {
            window.sharedState.loginStatus = loginStatus
            sessionStorage.setItem('defaultUserEmail', this.email.value)
            await getUser()

            if (loginStatus.loggedIn) {
              if (this.sharedState.page === "browse") {
                await setupFullLoadModuleMySources()
              }
            }
            this.callback()
          }
        }
      } catch (e) {
        console.log("UserEmailPasswordComponent:login:error => ", e)
        console.dir(e)
      }
    },
    validation(email, password) {
      this.email.error = ''
      this.password.error = ''
      let isValid = true
      if (!email) {
        this.email.error = i18.t('orderingProcess.mainForm.lightUserError.emptyEmail')
        isValid = false
      } else if (!email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        this.email.error = i18.t('orderingProcess.mainForm.lightUserError.invalid')
        isValid = false
      }

      if (this.code === "ERROR_REGISTRATION_USER_EXISTS") {
        if (!password) {
          this.password.error = i18.t('orderingProcess.mainForm.lightUserError.emptyPassword')
          isValid = false
        }
      }

      return isValid
    }
  }
}
</script>
