<template>
  <div v-html="this.chartHtml" class="chart-document-container"/>
</template>

<script>

export default {
  name: "ChartDocumentComponent",
  components: {},
  props: {
    sharedState: Object,
    chartHtml: String
  },

  data() {
    return {};
  },

  created() {
  },

  mounted() {
  },

  methods: {

  },

  computed: {

  },

  watch: {

  }
}
</script>


<style scoped>
</style>
