<template>
  <div class="media_preview__main_content__fields__field_I">
    <div class="media_preview__main_content__fields__field_I__row" v-for="item in fieldIList">
      <span class="media_preview__main_content__fields__field_I__name">{{ $t(`documentMetadata.${item.code}`) }}:</span>
      <span class="media_media_preview__main_content__fields__field_I__value">{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "fieldI",
  props: {
    fieldIList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>