import i18n from "../../vue/components/plugins/Translations.vue";
import {formTypes, standardOptions} from "./consts";
import {emailValidator, numberValidator} from "./validatorUtils";

export const validateFields = form => {
    const errors = []

    for (const key in form) {
        const fieldValue = form[key]

        const options = fieldValue.constraints || {},
            optionsEntriesLength = Object.entries(options).length

        if (optionsEntriesLength > 0) {
            for (const optionKey in options) {
                const optionValue = options[optionKey]

                if (fieldValue?.value || optionValue === standardOptions.required) {
                    if (!fieldValue?.value.trim()) {
                        errors.push({
                            field: key,
                            message: i18n.t('errors.missingParams', {fieldName: fieldValue.fieldLabel})
                        })
                    }
                    typeValidation(errors, key, optionValue, fieldValue)
                    rangeValidation(errors, key, optionKey, optionValue, fieldValue)
                } else if (fieldValue.value.length > 0) {
                    typeValidation(errors, key, optionValue, fieldValue)
                    rangeValidation(errors, key, optionKey, optionValue, fieldValue)
                }
            }
        }
    }

    return errors
}

const typeValidation = (errors, key, optionValue, fieldValue) => {
    if (optionValue === formTypes[optionValue]) {
        switch (optionValue) {
            case "email":
                if (!emailValidator(fieldValue.value))
                    errors.push({
                        field: key,
                        message: i18n.t('errors.invalidParams', {fieldName: fieldValue.fieldLabel})
                    })
                break
            case "number":
                if (!numberValidator(fieldValue.value))
                    errors.push({
                        field: key,
                        message: i18n.t('errors.invalidParams', {fieldName: fieldValue.fieldLabel})
                    })
                break

        }
    }
    //TODO: place for other types
}

const rangeValidation = (errors, key, optionKey, optionValue, fieldValue) => {
    if (optionKey === standardOptions.max) {
        if (fieldValue.value.length > optionValue) {
            errors.push({
                field: key,
                message: i18n.t('errors.maxLength', {
                    fieldName: fieldValue.fieldLabel,
                    maxLength: optionValue
                })
            })
        }
    }

    if (optionKey === standardOptions.min) {
        if (fieldValue.value.length < optionValue) {
            errors.push({
                field: key,
                message: i18n.t('errors.minLength', {
                    fieldName: fieldValue.fieldLabel,
                    minLength: optionValue
                })
            })
        }
    }
}