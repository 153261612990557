export const getDbFromUid = function (uid) {
    if (!!uid) {
        if (uid.includes("__")) {
            return uid.split("__")[0];
        } else {
            return "";
        }
    } else {
        return "";
    }
}

export const convertStringToHtml = (str, options = {}) => {
    const parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    if (options?.script) return doc.scripts
    else if (doc.body.firstElementChild?.textContent.length > 0)
        return doc.body.firstElementChild
    else return doc.body
}

export const formatPrice = price => {
    return Number(price).toLocaleString('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
}