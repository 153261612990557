<template>
  <ul class="password-criteria">
    <li
        v-for="(criterion, index) in criteriaList"
        :key="index"
        :class="{ valid: criterion.valid }"
    >
      <ion-icon
          v-if="criterion.valid"
          name="checkmark-outline"
          class="checkmark-icon"
      ></ion-icon>
    {{ criterion.text }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "PasswordCriteriaComponent",
  props: {
    criteria: {
      type: Array,
      required: true,
    },
  },
  computed: {
    criteriaList() {
      return this.criteria.map((criterion) => ({
        text: criterion.text,
        valid: criterion.validation(),
      }));
    },
  },
};
</script>

<style scoped>
.password-criteria {
  font-size: 14px;
  padding: 0;
}

.password-criteria li:not(.valid) {
  margin-left: 19px;
}

.password-criteria li.valid {
  list-style: none;
  color: #10A100;
}

.checkmark-icon {
  color: #10A100;
  font-size: 16px;
  text-shadow: 1px 1px 0 currentColor;
  transform: scale(1.1);
  margin-bottom: -2px;
}
</style>
