<template>
  <fragment>
    <article
        v-for="(page, index) in content[currentPage - 1]"
        :key="`content_${index}`"
        :class="page.className"
        :id="page.id"
        :data-document-id="page.dataset.documentId"
        v-html="page.innerHTML"
    />
  </fragment>

</template>

<script>

export default {
  name: "PageViewerComponent",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    content: {
      type: Array,
      required: true
    }
  }
}
</script>