export const standardOptions = Object.freeze({
    required: true, //on empty handler
    min: "min",
    max: "max"
})

export const formTypes = Object.freeze({
    text: 'text',
    number: "number",
    address: "address",
    email: "email",
    checkbox: "checkbox"
})