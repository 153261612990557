<template>
  <div class="container">
    <div class="message-box">
      <p class="message">Diese Seite befindet sich derzeit im Aufbau.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InProgressComponent"
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.message-box {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 50vh;
}

.message {
  font-size: 18px;
  color: #333;
}
</style>
