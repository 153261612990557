<template>
  <fragment>
    <!--    TODO: refactor css-->
    <div class="border-line"></div>
    <table class="moduleDocumentTable refTable table table-striped table-condensed" style="margin-top: 20px">
      <colgroup>
        <col class="firstColumn">
      </colgroup>
      <colgroup>
        <col class="secondColumn">
      </colgroup>
      <tbody>
      <tr v-for="(item, index) in medataFooterList" :class="(index % 2 === 0) ? 'boxColorLight_Gray': 'boxColorWhite'">
        <td class="boxFirst">{{ $t(`documentMetadata.${item.code}`) }}:</td>
        <td>
          <div class="textCompact">{{ item.value }}</div>
        </td>
      </tr>
      </tbody>
    </table>

    <p>
      <b>{{ $t('documentMetadata.link') }}:</b><br>
      <span style="word-break: break-all"><a :href="documentUrl" class="video__document-link">{{ documentUrl }}</a></span>
    </p>

    <video-footer class="video__full-document" :copyright="copyright"/>
  </fragment>
</template>

<script>
import VideoFooter from "../video/VideoFooter.vue";

export default {
  name: "MetadataFooter",
  components: {VideoFooter},
  props: {
    medataFooterList: {
      type: Array,
      default: []
    },
    documentUrl: {
      type: String,
      required: true
    },
    copyright: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>