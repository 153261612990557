<template>
  <div class="payment__addendum">
    <div class="payment__message" v-html="$t('orderingProcess.requestReason.addendum')"/>
    <div class="payment__form">
      <TextFormElement
          :passed-value="primaryEmail.value"
          :element="primaryEmail"
          :focus="focus"
          :error="!!(errors.primaryEmail)"
          @focus="focus = $event"
          @modified="updateForm({primaryEmail: $event})"
      />
      <TextFormElement
          :passed-value="alternativeEmail.value"
          :element="alternativeEmail"
          :focus="focus"
          :error="!!(errors.alternativeEmail)"
          @focus="focus = $event"
          @modified="updateForm({alternativeEmail: $event})"
      />
    </div>
  </div>
</template>

<script>
import TextFormElement from "../../FormElements/TextFormElement.vue";
import i18n from "../../plugins/Translations.vue";

export default {
  name: "AddendumComponent",
  components: {TextFormElement},
  props: {
    errors: {
      type: Object,
      default: {}
    },
    values: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      focus: null,
      form: {},
      primaryEmail: {
        id: 'primaryEmail',
        field: 'primaryEmail',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.primaryEmail.field'),
        fieldType: 'text',
        value: window.sharedState.defaultUserEmail || ''
      },
      alternativeEmail: {
        id: 'alternativeEmail',
        field: 'alternativeEmail',
        fieldLabel: i18n.t('orderingProcess.requestReason.form.alternativeEmail.field'),
        fieldType: 'text',
        value: ''
      }
    }
  },
  mounted() {
    const isValue = !!Object.keys(this.values).length
    if(isValue) this.setValues(this.values)

    this.$nextTick(() => {
      this.validate();
      this.updateForm({})
    })
  },
  methods: {
    setValues(newValue) {
      this.primaryEmail.value =  newValue.primaryEmail
      this.alternativeEmail.value = newValue.alternativeEmail
    },
    updateForm(value) {
      this.form = {
        ...this.form,
        ...value
      }
      this.validate();
      this.$emit('update', this.form)
    },
    validate() {
      const errors = {};
      if (!this.form.primaryEmail) {
        errors.primaryEmail = i18n.t('orderingProcess.requestReason.form.primaryEmail.error');
      }
      if (!this.form.alternativeEmail) {
        errors.alternativeEmail = i18n.t('orderingProcess.requestReason.form.alternativeEmail.error');
      }
      this.$emit('validation', errors);
    },
  }
}
</script>
