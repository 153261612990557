<template>
  <fragment>
    <pre class="boldLarge">{{ title }}</pre>

    <slot/>

    <pre class="text" v-if="!!abstract">{{ abstract }}</pre>

  </fragment>
</template>

<script>
export default {
  name: "VideoTextContent",
  props: {
    title: {
      type: String,
      required: true
    },
    abstract: {
      type: String,
      required: true
    },
  }
}
</script>