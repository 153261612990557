<template>
  <div class="payment__template">
    <h1 class="payment__title">{{ $t("orderingProcess.title") }}</h1>
    <p class="payment__message">{{
        $t("orderingProcess.confirmPaid.message", {
          documentTitle: formatTitle(confirmPaidModel.documentName),
          documentPrice: formatPrice(confirmPaidModel.priceGross)
        })
      }}</p>

    <CheckboxFormElement
        v-bind:class="{hide: !confirmPaidModel.showRememberConfirmStatusQuery}"
        :element="rememberMeCheckboxObj"
        :passed-value="rememberMeCheckboxObj.rememberMe"
        @modified="rememberMeCheckboxObj.rememberMe = $event"/>


    <div class="payment__actions">
      <Button
          type="button"
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
      <Button
          type="button"
          variant="primary"
          :text="$t('genios.ok')"
          :toggle-text="false"
          @click="confirmPayment"
          text-style="600"
      />
    </div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import i18n from "../plugins/Translations.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";

export default {
  name: "ConfirmPaidFormComponent",
  components: {Button, CheckboxFormElement},
  props: {
    confirmPaidModel: Object,
    orderDataId: [String, Number]
  },
  data() {
    return {
      rememberMeCheckboxObj: {
        id: 'rememberMe',
        rememberMe: false,
        fieldLabel: i18n.t("orderingProcess.confirmPaid.remember")
      }
    }
  },
  methods: {
    confirmPayment() {
      const {buy} = this.$parent
      const params = {
        orderDataId: this.orderDataId,
        ignorePayedDocument: this.rememberMeCheckboxObj.rememberMe
      }
      buy({params})
    },
    formatPrice: function (price) {
      return Number(price).toLocaleString('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
    },
    formatTitle: function (title) {
      return title.replaceAll("&nbsp;", "\xa0")
    }
  }
}
</script>

<style>
.form_element__input-checkbox {
  position: absolute;
}

.form_element__label {
  justify-content: flex-start;
}
</style>
