<template>
    <div class="email_group" :class="!sharedState.loginStatus.loggedIn ? 'email_group__disable' : ''">

      <div class="email_group__list">
        <checkbox-form-element
            v-for="option in checkedEmailsGroup"
            :key="option.id"
            :element="option"
            :passed-value="option.fieldValue"
            :class="option.fieldValue ? 'checked': ''"
            @modified="modifyCheckboxFields($event, option)"
        />
      </div>

      <span class="email_group__text" @click="openEmailGroupPopup">{{
          $t('genios.monitoring.emailGroup.actionText')
        }}</span>
      <StandardPopupComponent v-show="sharedState.loginStatus.loggedIn && toggleEmailGroup" variant="standard">
        <email-group-component
            @close-popup="closePopup"
            :checked-emails-group="checkedEmailsGroup"
        />
      </StandardPopupComponent>
    </div>
</template>

<script>
import Vue from "vue";
import {Plugin} from "vue-fragment";

import {emailsGroupMixin} from "../../../functions/mixins/emailsGroup";

import StandardPopupComponent from "../styled/StandardPopupComponent.vue";
import EmailGroupComponent from "./EmailGroupComponent.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";

//======================================

Vue.use(Plugin)

//======================================

export default {
  name: "EmailGroupAction",
  components: {CheckboxFormElement, EmailGroupComponent, StandardPopupComponent},
  props: {
    sharedState: {
      type: Object,
      required: true
    },
    checkedEmailsGroup: {
      type: Array,
      required: true
    }
  },
  mixins: [emailsGroupMixin]
}
</script>