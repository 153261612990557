<template>

  <div class="extra_navigation_line">
    <div class="extra_navigation_line__left">
      <Button
          name="filter"
          id="extra_navigation_line_filter"
          @click="toggleSidePanel"
          :text="$t('genios.filter')"
          icon="filter-outline"
          variant="secondary"
          class="extra_navigation_line__button"
          position="left"
          type="button"
      />
      <Button
          name="advancedSearch"
          id="extra_navigation_line_advanced_search"
          @click="toggleAdvancedSearchPanel"
          :text="$t('genios.advancedSearch.name')"
          icon="options-outline"
          variant="secondary"
          class="extra_navigation_line__button"
          position="left"
          type="button"
      />
    </div>
    <div class="extra_navigation_line__right">
      <Button
          v-if="hasPermission('monitoring')"
          id="extra_navigation_line_monitoring"
          name="monitoring"
          :text="$t('genios.setupMonitoring')"
          icon="alarm-outline"
          variant="secondary"
          @click="openMonitoringForm"
          position="left"
          type="button"
      />
    </div>

<!--    <div class="searchResult__summary__articlesFound hide">-->
<!--      <p class="searchResult__summary__articlesFound__count">-->
<!--        {{ $t('genios.results') }}-->
<!--      </p>-->
<!--    </div>-->


  </div>

</template>

<script>

import eventBus from "../../eventBus";
import Vue from "vue";
import i18n from "./plugins/Translations.vue";
import MonitoringForm from "./Monitoring/MonitoringForm.vue";
import Button from "./styled/Button.vue";
import {openTransparentPopupModal} from "../../functions/opens";
import {hasPermission} from "../../functions/utils/permission_utils";

export default {
  name: "ExtraNavigationLine",
  components: {Button},
  props: {
    sharedState: Object
  },
  computed: {
  },
  data() {
    return {
      isConfirmed: false
    };
  },
  methods: {
    toggleSidePanel: function () {
      eventBus.$emit('toggleSidePanel', sessionStorage.getItem('showPanel'));
    },
    toggleAdvancedSearchPanel: function () {
      eventBus.$emit('toggleAdvancedSearch', sessionStorage.getItem('showPanel'));
    },
    openMonitoringForm: function () {
      const createMonitoringForm = () => {
        const MonitoringFormClass = Vue.extend(MonitoringForm);
        const monitoringFormInstance = new MonitoringFormClass({
          i18n,
          propsData: {
            sharedState: this.sharedState
          }
        });
        monitoringFormInstance.$mount();
        const overlayContent = document.querySelector("#overlay_content");
        overlayContent.appendChild(monitoringFormInstance.$el);
      };
      openTransparentPopupModal(createMonitoringForm);
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }
  }
};
</script>

<style scoped>

</style>
