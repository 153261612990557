<template>
  <button
      :class="`button__${variant}${variant !== 'icon' && (!text || (toggleText && width <= 680)) ?` icon-null`:''}${position?` ${position}`:''}${decoration?` decoration`:''}`"
      :id="id"
      :type="type"
      :name="name"
      :aria-label="ariaLabel"
      :style="cssProps"
      :disabled="disabled"
      @click="$emit('click', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
  >
    <span
        v-if="text"
        :class="toggleText ? 'btn-text' : ''"
        :style="textStyle ? `font-weight: ${textStyle};` : ''"
    >
      {{ text }}
    </span>
    <ButtonLoader
        v-if="loading"
        :size="$el.clientHeight - 6"
    />
    <span :style="`${(width <= 680 && !icon) ?'display: none': ''}`" v-else-if="icon">
      <ion-icon :name="icon[0] !== '/' && icon" :src="icon[0] === '/' && icon" class="md hydrated rotate-0" :class="rotateIcon ? 'rotate-180' : ''"></ion-icon>
    </span>
    <slot v-else/>
  </button>
</template>

<script>

import ButtonLoader from "./ButtonLoader.vue";

export default {
  name: "Button",
  components: {ButtonLoader},
  data() {
    return {
      width: window.innerWidth
    }
  },
  props: {
    rotateIcon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    text: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: false
    },
    ariaLabel: {
      type: String,
      required: false
    },
    id: [String, Number],
    icon: {
      type: [Object, String],
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    position: {
      type: String,
      default: null
    },
    height: {
      type: [String, Number]
    },
    border: {
      type: [String]
    },
    decoration: [String],
    textStyle: {
      type: [String, Number],
      default: null
    },
    color: {
      type: [String],
      default: null
    },
    toggleText: {
      type: Boolean,
      default: true
    },
    type: {
      type: [String],
      default: 'submit'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssProps() {
      if (this.border && this.height && this.color) return `height: ${this.height}px;border: ${this.border}; color: ${this.color}`
      else if (this.height && this.color) return `height: ${this.height}px';color: ${this.color}`
      else if (this.border && this.color) return `border: ${this.border};color: ${this.color}`
      else if (this.height) return `height: ${this.height}px`
      else if (this.border) return `border: ${this.border}`
      else if (this.color) return `color: ${this.border}`
      else return ''
    },
    slots() {
      if (this.$slots.default) return this.$slots.default
      return []
    }
  },
  created() {
    window.removeEventListener('resize', this.updateWidth)
    window.addEventListener('resize', this.updateWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>

.icon-null {
  padding: 5px;
}

.left {
  flex-direction: row-reverse;
}

.right {
  flex-direction: row;
}

.decoration:hover {
  text-decoration: underline;
}
</style>
