import axios from "axios";

export const checkFullPopupModalPosition = function () {
    const popup = document.querySelector("[js-handler*='checkFullPopupModalPosition']");
    if (!!popup && popup.style.visibility === 'visible') {
        const header = document.querySelector(".header");
        if (!!header) {
            const headerHeight = header.offsetHeight;
            const heightCalc = "calc(100vh - " + headerHeight + "px)";
            popup.style.height = heightCalc;
            popup.style.top = headerHeight + "px";
            const modalContent = popup.querySelector("#modal_content");
            if (!!modalContent) {
                modalContent.style.minHeight = heightCalc;
            }
        }
    }
}

export const checkLogin = async function () {
    const wasLoggedIn = window.sharedState?.loginStatus?.loggedIn || false;
    const response = await fetch('/api/login', {
        //check with Demeter, the AuthLib and Charon
        method: 'POST',
    });
    const loginResult = await response.json();
    window.sharedState.loginStatus = loginResult;
    if (!loginResult?.loggedIn) {
        window.sharedState.defaultUserEmail = "";
        sessionStorage.setItem('defaultUserEmail', "");
    } else if (!wasLoggedIn) {
        //newly logged in - get email
        axios.get(`/api/getUserDefaultEmail`).then((response) => {
            window.sharedState.defaultUserEmail = response.data;
            sessionStorage.setItem('defaultUserEmail', response.data);
        }).catch(error => {
            console.log(error);
        });
    }
    fetch('/login/login', {
        method: 'POST',
    }).then(/*genios check ignore result - just still doing this for session sharing i.e. getting JSESSIONID*/);
    return loginResult;
}

export const checkShowMoreShowLessSections = function (element = document.documentElement) {
    const showMoreLessAll = element.querySelectorAll('[js-handler*="checkShowMoreShowLessSections"]');
    showMoreLessAll.forEach(showMoreLess => {
        const $container = showMoreLess.parentElement;
        let hasHiddenText = showMoreLess.offsetHeight < showMoreLess.scrollHeight;
        const showMore = $container.querySelector('.show-more');
        const showLess = $container.querySelector('.show-less');
        if (showMoreLess && showMoreLess.classList.contains('hideContent')) {
            if (hasHiddenText) {
                if (showMore) showMore.classList.remove("hide");
                if (showLess) showLess.classList.add("hide");
            } else {
                const showMoreLess = $container.querySelector('.show-more:not(.hide),.show-less:not(.hide)');
                if (!!showMoreLess) {
                    showMoreLess.classList.add("hide");
                }
            }
        } else {
            if (!hasHiddenText) {
                if (showLess) showLess.classList.add("hide");
                if (showMore) showMore.classList.add("hide");
            } else {
                if (showMore) showMore.classList.add("hide");
                if (showLess) showLess.classList.remove("hide");
            }
        }
    });
}

export const checkSingleDocument = function (docId, checked) {

    const elements = document.querySelectorAll('[js-handler*="checkSingleDocument"][data-document-id="' + docId + '"]');
    elements.forEach(element => {
        element.classList[checked ? 'add' : 'remove']('selected');
        const checkBoxElement = element.querySelector(".article__checkbox");
        if (!!checkBoxElement) {
            checkBoxElement.checked = checked;
        }
    });
};

export const checkStoredSelectedItems = function (storedItems = window.sharedState.selectedDocuments) {
    document.querySelectorAll('[js-handler*="articleCheckboxClickHandler"]').forEach(item => {
        item.closest('.element').classList[item.checked ? 'add' : 'remove']('selected');
        const docId = item.getAttribute("value");
        let wasCheckedBefore = storedItems.filter(element => element.docId === docId).length > 0;
        if (wasCheckedBefore) {
            checkSingleDocument(docId, true);
        }
    });
};

export const checkUserPermission = () => {
    checkLogin().then()

    const USER_NAME = "u352097a"

    const {userInfo: {isConfirmed}, loginStatus: {userAccount}} = window.sharedState

    if (userAccount === USER_NAME && !isConfirmed)
        axios.post("/login/logout?embedded=true").then()

}

