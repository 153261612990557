<template>
  <div class="payment__template">
    <h1 class="payment__title">{{ $t('orderingProcess.title') }}</h1>
    <p class="payment__message">{{ $t('orderingProcess.companyGoodness.message') }}</p>
    <div class="payment__actions">
      <Button
          variant="primary"
          :text="$t('genios.ok')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
    </div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "CompanyGoodnessComponent",
  components: {Button},
  props: {
    message: String
  }
}
</script>
