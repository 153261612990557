<template>

  <div class="searchFromSources">

    <form id="searchFormFromSources">
      <div class="search_container">
        <div class="search_layout search-toolbar">

          <input
              class="search_layout__input search_subject"
              @keydown.enter.prevent="search"
              type="text"
              v-model="searchString"
              name="requestText"
              aria-label="Search text"
              tabindex="0"
              :placeholder="$t('genios.browseSources.searchInSources')"
              autofocus/>
          <Button
              @click="resetAction"
              name="reset"
              icon="close-outline"
              variant="secondary"
              border="none"
              type="reset"
              style="order: 2; margin-right: 5px;"
          />

          <Button
              type="button"
              name="main_search"
              @click="search"
              aria-label="search for documents"
              icon="search-outline"
              variant="primary"
              style="order: 2;"
          />


        </div>
      </div>
    </form>

  </div>


</template>

<script>


import Button from "../styled/Button.vue";
import vClickOutside from "v-click-outside";

export default {
  name: "SearchInputActionbarComponent",
  components: {Button},
  props: {
    sharedState: Object
  },
  data() {
    return {
      searchString: ''
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    search() {
      // basic path
      let path = window.location.origin + "/searchResult/";
      let navTree = sharedState.navigationTree;
      if (typeof navTree !== "undefined" && navTree.length > 0) {
        path += navTree[0].name;
      }
      let searchUrl = new URL(path);
      // search term
      searchUrl.searchParams.set('requestText', this.searchString);
      // filtered sources
      sharedState.selectedSources.forEach(sourceItem => {
            searchUrl.searchParams.append("source", sourceItem.source);
          }
      );
      // redirect
      window.location.assign(searchUrl);
    },
    resetAction() {
      this.$emit('closeWidget', true);
    },
    clickOutside() {
      const searchBox = document.querySelector('.searchFromSources')
      searchBox.style.display = 'none'
    }
  }
};
</script>


<style scoped>

.searchFromSources {
  padding: 0 5px;
  margin: 10px 10px 20px;
}

.search_subject {
  font-size: 14px;
}

.search-toolbar {
  padding: 0 5px 0 15px;
}

</style>
