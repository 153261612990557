<template>
      <textarea
          ref="input"
          :id="element.id"
          :name="element.field"
          v-model="value"
          @focus="setFocus()"
          @blur="unsetFocus()"
          class="form_element__text-area"
          :aria-label="element.fieldLabel"
          :placeholder="focus === element.id ? '' : element.fieldLabel"
      />

</template>

<script>


export default {
  name: "TextAreaFormElement",
  components: {},
  data() {
    return {
      value: this.passedValue,
      showLabelElement: !this.hideLabel
    };
  },
  props: {
    element: Object,
    passedValue: String,
    focus: [String, Number],
    error: Boolean
  },
  mounted() {
    this.$nextTick(() => {
      if (this.focus === this.element.id) {
        this.focusInput();
      }
    });
  },
  computed: {
    formElementClass: function () {
      return this.element.fieldType.toLowerCase() +
          ' ' + (this.focus === this.element.id ? 'focus' : '') +
          ' ' + (this.value ? 'filled' : '') +
          ' ' + (this.error ? 'form_element-error' : '');
    }
  },
  watch: {
    value: function (val) {
      this.$emit("modified", val);
    },
    passedValue: function (val) {
      this.value = val;
    }
  },
  methods: {
    setFocus: function () {
      this.$emit("focus", this.element.id);
    },
    unsetFocus: function () {
      this.$emit("focus", null);
    },
    focusInput() {
      this.$refs.input.focus();
    }
  }
};
</script>


