import i18n from "../../vue/components/plugins/Translations.vue";
import {changeHistory} from "../utils/history_utils";

export const fidoComponent = Object.freeze({
    FIRMENPROFIL: i18n.t("genios.FIDO.companyProfile"),
    HANDELSREGISTER: i18n.t("genios.FIDO.commercialRegister"),
    CHARTS: i18n.t("genios.FIDO.charts"),
    JAHRESABSCHLUSS: i18n.t("genios.FIDO.annualAccounts"),
    BONITAETEN: i18n.t("genios.FIDO.creditInformation"),
    VERFLECHTUNGEN: i18n.t("genios.FIDO.integration"),
    BRANCHENINFORMATIONEN: i18n.t("genios.FIDO.industryInformation"),
    PRESSE: i18n.t("genios.FIDO.press")

});

export const updateExpandedListNameAction = (store, newListName) => {
    const miniListKeys = ["FIRMENPROFIL", "HANDELSREGISTER", "JAHRESABSCHLUSS", "CHARTS", "BONITAETEN", "VERFLECHTUNGEN", "BRANCHENINFORMATIONEN", "PRESSE"]
    window.sharedState.fidoCategory = newListName;
    store.expandedListName = newListName;

    const curIndex = miniListKeys.indexOf(newListName);
    const prev = miniListKeys[curIndex - 1];
    store.prevListName = prev ? prev : "";

    const next = miniListKeys[curIndex + 1];
    store.nextListName = next ? next : "";

    changeHistory({"expandedListName": newListName}, false);
}

export const updateHasItemInListAction = (store, {category, value}) => {
    store.hasItemsInList[category] = value;
}

export const updateSelectedItemAction = (store, value) => {
    store.selectedItem = value;
    changeHistory({"bundleDocumentId": value.itemId}, false);
}

export const changeUserInteractedAction = (store, value) => {
    store.userInteracted = value;
}

export const addPaidItemsByCategory = (store, {category, paidItems}) => {
    if (!!paidItems && !!category) {
        //get old items
        let existingPaidItems = store.paidDocumentsMap[category];
        if (existingPaidItems === undefined) {
            existingPaidItems = [];
        }
        //add all old to new
        existingPaidItems.push(...paidItems);
        //remove duplicates
        store.paidDocumentsMap[category] = existingPaidItems.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.itemId === value.itemId
                ))
        );
    }
}
