<template>

  <div class="myNav" id="myNavOverlay" @keydown.esc="closeNav">
    <div class="blocker hide" @click="closeNav">

    </div>
    <div class="myNavOverlay">
      <Button
          type="button"
          v-if="currentLevelDepth === 0"
          @click="closeNav"
          id="sideNavCloseBtn"
          variant="secondary"
          border="none"
          :text="$t('genios.close')"
          icon="close-outline"
          position="left"
          class="panel-btn"
          :toggle-text="false"
      />
      <button v-if="currentLevelDepth > 0"
              type="button"
              class="mobile-nav__btn-up hyperlink-menu"
              @click="navigate(currentLevel.path.slice(0, currentLevelDepth - 1))" ref="back">
        <ion-icon aria-hidden="true" src="/icons/custom/chevron-back-outline.svg"></ion-icon>
        <span class="mobile-nav__btn-up__text">
              {{ $t("genios.menu") }}
            </span>
      </button>
      <div class="border-line"></div>


      <div class="overlay-content">

        <div class="overlay-content__sourcesHeader" v-if="currentLevelDepth === 0">
          <span>{{ $t("genios.sources") }}</span>
          <SourceSearcher :explicitRoot="navigationRootName" :openSourceBrowsingPage="true"
                          :shared-state="sharedState"/>
        </div>

        <nav class="mobile-nav" aria-label="Source sections">
          <transition-group :name="transitionName" tag="div" class="mobile-nav__levels" ref="levels">
            <div v-if="currentLevel"
                 class="mobile-nav__level"
                 role="group"
                 tabindex="-1"
                 :key="getPathName(currentLevel.path)"
                 :ref="`level-${getPathName(currentLevel.path)}`"
            >
              <ul class="mobile-nav__list no-decoration">
                <li v-if="currentLevelDepth > 0 && currentLevel.name"
                    class="mobile-nav__item">
                  <button
                      @click="openUrl(currentLevel.url)"
                      class="mobile-nav__listHeader">
                    {{ currentLevel.name }}
                  </button>
                </li>

                <li class="mobile-nav__item" v-for="item in currentLevel.items" :key="item.path.join()">

                  <button v-if="item.nextLevel && item.name!==navigationRootName"
                          type="button"
                          class="mobile-nav__link__button"
                          :aria-label="'subsection ' + item.name"
                          @click="navigate(item.path)"
                          @keydown.right="navigate(item.path)" ref="link">
                    {{ item.name }}
                    <ion-icon aria-hidden="true" src="/icons/custom/chevron-forward-outline.svg"/>
                  </button>

                  <button v-else
                          @click="openUrl(item.url)"
                          class="mobile-nav__link__button"
                          ref="link">
                    {{ item.name }}
                  </button>

                </li>
              </ul>
            </div>

            <div v-show="currentLevelDepth<1" :key="-1">

              <div class="line"></div>

              <div type="button" class="overlay-content__element">
                <div class="overlay-content__help__support">
                  <p>{{ $t("genios.helpAndSupport") }}</p>
                </div>
              </div>
              <button
                  v-for="link in links"
                  type="button"
                  class="overlay-content__element"
              >
                <div class="line" v-if="link === 'line'"/>
                <a
                    v-else
                    class="overlay-content__help hyperlink-menu"
                    :href="link.src"
                    @click.prevent="openLink(link.src, link.target)"
                >
                  <p>{{ $t(link.labelCode) }}</p>
                </a>
              </button>

            </div>

          </transition-group>

        </nav>


      </div>


    </div>
  </div>
</template>

<script>
import eventBus from '../../eventBus';
import qs from 'qs';
import smoothReflow from 'vue-smooth-reflow';
import SourceSearcher from "./Browsing/SourceSearcher.vue";
import Button from "./styled/Button.vue";
import {menuNavigationLinks} from "../../functions/consts";

export default {
  name: "MenuNavigation",
  components: {Button, SourceSearcher},
  props: {
    type: String,
    name: String,
    sharedState: Object
  },

  mixins: [smoothReflow],
  data() {
    return {
      currentPosition: [],
      levels: [],
      currentLevel: null,
      prevLevelDepth: 0,
      currentLevelDepth: 0,
      mode: document.documentElement.classList.contains('dark-theme'),
      loaded: this.sharedState.loadedData,
      searchTerm: qs.parse(window.location.search, {ignoreQueryPrefix: true}).requestText
    };
  },
  computed: {
    links() {
      return menuNavigationLinks(this.sharedState.solution);
    },
    menuItems: function () {
      return this.sharedState?.navigationTree || [];
    },
    transitionName() {
      return 'slide-' + (this.currentLevelDepth > this.prevLevelDepth ? 'left' : 'right');
    },
    navigationRootName: function () {
      if (!this.sharedState.navigationTree) return '';
      return this.sharedState?.navigationTree[0]?.name || '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.recalculateItems();
      this.$smoothReflow({
        el: this.$refs.levels?.$el
      });
    });
    eventBus.$on("updateSearchTerm", this.handleUpdateSearchTerm);
  },
  watch: {
    menuItems: function () {
      this.recalculateItems();
      this.$smoothReflow({
        el: this.$refs.levels?.$el
      });
    }
  },
  methods: {
    openUrl(url) {
      location.href = url;
    },
    openLink(link, target) {
      let features = '';
      if (target === '_self') {
        target = 'Windows';
        features = 'width=1080,height=800,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no,left=200,top=200';
      }
      window.open(link, target, features);
    },

    navigate(path) {
      this.currentPosition = path;
      this.recalculateItems();
      this.$nextTick(() => {
        this.$refs[`level-${this.getPathName(this.currentPosition)}`].focus();
      });
    },

    recalculateItems() {
      let items = this.menuItems;
      let levels = [];

      levels.push(this.getLevel([], null, items, this.navigationRootName, null));

      this.currentPosition.forEach((index, depth) => {
        const path = [...this.currentPosition].slice(0, depth + 1);
        const name = items[index] && items[index].name || null;
        const urlSafeName = items[index].urlSafeName;
        items = items[index] && items[index].nextLevel || [];
        const entry = this.getLevel(path, name, items, this.navigationRootName, urlSafeName);
        levels.push(entry);
      });

      this.levels = levels;
      this.currentLevel = levels[levels.length - 1];
      this.prevLevelDepth = this.currentLevelDepth;
      this.currentLevelDepth = levels.length - 1;
    },

    getLevel(path, name, items, rootName, urlSafeName) {
      function iterate(that, path, name, items) {
        return items.map((item, index) => {
          return {
            path: [...path, index],
            name: item.name,
            url: generateUrl(path, name, item.urlSafeName, rootName),
            textId: item.textId,
            urlSafeName: item.urlSafeName,
            nextLevel: item.nextLevel && item.nextLevel.length > 0
          };
        });
      }

      function generateUrl(path, name, urlSafeName, rootName) {
        let result = '/' + encodeURIComponent('browse');

        if (urlSafeName !== rootName) {
          result = result + '/' + encodeURIComponent(rootName);
        }
        if (path.length > 0) {
          result = result + '/' + encodeURIComponent(name);
        }

        if (null !== name && name !== urlSafeName) {
          result += `?category=${encodeURIComponent(urlSafeName)}`
        } else {
          result += '/' + encodeURIComponent(urlSafeName);
        }

        return result
      }

      return {
        path: path,
        name: name,
        url: generateUrl(0, name, urlSafeName, rootName),
        items: iterate(this, path, name, items)
      };
    },
    getPathName(path) {
      if (!path || path.length < 0) {
        return 0;
      }

      return [0, ...path].join('-');
    },


    handleUpdateSearchTerm: function (searchTerm) {
      this.searchTerm = searchTerm;
    },

    closeNav: function () {
      const myNavModal = document.querySelector(".myNavOverlay");
      const myNav = document.querySelector('.myNav');
      const blocker = myNav.querySelector(".blocker");

      myNavModal.style.maxWidth = "0";
      blocker.classList.add('hide');

      document.getElementById('search_subject').focus();
    }

  }
};
</script>

<style scoped>
</style>
