<template>
  <div class="payment__template">
    <MainHeader/>
    <div class="payment__container">
      <Steps :code="`${currentCode}`" :steps="steps" :current-step="currentStep"
             @set-step="currentStep = $event"/>
      <div class="payment__windows">
        <p class="payment__title" id="current-title">{{ currentTitle }}</p>
        <PaymentBucket :orderDataModel="orderDataModel" :current-step="currentStep"/>
        <PaymentContainer>
          <UserEmailPasswordComponent
              v-if="getForm('choice')"
              :orderDataId="orderDataId"
              :shared-state="sharedState"
              :order-response="orderDataResponse"
              :document-ids="documentIds"
              :title="currentTitle"
              @update-title="currentTitle = $event"
              @update-step="currentCode = $event.code;orderDataResponse = $event"
          />
          <PaymentMethodComponent
              v-else-if="getForm('paymentType')"
              @update-step="currentCode = $event.code;orderDataResponse = $event"
              :orderDataId="orderDataResponse.orderDataId"
              :order-response="orderDataResponse"
              :title="currentTitle"
              @update-title="currentTitle = $event"
              :document-ids="documentIds"
          />
          <TermsAndConditionsComponent
              v-else-if="getForm('info')"
              @update-step="currentCode = $event.code;orderDataResponse = $event"
              :orderResponse="orderDataResponse"
              :title="currentTitle"
              @update-title="currentTitle = $event"
              :previewMode="previewMode"
              :document-ids="documentIds"
          />
        </PaymentContainer>
      </div>
      <div class="payment__footer">
        <span class="payment__footer__back-btn" @click="$parent.closeWindow(true)">{{ $t('orderingProcess.mainForm.backBtn') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UserEmailPasswordComponent from "./UserEmailPasswordComponent.vue";
import PaymentMethodComponent from "./PaymentMethodComponent.vue";
import MainHeader from "./MainHeader.vue";
import Steps from "./Steps.vue";
import PaymentBucket from "./PaymentBucket.vue";
import PaymentContainer from "./PaymentContainer.vue";
import {paymentSteps} from "../../../../functions/components/payment"
import i18 from "../../plugins/Translations.vue";
import TermsAndConditionsComponent from "./TermsAndConditionsComponent.vue";

export default {
  name: "MainFormComponent",
  components: {
    TermsAndConditionsComponent,
    PaymentBucket,
    UserEmailPasswordComponent,
    PaymentMethodComponent,
    Steps,
    MainHeader,
    PaymentContainer,
    i18
  },
  props: {
    sharedState: Object,
    documentIds: {
      type: Array,
      require: true
    },
    code: {
      type: String
    },
    orderDataId: {
      type: [String, Number],
      required: true
    },
    orderDataModel: Object,
    orderResponse: {
      type: Object,
      required: true
    },
    previewMode: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      currentStep: this.getEnabledSteps()[this.getEnabledSteps().length > 2 ? paymentSteps.findIndex(item => item.code === this.code) : paymentSteps.findIndex(item => item.code === this.code) - 1].name,
      currentCode: this.code,
      steps: this.getEnabledSteps(),
      orderDataResponse: this.orderResponse,
      currentTitle: this.code === 'SHOW_LIGHT_REGISTRATION_FORM' ?
          i18.t('orderingProcess.mainForm.guestTitle') :
          this.code === 'SHOW_PAYMENT_MAIN_FORM' ?
              i18.t('orderingProcess.mainForm.paymentMethod.title') :
              i18.t('orderingProcess.mainForm.termsAndConditions.title')
    }
  },
  mounted() {
    if (paymentSteps.map(item => item.code).includes(this.code))
      this.$parent.$el.firstChild?.classList.add('full_size')
  },
  methods: {
    getEnabledSteps() {
      const {code} = this
      const orderQuantity = JSON.parse(sessionStorage.getItem('paymentId'))?.orderQuantity || 0

      let newArr = (parseInt(orderQuantity) > 0 && window.sharedState.loginStatus.loggedIn) ?
          i18.t('orderingProcess.mainForm.steps').filter((item, index) => index !== 0) :
          i18.t('orderingProcess.mainForm.steps')
      newArr = newArr.map((item, index, arr) => {
        const newIndex = arr.length > 2 ? index : index + 1
        return {
          text: item,
          index: index,
          name: paymentSteps[newIndex].name,
          code: paymentSteps[newIndex].code,
          disabled: false
        }
      })
      let nextStepIndex = newArr.findIndex(item => item.code === code)
      newArr = newArr.map((item, index) => {
        return {
          ...item,
          disabled: !(index === nextStepIndex)
        }
      })
      return newArr
    },
    getForm(currentStep) {
      return this.currentStep === currentStep
    }
  },
  watch: {
    currentCode(newCode) {
      this.currentCode = newCode
      const nextStepIndex = this.steps.findIndex(item => item.code === newCode)
      if (nextStepIndex >= 0) {
        this.steps = this.steps.map((item, index) => {
          return {
            ...item,
            disabled: !(index === nextStepIndex)
          }
        })
        this.currentStep = this.steps[nextStepIndex].name
      }
    },
    currentStep(newStep) {
      this.currentTitle = newStep === 'choice' ?
          i18.t('orderingProcess.mainForm.guestTitle') :
          newStep === 'paymentType' ?
              i18.t('orderingProcess.mainForm.paymentMethod.title') :
              i18.t('orderingProcess.mainForm.termsAndConditions.title')
      this.currentStep = newStep
    },
    orderDataResponse(newValue) {
      this.orderDataResponse = newValue
    }
  }
}
</script>

<style>
.payment__footer {
  cursor: pointer;
}
</style>

