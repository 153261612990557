<template>
  <div class="search">
    <form
        id="searchForm"
        action="#"
        @submit="search"
    >
      <div class="search_container">
        <div class="search_layout">
          <div v-if="show"
               id="search_field"
               class="search_layout__category">
            <v-select
                ref="selection"
                label="name"
                :searchable="false"
                :clearable="false"
                v-model="selected"
                :value="selected"
                :options="options"
                @input="focusOnRequestText();"
            >
              <template #open-indicator="{ attributes }">
                <ion-icon v-bind="attributes" src="/icons/custom/chevron-down-outline.svg"></ion-icon>
              </template>
            </v-select>
          </div>

          <div v-if="show" class="search_layout__separator"></div>
          <input
              ref="requestText"
              class="search_layout__input search_subject"
              type="text"
              id="search_subject"
              v-model="searchString"
              name="requestText"
              aria-label="Search text"
              tabindex="0"
              :placeholder="$t('genios.search.input.placeholder', {category: selected.name})"
          />
          <Button
              ref="clearSearchButton"
              id="clear_search_button"
              name="close"
              variant="icon"
              @mousedown="reset"
              @click="reset"
              type="button"
              icon="close-outline"
              v-if="!disabled"
          />
          <Button
              ref="searchButton"
              id="search_button"
              name="search"
              :text="$t('genios.words.search')"
              variant="primary"
              aria-label="search"
              position="left"
              :text-style="400"
              type="submit"
              icon="search-outline"
          />

        </div>
      </div>
    </form>
  </div>


</template>

<script>

import {closeFullPopupModal} from "../../functions/closing";
import vSelect from 'vue-select';
import i18n from "./plugins/Translations.vue";
import Button from "./styled/Button.vue";
import {fetchCountsForNavigationItems} from "../../functions/fetching";
import {replaceSearchResult} from "../../functions/replacing";
import {excludeTypeArray} from "../../functions/components/searchComponent";

export default {
  name: "SearchComponent",
  components: {Button, vSelect, i18n},
  props: {
    sharedState: Object
  },
  data() {
    return {
      humanSelected: '',
      searchString: this.sharedState.queryString,
      requestedItems: {},
      show: this.sharedState.page !== "index",
      sourceTranslated: i18n.t("genios.source"),
      categoryTranslated: i18n.t("genios.category"),
      width: window.innerWidth
    };
  },
  computed: {
    selectedBrowseSource: function () {
      return this.sharedState.selectedBrowseSource;
    },
    navigationTree: function () {
      return this.sharedState?.navigationTree || [];
    },
    options: function () {
      let itemList = [];
      for (let item of this.navigationTree) {
        itemList.push(item);
      }
      if (this.sharedState.page !== "document") {
        if (this.navigationTree.length < 1) {
          // set up at least the url values if navigation tree not there yet
          if (!!this.sharedState.urlTopLevelNavigation) {
            itemList.push({
              "name": this.sharedState.urlTopLevelNavigation,
              "urlSafeName": this.sharedState.urlTopLevelNavigation.replace("/\//g", "|")
            });
          }
          if (!!this.sharedState.urlSecondLevel) {
            itemList.push({
              "name": this.sharedState.urlSecondLevel,
              "urlSafeName": this.sharedState.urlSecondLevel.replace("/\//g", "|")
            });
          }
          if (!!this.sharedState.urlThirdLevel) {
            itemList.push({
              "name": this.sharedState.urlThirdLevel,
              "urlSafeName": this.sharedState.urlThirdLevel.replace("/\//g", "|")
            });
          }
        }
        if (this.sharedState.urlThirdLevel) {
          itemList.push({"name": this.sharedState.urlThirdLevel, "filterType": "category"});
        }
        if (this.sharedState.selectedBrowseSource.name && !excludeTypeArray.includes(this.sharedState.selectedBrowseSource.type)) {
          itemList.push({"name": this.sharedState.selectedBrowseSource.name, "filterType": "source"});
        }
      }
      return itemList
    },
    navigationRootName: function () {
      if (!this.navigationTree) return '';
      if (this.navigationTree.length < 1) return '';
      if (this.navigationTree[0] === undefined) return '';
      return this.navigationTree[0].name || '';
    },
    autoSelectedString: function () {
      return this.sharedState.selectedBrowseSource.name
          || this.sharedState.urlThirdLevel
          || this.sharedState.urlSecondLevel
          || this.sharedState.urlTopLevelNavigation
          || this.navigationRootName;
    },
    autoSelected: function () {
      return this.options.find(option => option.name === this.autoSelectedString) || this.options[0]
    },
    selected: {
      get: function () {
        if (this.humanSelected) {
          return this.humanSelected
        } else if (this.sharedState.path.split("/").filter(it => it).length === 3 && excludeTypeArray.includes(this.sharedState.selectedBrowseSource.type)) {
          const result = this.sharedState.selectedBrowseSource.type
          return {
            name: result,
            urlSafeName: result.replace("/\//g", "|")
          }
        } else if (this.sharedState.path.split("/").filter(it => it).length === 4 && excludeTypeArray.includes(this.sharedState.selectedBrowseSource.type)) {
          return this.options.find(option => option.filterType === "category")
        } else
          return this.autoSelected
      },
      set: function (newValue) {
        this.humanSelected = newValue;
      }
    },
    searchPath: function () {
      let path = window.location.origin + "/searchResult/";
      let navroot = this.navigationRootName;// (Array.isArray(this.navigationTree) && this.navigationTree.length) ? this.navigationTree[0].name : '';
      path += navroot;
      let selectedName = (typeof this.selected === "object") ? this.selected.name : this.selected;
      if (navroot === selectedName) {
        return path;
      }
      if (this.selected.filterType) {
        if (this.sharedState.urlSecondLevel) {
          path += ('/' + this.sharedState.urlSecondLevel);
        }
      } else if (selectedName !== undefined) {
        path += ('/' + selectedName);
      }

      return path;
    },
    disabled: function () {
      return this.searchString.trim().length < 1;
    }
  },
  watch: {
    selectedBrowseSource: function (newValue) {
      if (newValue) {
        this.humanSelected = this.options.find(option => option.name === newValue);
      }
    }
  },
  mounted() {
    window.removeEventListener('resize', this.updateWidth)
    window.addEventListener('resize', this.updateWidth)

    this.$refs.searchButton.$el.addEventListener("mousedown", this.onMouseDownHandler)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
    this.$refs.searchButton.$el.removeEventListener("mousedown", this.onMouseDownHandler)
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth
    },
    focusOnRequestText: function () {
      this.$nextTick(() => this.$refs.requestText.focus());
    },
    translate(text) {
      if (text === "source") {
        return this.sourceTranslated;
      } else {
        return this.categoryTranslated;
      }
    },
    reset() {
      this.searchString = '';
      this.focusOnRequestText();
    },
    onMouseDownHandler(event) {
      event.preventDefault()
      event.target.blur()
    },
    search(event) {
      event.preventDefault();
      if (this.disabled) {
        this.focusOnRequestText();
        this.$refs.requestText.blur();
        return;
      }

      let path = this.searchPath;

      let currentUrl = new URL(window.location);
      let newUrl = new URL(path);

      if (this.selected.filterType) {
        newUrl.searchParams.set(this.selected.filterType, this.selected.name);
      }

      if (currentUrl.pathname === newUrl.pathname) {
        currentUrl.searchParams.forEach((value, key) => {
          if (!(['preview', 'document', 'offset'].includes(key)))
            newUrl.searchParams.append(key, value);
        });
        window.sharedState.queryString = this.searchString;
        window.sharedState.offset = 0;
        if (window.sharedState.pathRoot === "searchResult") {
          /*
           if (this.selected.filterType) {
           this.sharedState.activeFilters.category = [];
           this.sharedState.activeFilters.source = [];
           this.sharedState[this.selected.filterType] = [this.selected.name];
           }
           */
          closeFullPopupModal(false, false);
          replaceSearchResult();
          let textIdsTop = window.sharedState.navigationTree.map(navItem => navItem.textId);
          fetchCountsForNavigationItems(textIdsTop);
          this.$refs.requestText.blur();
          return;
        }
      }
      newUrl.searchParams.set('requestText', this.searchString);
      window.location.assign(newUrl);
      this.$refs.requestText.blur();
    }
  }
};
</script>

<style scoped>
#search_button {
  padding: 4px !important;
  min-width: 34px;
}
</style>
