<template>
  <div class="payment__template">
    <h1 class="payment__title">{{$t('orderingProcess.noCouponWarning.title')}}</h1>
    <p class="payment__message">{{$t('orderingProcess.noCouponWarning.message')}}</p>
    <div class="payment__actions">
      <Button
          type="button"
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
      <Button
          type="button"
          variant="primary"
          :text="$t('orderingProcess.noCouponWarning.buyNow')"
          :toggle-text="false"
          @click="submit"
          text-style="600"
      />
    </div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "NoCouponWarningFormComponent",
  components: {Button},
  props: {
      orderDataId: [String, Number]
  },
  methods: {
    submit() {
      const bodyParams = {
        orderDataId: this.orderDataId,
      }
      const {buy} = this.$parent

      buy({params: bodyParams})
    }
  }
}
</script>
