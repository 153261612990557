<!-- HtmlUnknownAttribute -->
<template>
  <div id="filter_dateRange" class="filter">
    <filter-title
        has-toggle
        :show="show"
        :title="title"
        key="filter-title"
        v-on:toggle-show="toggleShow()"
    />
    <transition name="grow">
      <div class="filter__item_container" v-if="show">
        <RangeFormElement
            :element="dateRangeElement"
            :passedValue="selectedRange"
            :focus="focus"
            :max-date="todaysDate()"
            v-on:focus="focus = $event"
            v-on:modified="newCustomRangeSelected($event)"
        />
        <div
            class="filter__item_container__date_range_select"
            v-click-outside="hideOptions"
        >
          <Button
              type="button"
              variant="secondary"
              :toggle-text="false"
              :class="showOptions ? 'filter__item_container__date_range_select__selected-active' : ''"
              @click="showOptions = !showOptions"
              :aria-expanded="showOptions ? 'true' : 'false'"
              :text="selected"
              border="none"
              style="background-color: rgba(0,0,0,0);"
          >
            <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="showOptions ? 'rotate-180' : ''"/>
          </Button>
          <div
              class="filter__item_container__date_range_select__options"
              v-if="showOptions"
          >
            <button
                type="button"
                class="filter__item_container__date_range_select__options__option"
                v-for="(option, index) in options"
                v-if="index < 5"
                v-on:click.stop="selectRange(index)"
                v-on:mousedown.stop=""
                v-on:mouseup.stop=""
            >{{ option }}
            </button>
          </div>
        </div>
        <div class="filter__item_container__chart">
          <div class="filter__item_container__chart__buttons">
            <button
                type="button"
                @click="histogramGoBackwards"
            >
              <ion-icon src="/icons/custom/chevron-back-outline.svg"/>
            </button>
            <button
                type="button" v-if="showForwardButton"
                @click="histogramGoForwards"
            >
              <ion-icon src="/icons/custom/chevron-forward-outline.svg"/>
            </button>
            <div v-else></div>
          </div>
          <DateFilterChart
              ref="chart"
              :chartData="chartData"
              :chartHeight="200"
              :selectedFrom="currentFrom"
              :selectedTo="currentTo"
              :scale="histogramScale"
              :fullChartRangeDisplay="fullChartRangeDisplay"
              @rangeSelected="chartRangeSelected"
          ></DateFilterChart>
          <div class="filter__item_container__spinner_container"
               v-if="fetchingHistogram">
            <spinner/>
          </div>
        </div>
        <div class="filter__item_container__bottom_buttons">
          <button
              type="button"
              @click="selectScale('day')"
              class="">
            <ion-icon
                v-if="histogramScale === 'day'"
                name="radio-button-on-outline"
            ></ion-icon>
            <ion-icon
                v-else
                name="radio-button-off-outline"
            ></ion-icon>
            <span>{{ $t("genios.dateFilter.day") }}</span>
          </button>
          <button
              type="button"
              @click="selectScale('week')"
              class="">
            <ion-icon
                v-if="histogramScale === 'week'"
                name="radio-button-on-outline"
            ></ion-icon>
            <ion-icon
                v-else
                name="radio-button-off-outline"
            ></ion-icon>
            <span>{{ $t("genios.dateFilter.week") }}</span>
          </button>
          <button
              type="button"
              @click="selectScale('month')"
              class="">
            <ion-icon
                v-if="histogramScale === 'month'"
                name="radio-button-on-outline"
            ></ion-icon>
            <ion-icon
                v-else
                name="radio-button-off-outline"
            ></ion-icon>
            <span>{{ $t("genios.dateFilter.month") }}</span>
          </button>
          <button
              type="button"
              @click="selectScale('year')"
              class=""
          >
            <ion-icon
                v-if="histogramScale === 'year'"
                name="radio-button-on-outline"
            ></ion-icon>
            <ion-icon
                v-else
                name="radio-button-off-outline"
            ></ion-icon>
            <span>{{ $t("genios.dateFilter.year") }}</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FilterTitle from "./FilterTitle.vue";
import i18n from "../plugins/Translations.vue";
import RangeFormElement from "../FormElements/RangeFormElement.vue";
import Spinner from "../styled/Spinner.vue";
import DateFilterChart from "./dateFilterChart/DateFilterChart.vue";
import qs from "qs";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import vSelect from 'vue-select';
import vClickOutside from 'v-click-outside';
import Button from "../styled/Button.vue";
import {buildPath, buildSearchParametersFromCurrentState} from "../../../functions/utils/url_utils";
import {replaceSearchResult} from "../../../functions/replacing";


const maxUnits = 30;

// noinspection RedundantIfStatementJS,JSUnusedLocalSymbols
export default {
  name: "DateFilter",
  components: {vSelect, FilterTitle, RangeFormElement, DateFilterChart, Spinner, Button},
  props: {
    title: String,
    sharedState: Object
  },
  data: function () {
    const fullFormat = 'DD.MM.YYYY';
    return {
      show: true,
      dateRangeElement: {
        id: 0,
        fieldType: "RANGE",
        dataType: "DATE"
      },
      focus: null,
      type: "date",
      fullFormat: fullFormat,
      chartData: [],
      fetchingHistogram: true,
      lastParams: {},
      histogramScale: "day",
      histogramFrom: moment().subtract(maxUnits, 'days').startOf('day').format(fullFormat),
      histogramTo: moment().format(fullFormat),
      overrideHistogramDatesText: null,
      selected: i18n.t('genios.dateFilter.histogramText.option5'),
      showOptions: false,
      options: [
        i18n.t('genios.dateFilter.histogramText.option1'),
        i18n.t('genios.dateFilter.histogramText.option2'),
        i18n.t('genios.dateFilter.histogramText.option3'),
        i18n.t('genios.dateFilter.histogramText.option4'),
        i18n.t('genios.dateFilter.histogramText.option5'),
        i18n.t('genios.dateFilter.histogramText.option6'),
      ],
      selectedRange: {},
      reactionTimer: null,
      noReaction: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    currentValues: function () {
      return this.sharedState.activeFilters[this.type] || [];
    },
    currentValue: function () {
      return (!!this.currentValues) ? this.currentValues[0] : null;
    },
    currentFrom: function () {
      return (!!this.currentValue) ? this.currentValue.from : null;
    },
    currentTo: function () {
      return (!!this.currentValue) ? this.currentValue.to : null;
    },
    currentHistogramRange: function () {
      return {
        from: this.histogramFrom,
        to: this.histogramTo
      };
    },
    currentHistogramFromMoment: function () {
      return moment(this.histogramFrom || moment().subtract(maxUnits, 'years').format(this.fullFormat), this.fullFormat);
    },
    currentHistogramToMoment: function () {
      return moment(this.histogramTo || moment().startOf('day').format(this.fullFormat), this.fullFormat);
    },
    fullChartRangeDisplay: function () {
      const scaleToDisplayText = {
        'year': (date) => {
          return date.format('YYYY')
        },
        'month': (date) => {
          return date.format('MMMM YYYY')
        },
        'week': (date) => {
          return date.format(this.fullFormat)
        },
        'day': (date) => {
          return date.format(this.fullFormat)
        }
      }

      return {
        from: scaleToDisplayText[this.histogramScale](this.currentHistogramFromMoment),
        to: scaleToDisplayText[this.histogramScale](this.currentHistogramToMoment)
      }
    },
    currentFromMoment: function () {
      return this.currentFrom ? moment(this.currentFrom, this.fullFormat) : null;
    },
    currentToMoment: function () {
      return this.currentTo ? moment(this.currentTo, this.fullFormat) : null;
    },
    activeFilters: function () {
      return this.sharedState.activeFilters;
    },
    advancedSearchParams: function () {
      return this.sharedState.advancedSearchParams;
    },
    requestText: function () {
      return this.sharedState.queryString;
    },
    histogramParams: function () {
      let generalParams = buildSearchParametersFromCurrentState();
      delete generalParams.offset;
      delete generalParams.date;

      return {
        ...generalParams,
        date: ["from_" + this.histogramFrom, "to_" + this.histogramTo],
        size: 0,
        getDateHistograms: this.histogramScale
      };
    },
    showForwardButton: function () {
      return moment(this.histogramTo, this.fullFormat).isBefore(moment().startOf('day'));
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.currentValues.length) {
        this.selectedRange = {
          from: this.currentFrom,
          to: this.currentTo
        };
      } else {
        this.getHistogram();
      }
    })
  },
  watch: {
    requestText: function (newValue) {
      this.delayedGetHistogram();
    },
    activeFilters: {
      handler(val) {
        if (val.date && val.date.length < 1) {
          this.selectedRange = {};
        }
        this.delayedGetHistogram();
      },
      deep: true
    },
    advancedSearchParams: {
      handler(val) {
        this.delayedGetHistogram();
      },
      deep: true
    }
  },
  methods: {
    hideOptions: function (event) {
      this.showOptions = false;
    },
    delayedGetHistogram: function () {
      if (!!this.reactionTimer) {
        clearTimeout(this.reactionTimer);
      }
      this.reactionTimer = setTimeout(() => {
        this.getHistogram();
      }, 200);
    },
    todaysDate: function () {
      return moment().endOf('day').toDate();
    },
    toggleShow: function () {
      this.show = !this.show;
      this.getHistogram();
    },
    updateDateRange: function (newRange) {
      const oldDateRange = _.cloneDeep(this.currentValue);

      function isEmpty(dateRange) {
        if (!dateRange) return true;
        return !(!!dateRange.from || !!dateRange.to);

      }

      if (!_.isEqual(newRange, oldDateRange) && !(isEmpty(oldDateRange) && isEmpty(newRange))) {
        if (!!newRange && (!!(newRange.from) || !!(newRange.to))) {
          this.sharedState.activeFilters[this.type] = [newRange];
        } else {
          this.sharedState.activeFilters[this.type] = [];
        }
        window.sharedState.offset = 0; // reset for getting new results
        replaceSearchResult();
      }
    },
    chartRangeSelected: function (range) {
      const newRange = {
        from: range.from.format(this.fullFormat),
        to: range.to.format(this.fullFormat)
      };
      this.updateDateRange(newRange);
      this.noReaction = true;
      this.selectedRange = newRange;
      this.selected = this.options[5];
    },
    newCustomRangeSelected: function (newRange) {
      if (this.noReaction) {
        this.noReaction = false;
        return;
      }
      const selectedText = this.options[5];
      if (newRange && (newRange.from || newRange.to)) {
        this.updateDateRange(newRange);
        this.updateHistogramDateRange(newRange);
        this.selected = selectedText;
      } else if (this.selected === selectedText) {
        this.selectRange(4);
      }
    },
    updateHistogramDateRange: function (newRange, newScale) {
      if (!_.isEqual(newRange, this.currentHistogramRange) || newScale) {
        const newHistogramFrom = newRange.from || moment()
            .subtract(maxUnits, 'years')
            .startOf('year')
            .format(this.fullFormat);
        this.histogramFrom = newHistogramFrom;
        const newHistogramTo = newRange.to || moment().format(this.fullFormat);
        this.histogramTo = newHistogramTo;

        const fromMoment = moment(newHistogramFrom, this.fullFormat)
        const toMoment = moment(newHistogramTo, this.fullFormat)
        const timeDiff = toMoment.diff(fromMoment, 'days');

        if (newScale) {
          this.histogramScale = newScale;
        } else {
          // find a sensible scale
          if (timeDiff > (365 * 4)) {
            this.histogramScale = 'year';
          } else if (timeDiff > (maxUnits * 3)) {
            this.histogramScale = 'month';
          } else if (timeDiff > (7 * 5)) {
            this.histogramScale = 'week';
          } else {
            this.histogramScale = 'day';
          }
        }
        //max 30 min 7
        const diff = toMoment.diff(fromMoment, this.histogramScale);
        if (diff > maxUnits) {
          this.histogramFrom = toMoment.subtract(maxUnits, this.histogramScale).format(this.fullFormat);
        } else {
          const minimumNumberOfColumns = this.histogramScale === 'year' ? maxUnits : 12;
          if (diff < minimumNumberOfColumns) {
            let histogramToMoment = toMoment.add(3, this.histogramScale);
            const today = moment().startOf('day');
            if (histogramToMoment.isAfter(today)) {
              this.histogramTo = today.format(this.fullFormat);
              histogramToMoment = today;
            } else {
              this.histogramTo = histogramToMoment.format(this.fullFormat);
            }
            this.histogramFrom = histogramToMoment.subtract(Math.max(minimumNumberOfColumns - 1, diff + 3), this.histogramScale).format(this.fullFormat);
          }
        }
        //correct histogramFrom for scale
        this.histogramFrom = moment(this.histogramFrom, this.fullFormat).startOf(this.histogramScale).format(this.fullFormat);
        this.getHistogram();
      }
    },
    selectRange: function (index) {
      this.showOptions = false;
      this.selected = this.options[index];
      this.selectedRange = {};

      let newFrom = '';
      let newTo = '';
      let histogramFrom;
      let histogramTo;
      let histogramScale;
      if (index === 0) {
        // last 3 days
        newFrom = moment().startOf('day').subtract(2, 'days').format(this.fullFormat);
        newTo = moment().format(this.fullFormat);
        histogramFrom = moment().startOf('day').subtract(6, 'days').format(this.fullFormat);
        histogramTo = newTo;
        histogramScale = 'day';
      } else if (index === 1) {
        // this week
        newFrom = moment().startOf('week').format(this.fullFormat);
        newTo = moment().format(this.fullFormat);
        histogramFrom = moment().subtract('21', 'days').startOf('week').format(this.fullFormat);
        histogramTo = newTo;
        histogramScale = 'day';
      } else if (index === 2) {
        // this month
        newFrom = moment().startOf('month').format(this.fullFormat);
        newTo = moment().format(this.fullFormat);
        histogramFrom = moment().startOf('day').subtract(maxUnits, 'days').format(this.fullFormat);
        histogramTo = newTo;
        histogramScale = 'day';
      } else if (index === 3) {
        // last 2 years
        newFrom = moment().startOf('year').subtract(1, 'year').format(this.fullFormat);
        newTo = moment().format(this.fullFormat);
        histogramFrom = moment().subtract(maxUnits, 'months').startOf('month').format(this.fullFormat);
        histogramTo = moment().format(this.fullFormat)
        histogramScale = 'month';
      } else if (index === 4) {
        // max (no filter)
        histogramFrom = moment().subtract(maxUnits, 'years').startOf('year').format(this.fullFormat);
        histogramTo = moment().format(this.fullFormat)
        histogramScale = 'year';
      }
      this.histogramFrom = histogramFrom;
      this.histogramTo = histogramTo;
      this.histogramScale = histogramScale;
      this.updateDateRange({
        from: newFrom,
        to: newTo
      })
      this.getHistogram();
    },
    selectScale: function (scale) {
      //check if current selected range is within current histogram range and if it is use the selected range
      // if it isn't then we paged away so stay more or less where we are
      // TODO check if paged behind or beyond so we take the start or end of the current range
      if (this.currentToMoment || this.currentFromMoment) {
        let newTo = this.currentHistogramRange.to;
        let newFrom = this.currentHistogramRange.from;
        if (this.currentToMoment && this.currentToMoment.isBefore(this.currentHistogramToMoment)) {
          newTo = this.currentTo;
        }
        if (this.currentFromMoment && this.currentFromMoment.isAfter(this.currentHistogramFromMoment)) {
          newFrom = this.currentFrom;
        }
        this.updateHistogramDateRange({from: newFrom, to: newTo}, scale);
      } else {
        this.updateHistogramDateRange(this.currentHistogramRange, scale);
      }
    },
    convertHistogramToChartData: function (key, value) {
      const today = moment().startOf('day');
      const keyMoment = moment(key, 'DD-MM-YYYY');
      if (keyMoment.isAfter(today)) {
        return [];
      }
      let toMoment = moment(key, 'DD-MM-YYYY').endOf(this.histogramScale).startOf('day');
      if (toMoment.isAfter(today)) {
        //never go after today
        toMoment = today;
      }
      let displayKey;
      const scaleToDisplayText = {
        'year': () => {
          return keyMoment.format('YYYY')
        },
        'month': () => {
          return keyMoment.format('MMMM YYYY')
        },
        'week': () => {
          return keyMoment.format(this.fullFormat) + '-' + toMoment.format(this.fullFormat)
        },
        'day': () => {
          return keyMoment.format(this.fullFormat)
        }
      }
      displayKey = scaleToDisplayText[this.histogramScale]();
      return {
        key: displayKey,
        count: value.count,
        from: keyMoment,
        to: toMoment
      };
    },
    histogramGoBackwards: function () {
      const currentNumberOfElements = this.chartData.length;
      const amount = Math.min(Math.max(currentNumberOfElements - 1, 7), maxUnits); //min 7 max 30
      const newFrom = moment(this.histogramFrom, this.fullFormat).subtract(amount, this.histogramScale).startOf(this.histogramScale).format(this.fullFormat);
      const newTo = moment(this.histogramFrom, this.fullFormat).endOf(this.histogramScale).format(this.fullFormat);
      this.histogramFrom = newFrom;
      this.histogramTo = newTo;
      this.getHistogram();
    },
    histogramGoForwards: function () {
      const currentNumberOfElements = this.chartData.length;
      const amount = Math.min(Math.max(currentNumberOfElements - 1, 7), maxUnits); //min 7 max 30
      let newToMoment = moment(this.histogramTo, this.fullFormat).add(amount, this.histogramScale).endOf(this.histogramScale);
      if (newToMoment.isAfter(moment().startOf('day'))) {
        newToMoment = moment().startOf('day');
      }
      this.histogramTo = newToMoment.format(this.fullFormat);
      this.histogramFrom = newToMoment.subtract(amount, this.histogramScale).startOf(this.histogramScale).format(this.fullFormat);
      this.getHistogram();
    },
    getHistogram: function () {
      if (!this.show) return;
      const path = buildPath('/api/searchResult');
      const params = this.histogramParams;
      if ((this.fetchingHistogram || this.chartData.length) && _.isEqual(params, this.lastParams)) {
        return;
      }
      this.lastParams = _.cloneDeep(params);
      if (this.fetchingHistogram && this.fetchingHistogram.cancel) {
        this.fetchingHistogram.cancel();              // cancel old request
      }
      this.fetchingHistogram = axios.CancelToken.source();

      axios.get(path, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: "repeat"});
        },
        cancelToken: this.fetchingHistogram.token
      }).then((response) => {
        // noinspection JSUnresolvedVariable
        const aggregations = response.data.aggregations;
        let chartDataArray = [];
        if (!!aggregations) {
          const histogram = aggregations[this.histogramScale];
          if (!!histogram) {
            const histogramEntries = Object.entries(histogram);
            chartDataArray = histogramEntries.flatMap(
                ([key, value]) =>
                    this.convertHistogramToChartData(key, value)
            );
          }
          //sometimes we get data too early TODO find out why
          if (chartDataArray.length && chartDataArray[0].from.isBefore(this.currentHistogramFromMoment)) {
            chartDataArray.shift();
          }
        }
        this.chartData = chartDataArray;
        this.fetchingHistogram = false;
      }).catch(error => {
        if (axios.isCancel(error)) {
          console.log('Histogram request canceled');
        } else {
          console.log("Error: " + error);
          this.chartData = [];
          this.fetchingHistogram = false;
        }
      }).finally(() => {
      });
    }
  }
}
</script>

<style scoped>
</style>
