<template>
  <div class="query-popup">
    <p class="query-popup__title">{{ label }}</p>
    <spinner v-if="isLoading"/>
    <fragment v-else>
      <span v-if="pagesQuantity <= 0">{{ htmlContent }}</span>
      <page-viewer-component
          v-else
          :current-page="currentPage"
          :content="htmlContent"
      />

      <pagination-component
          v-if="pagesQuantity > 1"
          :pages="pagesQuantity"
          :selected="currentPage"
          @update-page="currentPage = $event"
      />
    </fragment>

  </div>

</template>

<script>
import axios from "axios";
import _ from "lodash";

import Vue from 'vue'
import {Plugin} from "vue-fragment"

import {convertStringToHtml} from "../../../functions/utils/string_utils";

import Spinner from "../styled/Spinner.vue";
import i18n from "../plugins/Translations.vue";

import PaginationComponent from "../styled/PaginationComponent.vue";
import PageViewerComponent from "../styled/PageViewerComponent.vue";
import {getDocumentPrices} from "../../../functions/services";
import {addLoader} from "../../../functions/stylization";
import FullDocumentComponent from "./FullDocumentComponent.vue";
import {changeHistory} from "../../../functions/utils/history_utils";
import {setupFullDocumentComponent, setupPaymentFormComponent} from "../../../functions/setups/vue-components";
import {PaymentMode} from "../../../functions/components/payment";
import {closeTransparentPopupModal} from "../../../functions/closing";
import qs from "qs";

Vue.use(Plugin)

export default {
  name: "QueryPopup",
  components: {FullDocumentComponent, PageViewerComponent, PaginationComponent, Spinner},
  props: {
    query: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    dbShortcuts: {
      type: Array,
      required: true
    },
    sharedState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      htmlContent: [],
      isLoading: true,
      offset: 3,
      pagesQuantity: 0,
      currentPage: 1
    }
  },
  async mounted() {
    await this.fetchQuery()

    await this.$nextTick(async () => {
      await this.customUpdatePrices(this.$el);
      this.setupCustomDocumentFunctionality()
    })
  },
  methods: {
    async fetchQuery() {
      const root = this.sharedState.navigationTree[0].urlSafeName,
          path = `/searchResult/${root}`;
      //sometimes queries have characters that is not understandable for ES. Here is the place to fix it
      let requestText = decodeURIComponent(this.query).replaceAll("+", " ")

      const params = {
        requestText,
        partial: true,
        size: 50,
        tags: this.dbShortcuts
      }

      try {
        const {data} = await axios.get(path, {
          params,
          paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: "repeat"}); //this line needed because of array [tags]
          }
        })

        const html = convertStringToHtml(data).parentNode
        const content = html.querySelector('.list-container')

        const articles = Array.from(content.children)
        const pages = _.chunk(articles, this.offset)

        this.pagesQuantity = pages.length

        if (content.children.length > 0) {
          this.htmlContent = pages
        } else {
          this.htmlContent = i18n.t("queryPopup.noDocumentsMessage")
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
    async customUpdatePrices(element) {
      const links = element.querySelectorAll('[js-handler*="updatePrices"]') || []
      const documents = Array.from(links).map(item => {
        //TODO: with documentId displayText is always with price
        return {
          documentId: item.dataset.legacyDocId,
          database: item.dataset.documentId.split('__')[0],
          priceCategory: ''
        }
      })

      const data = await getDocumentPrices(documents)

      const isLoggedIn = this.sharedState.loginStatus.loggedIn || false;

      for (let item of links) {
        const isButton = item.classList.contains("buy_button");
        if (isButton) {
          const priceInfo = data[item.dataset.legacyDocId];
          if (priceInfo) {
            const isPrimaryButton = item.classList.contains("button__primary"), {paid} = priceInfo;

            if (isPrimaryButton && paid) {
              item.classList.replace("button__primary", "button__secondary");
              item.classList.add("paid");
            } else if (!isLoggedIn && isPrimaryButton) {
              item.classList.replace("button__secondary", "button__primary");
            }

            item.innerHTML = priceInfo.displayText;

            item.classList.remove("hide");

            item.dataset.price = priceInfo.price;
            item.dataset.paid = priceInfo.paid;
          }
        }
      }
    },
    setupCustomDocumentFunctionality() {
      const domElement = this.$el;
      const documentLinks = domElement.querySelectorAll('.js_open_full_document_modal') || [];

      const openArticleOnClick = async (e) => {
        e.preventDefault();
        e.stopPropagation()

        const docId = e.currentTarget.getAttribute("data-document-id");
        const linkType = !!e.target.dataset.linkType
        if (linkType)
          await addLoader(e, "link", async () => {
            await this.openDocument(docId)
          })
        else {
          await this.openDocument(docId)
        }
      };
      documentLinks.forEach(link => {
        link.removeEventListener('click', openArticleOnClick);
        link.addEventListener('click', openArticleOnClick);
      });
    },
    async openDocument(documentId) {

      changeHistory({queryPopupId: documentId}, true);

      const params = {}

      const {data} = await axios.post(`/api/retrieveDocuments`, {
        ids: [documentId],
        forceConfirmPrice: false,
        ...params
      });

      if (data?.code) {
        await setupPaymentFormComponent(data, documentId, PaymentMode.queryPopup);
      } else {
        await setupFullDocumentComponent(documentId)
      }

      closeTransparentPopupModal()
    },
  },
  watch: {
    async currentPage() {
      await this.$nextTick(async () => {
        await this.customUpdatePrices(this.$el);
        this.setupCustomDocumentFunctionality()
      })
    }
  }
}
</script>