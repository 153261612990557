<template>
  <div class="payment__template">
    <p class="payment__message" v-if="orderResponse.validationModel"> {{ orderResponse.validationModel.message }} </p>
    <p class="payment__message" v-if="orderResponse.errorMessage" v-html="orderResponse.errorMessage"></p>
    <div class="payment__actions">
      <Button
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";

export default {
  name: "ErrorPaymentCode",
  components: {
    Button
  },
  props: {
    orderResponse: {
      type: Object,
      required: true
    }
  }
}
</script>