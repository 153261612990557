<template>

  <div class="sidePanel">
    <div class="searchResult__filters fixed" v-if="sizePanel === 'open'">

      <overlay-scrollbars
          v-if="showOverlayScrollbarSection"
          class="sidenav"
          :options="osOptions"
      >
        <Button
            type="button"
            variant="secondary"
            @click="toggleAdvancedSearch"
            icon="options-outline"
            :text="$t('genios.advancedSearch.name')"
            :toggle-text="false"
            class="panel-btn reset_div"
            border="none"
            position="left"
            style="padding-left: 25px;"
            :disabled="disableAdvancedSearch"
            :title="disableAdvancedSearch?$t('genios.advancedSearch.disabledTooltip'):''"
        />
        <div class="border-line border-line-fix"></div>


        <div class="sidenav__header" @click="togglePanel()">
          <div class="sidenav__text">
            <p>{{ $t("genios.filter") + ' ('+this.getActiveNavigationName()+')'}}</p>
          </div>
          <div class="sidenav__toggle">
            <ion-icon src="/icons/custom/chevron-back-outline.svg" class="sidenav__toggle__icon"/>
            <Button
                type="button"
                class="sidenav__toggle__show_button"
                :class="{ 'sticky_button': isHeaderSticky }"
                variant="primary"
                :text="$t('genios.filters.use')"
                :toggle-text="false"
            />
          </div>
        </div>
        <div class="border-line"></div>
        <div class="filters">
          <DateFilter
              :title="dateRangeFilterParams.title"
              :shared-state="sharedState"
          />
          <CheckBoxFilter
              v-for="(value, name) in filterParams"
              v-if="shownFilters.includes(name)"
              :key="name"
              :id="name + '_filter'"
              class="filter"
              :shared-state="sharedState"
              :title="value.title"
              :search-placeholder="value.searchPlaceholder"
              :type="name"
              :max-number="value.maxNumber"
              aria-label="search results filter"
          >
          </CheckBoxFilter>
        </div>
        <div class="border-line"></div>
        <div class="filter__footer" v-show="someFiltersActive">
          <button
              type="button"
              class="filter__clear"
              @click="clearFilters()"
              aria-label="clear filters">
            <ion-icon name="trash-outline"/>
            <span>{{ $t("genios.filters.clear") }}</span>
          </button>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="searchResult__filters fixed" v-else-if="sizePanel === 'closed'" aria-label="Filter">
      <div class="sidenav-medium">
        <Button
            type="button"
            variant="secondary"
            border="none"
            @click="toggleAdvancedSearch"
            icon="options-outline"
            class="panel-btn reset_div"
            style="justify-content: center"
            :disabled="disableAdvancedSearch"
            :title="disableAdvancedSearch?$t('genios.advancedSearch.disabledTooltip'):''"
        />

        <div class="border-line border-line-fix"></div>

        <Button
            type="button"
            variant="secondary"
            border="none"
            @click="togglePanel"
            icon="filter-outline"
            class="panel-btn"
            style="justify-content: center"
        />

        <div class="border-line"></div>

      </div>
    </div>
    <AdvancedSearchComponent
        :sidePanelPosition="this.sizePanel"
        :source-filters="sourceFilters"
        :category-filters="categoryFilters"
        :advanced-search-params="advancedSearchParams"
        :key="reloadKey"
        @empty-search-masks="(disable)=>this.disableAdvancedSearch = disable"
    />
  </div>

</template>

<script>

import CheckBoxFilter from "./Filters/CheckBoxFilter.vue";
import AdvancedSearchComponent from "./AdvancedSearch/AdvancedSearchComponent.vue";
import i18n from "./plugins/Translations.vue";
import eventBus from "../../eventBus";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';
import DateFilter from "./Filters/DateFilter.vue";
import Button from "./styled/Button.vue";
import {buildPath} from "../../functions/utils/url_utils";
import axios from "axios";
import {replaceSearchResult} from "../../functions/replacing";
import {getAuthCookieCacheBuster} from "../../functions/utils/cookie_utils";


export default {
  name: "SidePanel",
  components: {
    Button,
    DateFilter,
    CheckBoxFilter,
    AdvancedSearchComponent,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    sharedState: Object
  },
  data() {
    return {
      mode: localStorage.getItem('mode'),
      sizePanel: this.initialSizePanel(),
      savedWindowWidth: window.innerWidth,
      savedDefaultByScreenSize: this.defaultByScreenSize(),
      dateRangeFilterParams: {
        title: i18n.t("genios.dateRange")
      },
      osOptions: {
        clipAlways: false,
        scrollbars: {autoHide: 'move', clickScrolling: true},
        callbacks: {
          onScroll: this.onScroll
        }
      },
      filterParams: {
        category: {
          title: i18n.t("genios.categories"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 40
        },
        source: {
          title: i18n.t("genios.sources"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 15
        },
        company: {
          title: i18n.t("genios.companies"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        person: {
          title: i18n.t("genios.people"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        author: {
          title: i18n.t("genios.authors"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        instructor: {
          title: i18n.t("genios.instructors"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        industry: {
          title: i18n.t("genios.industries"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        topic: {
          title: i18n.t("genios.themes"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        region: {
          title: i18n.t("genios.regions"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        }
      },
      reloadKey: 0,
      isHeaderSticky: false,
      disableAdvancedSearch: false,
      showOverlayScrollbarSection: false
    };

  },
  computed: {
    sourceFilters: function () {
      return this.sharedState.activeFilters.source;
    },
    categoryFilters: function () {
      return this.sharedState.activeFilters.category;
    },
    advancedSearchParams: function () {
      return this.sharedState.advancedSearchParams;
    },
    urlSecondLevel: function () {
      return this.sharedState.urlSecondLevel;
    },
    filtersAvailable: function () {
      return this.sharedState.filtersAvailable[this.urlSecondLevel] || [];
    },
    zFiltersToShow: function () {
      if (!this.urlSecondLevel) {
        // fixed set of filters for top level navigation
        return ["industry", "company", "person", "region", "topic"];
      }
      return this.filtersAvailable;
    },
    shownFilters: function () {
      let filtersToShow = [];
      if (this.categoryFilters || this.urlSecondLevel) {
        filtersToShow.push("category");
      }
      filtersToShow.push("source");
      for (let filterType of ["industry", "company", "theme", "region", "topic", "person", "author", "instructor"]) {
        let filterValues = this.sharedState.activeFilters[filterType];
        if ((Array.isArray(filterValues) && !!filterValues.length)
            ||
            this.zFiltersToShow.includes(filterType)) {
          filtersToShow.push(filterType);
        }
      }
      return filtersToShow;
    },
    someFiltersActive: function () {
      if (sharedState.advancedSearchParams.length > 0) {
        return true;
      }
      let filterLists = Object.values(sharedState.activeFilters);
      for (let filterList of filterLists) {
        if (filterList.length > 0) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    //This is only because overlayscrollbars take ages to initialise
    this.showOverlayScrollbarSection = true;
    // noinspection JSUnusedLocalSymbols
    window.addEventListener('resize', (event) => {
      const newDefaultByScreenSize = this.defaultByScreenSize();
      const oldDefaultByScreenSize = this.savedDefaultByScreenSize;
      const newWindowWidth = window.innerWidth;
      if (newDefaultByScreenSize !== oldDefaultByScreenSize) {
        if (oldDefaultByScreenSize === 'open'
            &&
            newDefaultByScreenSize === 'closed'
            && this.sizePanel === 'open') {
          this.closePanel();
        } else if (oldDefaultByScreenSize === 'closed'
            &&
            newDefaultByScreenSize === 'open'
            && this.sizePanel === 'closed') {
          this.openPanel();
        }
      }
      if (newWindowWidth < 1025 && this.savedWindowWidth >= 1025) {
        this.closePanel();
      }
      this.savedDefaultByScreenSize = newDefaultByScreenSize;
      this.savedWindowWidth = newWindowWidth;
    });
    // noinspection JSUnusedLocalSymbols
    eventBus.$on("toggleSidePanel", (data) => {
      this.togglePanel();
    });
    this.$nextTick(() => {
      this.getFiltersAvailable();
    });
  },
  methods: {
    getFiltersAvailable() {
      let filtersAvailablePath = buildPath('/api/filtersAvailable');
      let urlTopLevelNavigation = window.sharedState.urlTopLevelNavigation;
      let urlSecondLevel = window.sharedState.urlSecondLevel;
      // use auth cookie as cache busting trick for user switching etc.
      const params = {
        auth: getAuthCookieCacheBuster(),
      }

      axios.get(filtersAvailablePath, {params: params}).then(response => {
        if (!!response.data) {
          const filtersAvailable = response.data;
          let newFiltersAvailable = {};
          newFiltersAvailable[urlSecondLevel || urlTopLevelNavigation] = filtersAvailable;
          window.sharedState.filtersAvailable = {
            ...window.sharedState.filtersAvailable,
            ...newFiltersAvailable
          };
          // reportSearch it for reuse
          localStorage.setItem('availableFilters', JSON.stringify(window.sharedState.filtersAvailable));
        }

        // use/access the results
      }).catch(error => {
        console.log(error);
      });
    },
    getStoredState() {
      return sessionStorage.getItem('showPanel');
    },
    setStoredState(value) {
      sessionStorage.setItem('showPanel', value);
    },
    onScroll() {
      let parent = this.$el.querySelector('.sidenav__header');
      let buttonTop = parent.querySelector('.sidenav__toggle__show_button');
      if (!!parent) {
        this.isHeaderSticky = parent.getBoundingClientRect().top <= 158;
      }
    },
    togglePanel() {
      const currentSizePanel = this.sizePanel;
      if (currentSizePanel === 'open') {
        this.closePanel();
      } else if (currentSizePanel === 'closed') {
        this.openPanel();
      }
      this.rerenderSearchResult(this.sizePanel);
    },
    openPanel() {
      this.sizePanel = 'open';
      this.setStoredState('open');
      this.rerenderSearchResult('open');
    },
    closePanel() {
      this.sizePanel = 'closed';
      this.setStoredState('closed');
      this.rerenderSearchResult('closed');
    },
    toggleAdvancedSearch() {
      eventBus.$emit('toggleAdvancedSearch', this.sizePanel);
    },
    initialSizePanel() {
      let storedValue = this.getStoredState();
      if (!storedValue || !(['open', 'closed'].includes(storedValue))) {
        this.setStoredState(this.defaultByScreenSize());
        storedValue = this.defaultByScreenSize();
      }
      this.rerenderSearchResult(storedValue);
      return storedValue;
    },
    rerenderSearchResult(showPanel) {
      let searchResult = document.querySelector('.searchResult');
      if (showPanel === 'open') {
        searchResult.classList.remove('left_aligned');
        searchResult.classList.add('shifted_right');
      } else if (showPanel === 'closed') {
        searchResult.classList.add('left_aligned');
        searchResult.classList.remove('shifted_right');
      }
    },
    defaultByScreenSize() {
      const innerWidth = window.innerWidth;
      if (innerWidth < 1254) {
        return 'closed';
      }
      return "open";
    },
    clearFilters() {
      let currentActiveFilters = {...this.sharedState.activeFilters};
      Object.keys(currentActiveFilters).forEach(key => {
        this.sharedState.activeFilters[key] = [];
      });
      this.sharedState.advancedSearchParams = [];
      this.reloadKey = !this.reloadKey; // refresh advanced search
      replaceSearchResult();
    },
    getActiveNavigationName() {
      const navigationName = this.sharedState.urlSecondLevel
      if (!navigationName) {
        return this.sharedState.urlTopLevelNavigation
      } else
        return navigationName
    }
  }
};
</script>

<style scoped>

</style>
