<template>
  <div class="source__mobile-element">
    <div @click.stop.prevent="updateShowForm" class="source__mobile-toggle-btn" tabindex="0">
      {{ $t(filter.title) }}
      <ion-icon src="/icons/custom/chevron-down-outline.svg" class="md hydrated rotate-0"
                :class="showForm === filter.name ? 'rotate-180' : ''"/>
    </div>
    <transition name="grow">
      <div class="source__mobile-list" v-show="showForm === filter.name">
        <source-content :filter="filter" :shared-state="sharedState"/>
      </div>
    </transition>
  </div>
</template>

<script>
import SourceContent from "./SourceContent.vue";
import {browseFiltersMethodsMixin} from "../../../../functions/mixins/browseFilters";

export default {
  name: "MobileSourceFilterElement",
  components: {SourceContent},
  mixins: [browseFiltersMethodsMixin]
}
</script>