import { render, staticRenderFns } from "./VideoFooter.vue?vue&type=template&id=e3e60df0&scoped=true&"
import script from "./VideoFooter.vue?vue&type=script&lang=js&"
export * from "./VideoFooter.vue?vue&type=script&lang=js&"
import style0 from "./VideoFooter.vue?vue&type=style&index=0&id=e3e60df0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3e60df0",
  null
  
)

export default component.exports