import { render, staticRenderFns } from "./FidoNavigationBoxesComponent.vue?vue&type=template&id=53ec7a93&scoped=true&"
import script from "./FidoNavigationBoxesComponent.vue?vue&type=script&lang=js&"
export * from "./FidoNavigationBoxesComponent.vue?vue&type=script&lang=js&"
import style0 from "./FidoNavigationBoxesComponent.vue?vue&type=style&index=0&id=53ec7a93&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ec7a93",
  null
  
)

export default component.exports