import {openPreview, setupFullPreview} from "../opens";
import {closeAllPreviews} from "../closing";


export const swipeInPreview = function (event) {
    const {currentTarget, detail: {dir}} = event;
    // const resultDiv = document.querySelector(`.result-div`);
    const tile = document.querySelector(`.grid-view .media_tile[data-document-id=${currentTarget.id}]`);
    const nextTile = tile.nextElementSibling;
    const previousTile = tile.previousElementSibling;

    if (dir === "left") {
        // nextElementSibling
        if (currentTarget?.nextElementSibling) {
            window.scroll(0, nextTile.offsetTop - 20);
            currentTarget.removeEventListener('swiped', swipeInPreview);
            if (currentTarget.classList.contains('full_preview')) {
                resizeDoc();
                openPreview(currentTarget.nextElementSibling.id, true, true);
            } else {
                openPreview(currentTarget.nextElementSibling.id);
                currentTarget.classList.remove('full_preview');

            }

        }
    } else if (dir === "right") {
        // previousElementSibling
        if (currentTarget?.previousElementSibling) {

            window.scroll(0, previousTile.offsetTop - 20);
            currentTarget.removeEventListener('swiped', swipeInPreview);
            if (currentTarget.classList.contains('full_preview')) {
                resizeDoc();
                openPreview(currentTarget.previousElementSibling.id, true, true);
            } else {
                openPreview(currentTarget.previousElementSibling.id);
                currentTarget.classList.remove('full_preview');

            }
            // currentTarget.classList.remove('full_preview');

        }
    } else if (dir === "up") {
        if (!currentTarget.classList.contains("full_preview")) {
            currentTarget.classList.add('full_preview');
            resizeDoc();
            setupFullPreview(currentTarget, currentTarget.id);
        }

    } else {//down
        if (!currentTarget.classList.contains("full_preview")) {
            closeAllPreviews();
        } else if(event.target.classList.contains("border-line-mobile") ||
            event.target.classList.contains("media_preview__main_content__preview")){
            currentTarget.classList.remove('full_preview');
        }
    }
};

const resizeDoc = function () {
    let timeoutId = null;
    const documentHeight = () => {
        clearTimeout(timeoutId); // avoid execution of previous timeouts
        timeoutId = setTimeout(() => {
            const doc = document.documentElement;
            let vh = window.innerHeight * 0.01;
            console.log(window.innerHeight);
            doc.style.setProperty('--vh', `${vh}px`)
        }, 200);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
}
