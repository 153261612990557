<template>
  <div class="full-document">
    <document-loader v-if="isLoading"/>
    <div v-else v-html="html"/>
  </div>
</template>

<script>
import axios from "axios";

import Vue from "vue";
import {Plugin} from 'vue-fragment'

import {watchlistAddAllButtons} from "../../../functions/components/watchlist";
import {setupShareButton} from "../../../functions/components/shareButton";
import {openQueryPopup} from "../../../functions/opens";
import {setupAttachmentDownloadHandler, setupCompanyNotificationButton} from "../../../functions/setups/handlers";
import {setupMiniPopup, setupSendEmailFunctionality} from "../../../functions/setups";

import DocumentLoader from "../styled/DocumentLoader.vue";
import {changeHistory} from "../../../functions/utils/history_utils";

Vue.use(Plugin)

export default {
  name: "FullDocumentComponent",
  components: {DocumentLoader},
  props: {
    documentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      html: '',
      isLoading: true
    }
  },
  async created() {
    await this.loadDocument(this.documentId)

    this.setupFunctionality()
  },
  methods: {
    setupCloseFullDocument() {
      const closeBtn = this.$el.querySelector('[js-handler="closeLinkClickHandler"]')
      const closeAction = (event) => {
        event.stopPropagation()
        changeHistory({queryPopupId: ''}, true)

        this.$destroy()
        this.$el.parentNode.removeChild(this.$el)
      }

      closeBtn.removeEventListener("click", closeAction)
      closeBtn.addEventListener("click", closeAction)
    },
    async loadDocument(documentId) {
      try {
        let {data} = await axios.post(`/document/${documentId}?embed=true&withButtons=true`, {})

        if (window.sharedState.page !== "document") {
          data = data + document.querySelector(".footer").outerHTML;
        }

        this.html = data
      } catch (e) {
        console.log("FullDocumentComponent:loadDocument:error => ", e)
        console.dir(e)
      } finally {
        this.isLoading = false
      }
    },
    setupFunctionality() {
      const docBody = this.$el
      setupMiniPopup(docBody)
      setupAttachmentDownloadHandler(docBody);
      setupSendEmailFunctionality(docBody, this.documentId);
      watchlistAddAllButtons();
      setupShareButton(docBody);
      openQueryPopup(docBody)
      this.setupCloseFullDocument()
      setupCompanyNotificationButton(docBody.querySelector('#company_notification_button'));
    }
  }
}
</script>

<style scoped>
</style>