import axios from "axios";

const createHost = () => {
    return axios.create({
        baseURL: "/api/unzer"
    });
}

export {
    createHost
}
