<template>
  <div class="fido_component_container" :data-fido-id="documentId">
    <div class="document_and_metadata_container"
         :data-document-title="store.fidoBasicInfo.companyName"
         :data-legacy-doc-id="fidoLegacyId"
    >
      <div class="document">
        <div class="with-fixed-size">
          <div class="top_navigation">
            <div class="document_additional_info" ref="documentAdditionalInfo">
              <div class="action_icons">
                <div class="tooltip tooltip-icon">
                  <Button
                      type="button"
                      id="article_content_print"
                      variant="icon"
                      aria-label="print"
                      icon="print-outline"
                      :data-document-id="documentId"
                      :data-legacy-doc-id="fidoLegacyId"
                      :data-print="true"
                      class="not_paid"
                      @click="share"
                  />
                  <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.print")}}</div>
                </div>

                <div class="tooltip tooltip-icon">
                  <Button
                      type="button"
                      v-if="sharedState.loginStatus.loggedIn"
                      variant="icon"
                      :data-document-id="documentId"
                      :data-legacy-doc-id="fidoLegacyId"
                      aria-label="share"
                      class="not_paid"
                      @click="share"
                  >
                    <ion-icon src="/icons/custom/PDF-document-outline.svg"></ion-icon>
                  </Button>
                  <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.openPdf")}}</div>
                </div>

                <watchlist-bookmark-toggle-component
                    :shared-state="sharedState"
                    :document-id="documentId"
                    :document-title="store.fidoBasicInfo.companyName"
                    :read-only="false"
                />
                <div class="tooltip tooltip-icon">
                  <Button
                      id="article_content_mail"
                      type="button"
                      variant="icon"
                      @click="sendEmail($event)"
                      aria-label="email"
                      icon="mail-outline"
                  />
                  <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.mail")}}</div>
                </div>
                <!--      TODO: Commented in FUNKTIONEN-10947, uncomment when this button will be ready to use -->
                <!--                <Button-->
                <!--                    type="button"-->
                <!--                    id="article_content_volume"-->
                <!--                    variant="icon"-->
                <!--                    aria-label="volume"-->
                <!--                    icon="volume-medium-outline"-->
                <!--                    class="not_implemented"-->
                <!--                    @click="notImplemented"-->
                <!--                />-->
              </div>

              <SendEmailComponent
                  v-if="expandedSendEmail"
                  :expanded-send-email=expandedSendEmail
                  :ids="[documentId]"
                  @closeWidget="expandedSendEmail = false"
              />
            </div>
          </div>
          <div class="mini_list_navigation_line">

            <div class="mini_list_navigation_line__left" @click="prevNavigationClicked"
                 :class="{disabled: !hasPrev}">
              <button type="button" aria-label="back" class="mini_list_navigation_line__left__arrow">
                <ion-icon src="/icons/custom/arrow-back-outline.svg"></ion-icon>
              </button>
              <div class="mini_list_navigation_line__left__title">{{ prevMiniListText }}</div>
            </div>

            <div class="mini_list_navigation_line__title">{{ currentMiniListText }}</div>

            <div class="mini_list_navigation_line__right" @click="nextNavigationClicked"
                 :class="{disabled: !hasNext}">
              <div class="mini_list_navigation_line__right__title">{{ nextMiniListText }}</div>
              <button type="button" aria-label="forward" class="mini_list_navigation_line__right__arrow">
                <ion-icon src="/icons/custom/arrow-forward-outline.svg"></ion-icon>
              </button>
            </div>

          </div>
          <chart-document-component v-if="showCharts" :chart-html="chartHtml"/>
          <div v-else>
            <div class="no_info_container" v-if="hasNoItemsInList">
              <div class="no_info_container__title">
                {{ $t("genios.FIDO.noDocumentsFound.title", {category: currentMiniListText}) }}
              </div>
              <div class="no_info_container__image_container">
                <!--suppress HtmlUnknownTarget -->
                <img src="/figures/FIDO_empty_pages.png" class="" alt="fido">
              </div>
              <div class="no_info_container__point1">{{ $t("genios.FIDO.noDocumentsFound.point1") }}</div>
              <div class="no_info_container__point2">{{ $t("genios.FIDO.noDocumentsFound.point2") }} <a
                  :href="searchLink">{{ $t("genios.FIDO.noDocumentsFound.here") }}</a></div>
            </div>
            <div v-else>
              <spinner class="bundle_spinner" v-if="isBundleLoader"/>
              <template v-else v-for="(item, index) in currentMiniListBundleDocuments">
                <bundle-document-component
                    v-if="bought && item.itemId"
                    :key="item.itemId"
                    :item-id="item.itemId"
                    :item-paid="item.paid"
                    :delay="index"
                    :shared-state="sharedState"/>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="document-metadata">
        <div class="doc_info_container">
          <div class="document_type_header"> {{ store.fidoBasicInfo.docType }}</div>
          <div class="contact_info_container">
            <div class="contact_info_container__cover_container">
              <!--suppress HtmlUnknownTarget -->
              <img alt="" loading="eager" class="preview-image" src="/img?db=FIDO">
            </div>
            <div class="contact_info_container__contacts">
              <div class="contact_info_container__contacts__title"> {{ store.fidoBasicInfo.companyName }}</div>
              <div class="contact_info_container__contacts__details" v-html="store.contactInformation"></div>
            </div>
          </div>

          <div class="tooltip tooltip-icon" v-if="hasPermission('monitoring')">
            <Button type="button"
                    id="company_notification_button"
                    class="button__secondary"
                    style="font-weight: 600;font-size: 18px; padding: 5px 14px !important;"
                    :data-company-name="store.fidoBasicInfo.companyName"
                    :data-hnumber="this.store.fidoBasicInfo.hnumber">
              <ion-icon name="alarm-outline"></ion-icon>
            <span>{{$t('genios.companyNotification')}}</span>
            </Button>
            <div class="tooltiptext tooltiptext-button tooltip-top tooltip-top-icon">
              {{$t('genios.tooltip.buttons.companyNotification')}}
            </div>
          </div>



          <!--      TODO: Commented in FUNKTIONEN-10947, uncomment when this will be ready to use -->
          <!--          <div class="buttons_line">-->
          <!--            <Button-->
          <!--                type="button"-->
          <!--                variant="secondary"-->
          <!--                :text="$t('genios.FIDO.monitoring')"-->
          <!--                icon="alarm-outline"-->
          <!--                position="left"-->
          <!--                class="not_implemented"-->
          <!--                @click="notImplemented"-->
          <!--            />-->
          <!--            <Button-->
          <!--                type="button"-->
          <!--                variant="secondary"-->
          <!--                :text="$t('genios.FIDO.requestHr')"-->
          <!--                class="not_implemented"-->
          <!--                @click="notImplemented"-->
          <!--                :toggle-text="false"-->
          <!--            />-->
          <!--          </div>-->
        </div>

        <fido-navigation-boxes-component
            :update="updateAllMiniListPrices"
            :shared-state="sharedState"
            :restoredBundleDocumentId="store.restoredBundleDocumentId"
            :userInteracted="store.userInteracted"
            :expandedListName="store.expandedListName"
            :fidoDocumentId="documentId"
            :has-charts="store.hasCharts"
            :is-preview="!bought"
            :store="store"
        />
        <div class="border-line"></div>

        <div class="additional_information">
          <p class="additional_information__header">{{ $t('genios.FIDO.additionalInformation.name') }}</p>
          <div class="additional_information__item" v-if="store.fidoBasicInfo.sourceName">
            <div class="additional_information__item__label">{{ $t('genios.FIDO.additionalInformation.source') }}</div>
            {{ store.fidoBasicInfo.sourceName }}
          </div>
          <div class="additional_information__item" v-if="store.fidoBasicInfo.documentId">
            <div class="additional_information__item__label">{{
                $t('genios.FIDO.additionalInformation.documentNumber')
              }}
            </div>
            {{ store.fidoBasicInfo.documentId }}
          </div>
          <div class="additional_information__item" v-if="store.fidoBasicInfo.lastUpdated">
            <div class="additional_information__item__label">{{
                $t('genios.FIDO.additionalInformation.lastUpdated')
              }}
            </div>
            {{ store.fidoBasicInfo.lastUpdated }}
          </div>
          <div class="permanent_link_item">
            <div class="additional_information__item__label">{{
                $t('genios.FIDO.additionalInformation.documentLink')
              }}
            </div>
            <a :href="'/document/' + documentId">{{ directLink }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import eventBus from "../../../eventBus";

import Spinner from "../styled/Spinner.vue";
import Button from "../styled/Button.vue";

import {
  addPaidItemsByCategory,
  changeUserInteractedAction,
  fidoComponent,
  updateExpandedListNameAction,
  updateHasItemInListAction,
  updateSelectedItemAction
} from "../../../functions/components/fidoComponent";

import {PaymentMode} from "../../../functions/components/payment";

import updatePrices from "../../../functions/updatePrices";

import {fetchAndInsertDocumentInPreview, fetchFullText} from "../../../functions/fetching";
import {shareHandler} from "../../../functions/components/shareButton";
import {notImplementedVueAlert} from "../../../functions/temp";
import {
  setupLoginComponent,
  setupPaymentFormComponent,
  setupSendEmailComponent
} from "../../../functions/setups/vue-components";

import {checkLogin} from "../../../functions/checks";
import {openTransparentPopupModal} from "../../../functions/opens";
import {closeTransparentPopupModal} from "../../../functions/closing";

import {getDbFromUid} from "../../../functions/utils/string_utils";

import FidoNavigationBoxesComponent from "./FidoNavigationBoxesComponent.vue";
import BundleDocumentComponent from "./BundleDocumentComponent.vue";
import ChartDocumentComponent from "./ChartDocumentComponent.vue";
import ActionBarComponent from "../ActionBar/ActionBarComponent.vue";
import SendEmailComponent from "../ActionBar/SendEmailComponent.vue";
import WatchlistBookmarkToggleComponent from "../Watchlist/WatchlistBookmarkToggleComponent.vue";
import {getDocumentPricesAsync} from "../../../functions/services";
import {hasPermission} from "../../../functions/utils/permission_utils";

export default {
  name: "FidoComponent",
  components: {
    ActionBarComponent,
    ChartDocumentComponent,
    BundleDocumentComponent,
    FidoNavigationBoxesComponent,
    Spinner,
    SendEmailComponent,
    WatchlistBookmarkToggleComponent,
    Button
  },
  props: {
    sharedState: Object,
    documentId: String,
    legacyDocId: String,
    priceCategory: String,
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      previewHtml: "",
      updating: true,
      chartHtml: "",
      bought: false,
      updateAllMiniListPrices: false,
      expandedSendEmail: false,
      store: {
        userInteracted: false,
        restoredBundleDocumentId: "",
        expandedListName: "FIRMENPROFIL",
        prevListName: "",
        nextListName: "HANDELSREGISTER",
        selectedItem: {},
        fidoBasicInfo: "",
        contactInformation: "",
        paidDocumentsMap: {
          "FIRMENPROFIL": [],
          "HANDELSREGISTER": [],
          "JAHRESABSCHLUSS": [],
          "BONITAETEN": [],
          "VERFLECHTUNGEN": [],
          "BRANCHENINFORMATIONEN": [],
          "PRESSE": []
        },
        hasItemsInList: {
          "FIRMENPROFIL": undefined,
          "HANDELSREGISTER": undefined,
          "JAHRESABSCHLUSS": undefined,
          "CHARTS": undefined,
          "BONITAETEN": undefined,
          "VERFLECHTUNGEN": undefined,
          "BRANCHENINFORMATIONEN": undefined,
          "PRESSE": undefined
        },
        hasCharts: false
      }
    };
  },

  created() {
    this.initializeState();
    let fromUrl = this.retrieveEffectiveExpandedListName();
    updateExpandedListNameAction(this.store, fromUrl);
    this.sharedState.fidoCategory = this.store.expandedListName;
    this.removeBuyButton();
  },
  mounted() {
    eventBus.$on("documentBought", (documentId) => {
      if (documentId === this.documentId) {
        this.retrieveDocHtmlResponses();
        this.bought = true;
      } else {
        this.updateAllMiniListPrices = !this.updateAllMiniListPrices;
      }
    });

    eventBus.$on("destroyFIDO", () => {
      this.$destroy()
    })

    getDocumentPricesAsync([this.priceItem], async (data) => {
      this.bought = !!data[this.legacyDocId]?.paid;
      if (!this.bought) {
        const fidoBoughtInfo = await axios.post("/api/retrieveDocuments", {ids: [this.documentId]});
        this.bought = !fidoBoughtInfo.data?.code;
        if (!this.bought) {
          await setupPaymentFormComponent(fidoBoughtInfo.data, this.documentId, PaymentMode.fullDocument);
        }
      } else {
        this.retrieveDocHtmlResponses();
      }
    })
  },

  methods: {
    initializeState() {
      window.sharedState.lastPaidBundleDocument = "";
      this.store.restoredBundleDocumentId = this.restoreBundleDocumentIdFromUrl()
    },
    unshiftPaidItemByCategory({category, item}) {
      if (!!item && !!category) {
        let existingPaidItems = this.store.paidDocumentsMap[category];
        const ids = existingPaidItems.map(el => el.itemId);
        if (!ids.includes(item.itemId)) {
          existingPaidItems.unshift(item);
        }
      }
    },
    updateHasChartsAction(value) {
      this.store.hasCharts = value;
    },
    notImplemented() {
      notImplementedVueAlert(this.$root);
    },
    restoreBundleDocumentIdFromUrl() {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams.has("bundleDocumentId") ? urlParams.get("bundleDocumentId") : ""
    },
    async sendEmail({currentTarget: emailBtn}) {
      const {loggedIn} = await checkLogin();
      if (loggedIn) {
        this.expandedSendEmail = !this.expandedSendEmail;
      } else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          setupSendEmailComponent(this.documentId, emailBtn.parentNode.parentNode, true);
        });
      }
    },
    share(event) {
      shareHandler(event);
    },
    resolveMiniListTitle(key) {
      return key ? fidoComponent[key] : "";
    },
    nextNavigationClicked() {
      if (this.hasNext) {
        updateExpandedListNameAction(this.store, this.store.nextListName);
        changeUserInteractedAction(this.store, true);
      }
    },
    prevNavigationClicked() {
      if (this.hasPrev) {
        updateExpandedListNameAction(this.store, this.store.prevListName);
        changeUserInteractedAction(this.store, true);
      }
    },
    retrieveDocHtmlResponses() {
      fetchAndInsertDocumentInPreview(this.documentId, null, (docBody) => {
        this.chartHtml = this.extractChartsFromFidoDoc(docBody);
        this.getPaidBundleDocuments();
        this.updateAllMiniListPrices = !this.updateAllMiniListPrices;
        this.updatePreviewPricesAndContent(this.store.selectedItem.itemId);
        document.querySelectorAll('.not_paid').forEach(button => {
          button.classList.remove("not_paid");
        });
      });
    },
    removeBuyButton() {
      const fidoBuyButton = document.querySelector('.media_preview__main__id[id=' + this.documentId + '__content] .fido__button');
      if (fidoBuyButton) {
        fidoBuyButton.classList.add('hide');

      }
    },
    extractChartsFromFidoDoc(fidoDocHtml) {
      let fullDoc = document.createElement('div');
      fullDoc.innerHTML = fidoDocHtml;
      let chartHtml = "";
      fullDoc.querySelectorAll('.companyResults').forEach(el =>
          chartHtml += el.innerHTML
      );
      if (chartHtml.length > 0) {
        this.updateHasChartsAction(true);
        updateHasItemInListAction(this.store, {category: "CHARTS", value: true});
      } else {
        updateHasItemInListAction(this.store, {category: "CHARTS", value: false});
      }
      return chartHtml;
    },
    async getPaidBundleDocuments() {
      try {
        let path = "/api/retrievePaidBundleDocuments";
        const {data} = await axios.get(path, {
          params: {"fidoUid": this.documentId}
        });
        for (const [key, value] of Object.entries(data)) {
          let paidItems = value.map(el => {
            return {itemId: el, paid: true};
          });
          addPaidItemsByCategory(this.store, {category: key, paidItems});
        }
      } catch (e) {
        console.log("getPaidBundleDocuments:error => ", e);
        console.dir(e);
      }
    },
    retrieveEffectiveExpandedListName() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("expandedListName")) {
        return urlParams.get("expandedListName");
      } else {
        return this.store.expandedListName;
      }
    },
    updatePreviewPricesAndContent(docId) {
      if (!!docId) {
        let elementById = document.getElementById(docId);
        updatePrices(elementById, docId, false, function () {
          fetchFullText(docId);
        });
      }
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }
  },
  computed: {
    hasNext() {
      return this.store.nextListName !== "";
    },
    hasPrev() {
      return this.store.prevListName !== "";
    },
    hasNoItemsInList() {
      return this.store.hasItemsInList[this.store.expandedListName] === false
    },
    fidoLegacyId() {
      return "FIDO__" + this.store.fidoBasicInfo.documentId;
    },
    currentMiniListText() {
      return this.resolveMiniListTitle(this.store.expandedListName);
    },
    prevMiniListText() {
      return this.resolveMiniListTitle(this.store.prevListName);
    },
    nextMiniListText() {
      return this.resolveMiniListTitle(this.store.nextListName);
    },
    directLink() {
      return location.protocol + "//" + location.host + "/document/" + this.documentId;
    },
    isBundleLoader() {
      return this.currentMiniListBundleDocuments.length < 1 || this.currentMiniListBundleDocuments[0].item === ''
    },
    currentMiniListBundleDocuments() {
      let result = [];
      if (Object.keys(this.store.paidDocumentsMap).length) {

        //selected item always first
        if (!!this.store.selectedItem?.itemId) {
          result.push({itemId: this.store.selectedItem.itemId, paid: this.store.selectedItem.paid});
        }

        let paidItems = this.store.paidDocumentsMap[this.store.expandedListName];
        if (!!paidItems) {
          //remove selected item from the stored collection
          let filter = paidItems.filter((el) => el.itemId === this.store.selectedItem.itemId);
          if (filter.length) result.pop();
          //add all
          result.push(...paidItems);
        }
      }

      return result;
    },
    getSelectedDocumentDb() {
      return getDbFromUid(this.store.selectedItem.itemId);
    },
    showCharts() {
      return this.store.hasCharts && this.store.expandedListName === "CHARTS";
    },
    searchLink() {
      let url = "/searchResult/" + this.sharedState.urlTopLevelNavigation + "/";
      if (this.store.expandedListName === 'BRANCHENINFORMATIONEN') {
        url = url + "Marktdaten";
      } else if (this.store.expandedListName === "PRESSE") {
        url = url + "Presse";
      } else if (this.store.expandedListName === "VERFLECHTUNGEN") {
        url = url + "Firmen?category=Beteiligungsinformationen";
      } else if (this.store.expandedListName === "BONITAETEN") {
        url = url + "Firmen?category=Bonitätsauskünfte";
      } else if (["CHARTS", "JAHRESABSCHLUSS"].includes(this.store.expandedListName)) {
        url = url + "Firmen?category=Jahresabschlüsse+%26+Bilanzen+";
      } else {
        url = url + "Firmen";
      }

      if (url.includes("?")) {
        return url + "&requestText=" + this.store.fidoBasicInfo.companyName;
      } else {
        return url + "?requestText=" + this.store.fidoBasicInfo.companyName;
      }
    },
    priceItem() {
      return {
        documentId: this.legacyDocId,
        database: "FIDO",
        priceCategory: this.priceCategory
      }
    }
  },

  watch: {
    "sharedState.lastPaidBundleDocument": function (bundleDocumentId) {
      if (bundleDocumentId) {
        if (this.store.selectedItem.itemId === bundleDocumentId) {
          updateSelectedItemAction(this.store, {itemId: bundleDocumentId, paid: true});
        }
        let miniListIds = this.store.paidDocumentsMap[this.store.expandedListName].map(el => el.itemId);
        if (!miniListIds.includes(bundleDocumentId)) {
          this.unshiftPaidItemByCategory({
            category: this.store.expandedListName,
            item: {itemId: bundleDocumentId, paid: true}
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.bundle_spinner {
  display: block;
  margin: 100px auto;
}
</style>
