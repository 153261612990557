var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"press_notification_form flexible_container",attrs:{"id":"press_notification_form"}},[_c('div',{staticClass:"press_notification_form__header"},[_vm._v("\n    "+_vm._s(_vm.$t('genios.monitoring.company.headerText'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"press_notification_form__description"},[_vm._v("\n    "+_vm._s(_vm.$t('genios.monitoring.company.description.first', {companyName: _vm.companyName}))+"\n  ")]),_vm._v(" "),_c('form',{staticClass:"press_notification_form__form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',{staticClass:"monitoring_form__form__settings__schedule"},[_vm._l((_vm.emails),function(email,index){return _c('div',[_c('div',{staticClass:"press_notification_form__form__emails"},[_c('text-form-element',{attrs:{"passed-value":email.value,"element":email,"focus":_vm.focused},on:{"focus":function($event){_vm.focused=$event},"modified":function($event){email.value = ($event === null ? '' : $event)}}}),_vm._v(" "),_c('Button',{style:(_vm.emails.length <= 1 && 'visibility: hidden; pointer-event: none'),attrs:{"type":"button","variant":"icon","icon":"trash-outline"},on:{"click":function($event){return _vm.emails.splice(index, 1)}}}),_vm._v(" "),_c('Button',{attrs:{"type":"button","variant":"icon","icon":"add-outline"},on:{"click":function($event){_vm.emails.push({
                    id: 'press_notification_form__email_' + index+1,
                    field: 'email_' + index+1,
                    fieldType: 'TEXT',
                    fieldLabel: _vm.$t('genios.monitoring.label.email'),
                    value: '',
                    error: ''
          })}}})],1)])}),_vm._v(" "),_c('Error',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"press_notification_form__error",attrs:{"error":_vm.error}}),_vm._v(" "),_c('email-group-action',{attrs:{"checked-emails-group":_vm.checkedEmailsGroup,"shared-state":_vm.sharedState}})],2),_vm._v(" "),_c('div',{staticClass:"press_notification_form__submit_container"},[_c('Button',{attrs:{"name":"close-modal","text":_vm.$t('genios.monitoring.cancelText'),"type":"button","variant":"secondary","toggle-text":false},on:{"click":_vm.closeModal}}),_vm._v(" "),_c('Button',{ref:"submitButton",attrs:{"name":"submit1","text":_vm.$t('genios.monitoring.submitText'),"variant":"primary","id":"submit1","toggle-text":false}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }