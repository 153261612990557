<template>
  <div class="mini_list__container">
    <div class="border-line"></div>
    <div class="mini_list__container__header" :class="{empty: !hasToggle, 'unset-pointers': isPreview}"
         v-on:click="toggleShow()">
      <div class="mini_list__container__header__title">
        <span class="mini_list__container__header__title__text" :class="{expanded: expanded}">
          {{ title }}
        </span>
        <span v-if="!this.isChart" class="mini_list__container__header__title__count">
          {{ formattedCount }}
        </span>
      </div>

      <button
          v-if="hasToggle && !isPreview"
          type="button"
          class="mini_list__container__header__button"
          :aria-label="title"
          :aria-expanded=" (expanded) ? 'true' : 'false'"
      >
        <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="buttonClass"/>
      </button>
    </div>

    <transition name="slide" v-if="!isPreview">
      <div class="mini_list__container__links" v-show="showLinks">
        <link-item-component
            v-if="hasToggle"
            v-for="item in this.items"
            :key="item.id"
            :id="item.id"
            :item="item"
            :category="category"
            :store="store"
            v-on="$listeners"
        />
        <button type="button" class="mini_list__container__links__show_more hyperlink"
                v-if="items.length < count"
                @click="showMore">
          <span>{{ $t('genios.miniList.showMore') }}</span>
          <ion-icon src="/icons/custom/chevron-down-outline.svg"></ion-icon>
        </button>
      </div>
    </transition>

  </div>
</template>

<script>

import LinkItemComponent from "./LinkItemComponent.vue";
import axios from "axios";
import {
  addPaidItemsByCategory,
  changeUserInteractedAction,
  updateExpandedListNameAction,
  updateHasItemInListAction,
  updateSelectedItemAction
} from "../../../functions/components/fidoComponent";
import {getDocumentPricesAsync} from "../../../functions/services";

export default {
  name: "MiniListComponent",
  components: {
    LinkItemComponent
  },
  props: {
    sharedState: Object,
    title: String,
    subQueries: Array,
    category: String,
    expandedListName: String,
    userInteracted: Boolean,
    restoredBundleDocumentId: String,
    hasCharts: Boolean,
    isPreview: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object,
      required: true
    },
    index: Number,
    update: Boolean
  },
  data() {
    return {
      items: [],
      count: 0,
      size: 10,
      offset: 0,
      pricesLoaded: false
    };
  },
  mounted() {
    this.updateAllPrices()
  },
  methods: {
    toggleShow: function () {
      if (this.hasToggle) {
        updateExpandedListNameAction(this.store, this.$vnode.key);
        changeUserInteractedAction(this.store, true);
      }
    },
    showMore: function () {
      this.offset = this.offset + this.size;
      let path = "/api/bundleQueriesSearch?size=" + this.size + "&offset=" + this.offset;
      axios.post(path, this.subQueries).then(response => {
        this.items = [...this.items, ...response.data.items];
      }).catch(error => {
        console.log(error);
      });
    },
    async retrieveItems(queries) {
      if (queries) {
        try {
          let path = "/api/bundleQueriesSearch";
          const {data} = await axios.post(path, queries)
          this.count = data.totalHitCount;
          this.items = data.items;
          this.updateFidoContactInformation();
          updateHasItemInListAction(this.store, {category: this.category, value: this.count > 0});
        } catch (error) {
          console.log(error);
          updateHasItemInListAction(this.store, {category: this.category, value: false});
        }
      } else {
        updateHasItemInListAction(this.store, {category: this.category, value: false});
      }
    },
    updatePrices: function (items, firstLoad) {
      let category = this.category;
      if (items.length <= 0) {
        return;
      }

      updateSearchResultSharedState(items.map(item => {
        return {
          documentId: item.id,
          legacyDocId: item.legacyDocId,
          database: item.database,
          priceCategory: item.priceCategory
        }
      }));

      let ids = items.map(item => {
        return {
          documentId: item?.legacyDocId || item.documentId,
          database: item.database,
          priceCategory: item.priceCategory
        }
      });
      getDocumentPricesAsync(ids, (data) => {
        let paidItems = [];
        items.forEach(item => {
          if (data[item.legacyDocId] !== undefined) {
            item.price = data[item.legacyDocId].displayText;
            item.paid = data[item.legacyDocId].paid;
            item.priceSet = true;

            if (item.paid) {
              paidItems.push({itemId: item.id, paid: true});
            }

          }
        });
        if (firstLoad) {
          this.selectFirstItemOrRestoredFromUrl();
          addPaidItemsByCategory(this.store, {category, paidItems});
        }
        this.pricesLoaded = true;
      })
    },
    updateAllPrices() {
      this.updatePrices(this.items, !this.userInteracted);
    },
    selectFirstItemOrRestoredFromUrl() {
      if (this.hasToggle && this.expanded) {
        let hasRestoredDocumentId = !this.userInteracted && !!this.restoredBundleDocumentId;
        let itemId;
        let paid = false;
        if (hasRestoredDocumentId) {
          itemId = this.restoredBundleDocumentId;
        } else {
          itemId = this.items[0].id;
          paid = this.items[0].paid;
        }
        updateSelectedItemAction(this.store, {itemId, paid});
      }
    },
    updateFidoContactInformation() {
      if (this.$vnode.key === "FIRMENPROFIL" && this.hasToggle && !this.userInteracted) {
        let firstBundleDocument = this.items[0].id;
        let path = "/api/getFidoContactInformation/" + firstBundleDocument;
        axios.get(path).then((response) => {
          let data = response.data;
          this.updateContactInfoAction(this.store, data);
        }).catch(error => {
          console.log(error);
        });
      }
    },
    updateContactInfoAction(store, value) {
      store.contactInformation = value;
    },
  },
  computed: {
    hasToggle() {
      return this.count > 0 || (this.isChart && this.hasCharts);
    },
    formattedCount() {
      return this.count === undefined ? '...' : this.count.toLocaleString('de-DE');
    },
    expanded() {
      return this.expandedListName === this.category;
    },
    isChart() {
      return this.category === "CHARTS";
    },
    buttonClass() {
      if (!this.isChart) {
        if (this.expanded) {
          return 'rotate-180';
        } else {
          return '';
        }
      } else {
        return "";
      }
    },
    showLinks() {
      return this.expanded && !this.isChart;
    }
  },
  watch: {
    update: function () {
      this.updateAllPrices();
    },
    async subQueries(newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        await this.retrieveItems(newValue);
        this.$emit("miniListLoaded", this.index);
      }
    },
    items: function (newValue, oldValue) {
      if (newValue.length > 0 && !_.isEqual(newValue, oldValue)) {
        let toUpdate = newValue.filter(el => el.priceSet === false);
        let firstLoad = oldValue.length === 0; //old items size is 0 and new items size is not zero => first load
        this.updatePrices(toUpdate, firstLoad);
      }
    },
    expanded: function (newValue) {
      if (newValue && this.pricesLoaded) {
        this.selectFirstItemOrRestoredFromUrl();
      }
    },
    "sharedState.lastPaidBundleDocument": function (newValue) {
      let item = this.items.find(item => item.id === newValue);
      if (item) {
        item.paid = true;
      }
    },
    isPreview: function (previewMode) {
      if (!previewMode) {
        this.updateAllPrices();
      }
    }
  }
};
</script>


<style scoped>
.unset-pointers {
  pointer-events: none !important;
}
</style>
