<template>
  <div class="payment__bucket">
    <div class="toggle-bucket">
      <div @click="toggleBucket" class="toggle-bucket__header">
        <ion-icon name="cart-outline"/>
        <p class="toggle-bucket__header__title">
          <span>{{ $t('orderingProcess.mainForm.bucket.toggleBasket') }}</span>
          <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="isOpen?'rotate-180':''"/>
        </p>
        <Transition name="fade">
          <strong class="toggle-bucket__header__total" v-if="!isOpen">
            {{
              formatPrice(orderDataModel.totalGross)
            }}
          </strong>
        </Transition>
      </div>
      <div v-show-slide="isOpen" ref="expandedEl" class="toggle-bucket__body">
        <div class="payment__bucket__body">
          <h2 class="payment__bucket__title">
            {{currentStep === "choice"? $t('orderingProcess.mainForm.bucket.step1'):''}}
            {{ $t('orderingProcess.mainForm.bucket.title') }}</h2>
          <ul class="payment__list">
            <li v-for="document in orderDataModel.items" class="payment__list__item">
              <div class="payment__list__item__icon">
                <img ref="tinyIcon" :src="tinyIconUrl(document.database)" alt="" width="20" height="20"
                     style="visibility: hidden">
              </div>
              <div class="payment__list__item__info">
                <p class="payment__list__item__source"><span>{{ document.sourceName }}</span> <span
                    class="payment__list__item__source__pipe">|</span> <span>{{ document.docType }}</span></p>
                <div class="payment__list__item__footer">
                  <p class="payment__list__item__title"><strong>{{ document.title }}</strong></p>
                  <div class="payment__list__item__price">{{ formatPrice(document.priceNet) }}</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="payment__list__item__sum">
            <p>{{ $t('orderingProcess.mainForm.bucket.tax') }}</p>
            <p>{{
                formatPrice(orderDataModel.totalTax)
              }}</p>
            <p>{{ $t('orderingProcess.mainForm.bucket.total') }}</p>
            <p>{{
                formatPrice(orderDataModel.totalGross)
              }}</p>
          </div>
          <div class="payment__list__item__coupon">
            <button
                class="payment__list__item__button"
                @click="toggleForm"
            >
              + <span>{{ $t('orderingProcess.mainForm.bucket.coupon') }}</span>
            </button>
          </div>
          <div class="payment__list__item__form" v-if="expanded">
            <TextFormElement
                :passed-value="couponCode.value"
                :element="couponCode"
                :focus="focus"
                @focus="focus = $event"
                @modified="couponCode.value = $event"
            />
            <button class="payment__list__item__button">
              <span>{{ $t('orderingProcess.mainForm.bucket.submit') }}</span>
            </button>
          </div>
        </div>
        <div class="payment__bucket__footer" v-if="currentStep !=='info'">
          <p v-html="$t('orderingProcess.mainForm.bucket.premium')"/>
          <button class="payment__list__item__button">
            <span>{{ $t('orderingProcess.mainForm.bucket.upgradeBtn') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="payment__bucket__body">
      <h2 class="payment__bucket__title">
        {{currentStep === "choice"? $t('orderingProcess.mainForm.bucket.step1'):''}}
        {{ $t('orderingProcess.mainForm.bucket.title') }}</h2>
      <ul class="payment__list">
        <li v-for="document in orderDataModel.items" class="payment__list__item">
          <div class="payment__list__item__icon">
            <img ref="tinyIcon" :src="tinyIconUrl(document.database)" alt="" width="20" height="20"
                 style="visibility: hidden">
          </div>
          <div class="payment__list__item__info">
            <p class="payment__list__item__source"><span v-html="document.sourceName"/><span
                class="payment__list__item__source__pipe">|</span> <span>{{ document.docType }}</span></p>
            <div class="payment__list__item__footer">
              <p class="payment__list__item__title"><strong>{{ document.title }}</strong></p>
              <div class="payment__list__item__price">{{ formatPrice(document.priceNet) }}</div>
            </div>
          </div>
        </li>
      </ul>
      <div class="payment__list__item__sum">
        <p>{{ $t('orderingProcess.mainForm.bucket.tax') }}</p>
        <p>{{
            formatPrice(orderDataModel.totalTax)
          }}</p>
        <p>{{ $t('orderingProcess.mainForm.bucket.total') }}</p>
        <p>{{
            formatPrice(orderDataModel.totalGross)
          }}</p>
      </div>
      <div class="payment__list__item__coupon">
        <button
            class="payment__list__item__button"
            @click="toggleForm"
        >
          + <span>{{ $t('orderingProcess.mainForm.bucket.coupon') }}</span>
        </button>
      </div>
      <div class="payment__list__item__form" v-if="expanded">
        <TextFormElement
            :passed-value="couponCode.value"
            :element="couponCode"
            :focus="focus"
            @focus="focus = $event"
            @modified="couponCode.value = $event"
        />
        <button class="payment__list__item__button">
          <span>{{ $t('orderingProcess.mainForm.bucket.submit') }}</span>
        </button>
      </div>
    </div>
    <div class="payment__bucket__footer" v-if="currentStep !=='info'">
      <p v-html="$t('orderingProcess.mainForm.bucket.premium')"/>
      <button type="button" class="payment__list__item__button" @click="showRegisterForm">
        <span>{{ $t('orderingProcess.mainForm.bucket.upgradeBtn') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import TextFormElement from "../../FormElements/TextFormElement.vue";
import i18 from "../../plugins/Translations.vue"
import {openRegistrationPage} from "../../../../functions/opens";

export default {
  name: "PaymentBucket",
  components: {TextFormElement, Button, i18},
  props: {
    orderDataModel: Object,
    currentStep: String
  },
  data() {
    return {
      isOpen: false,
      focus: null,
      expanded: false,
      couponCode: {
        id: "coupon",
        fieldLabel: i18.t('orderingProcess.mainForm.bucket.code'),
        value: "",
        field: "code",
        fieldType: 'text'
      },
      scrollBarOptions: {
        scrollbars: {
          autoHide: 'never',
          clickScrolling: true,
          autoHideDelay: 200
        },
        overflowBehavior: {x: 'hidden'}
      },
    }
  },
  mounted() {
    const images = this.$el.querySelectorAll(".payment__list__item__icon img")
    images.forEach(item => {
      item.addEventListener("load", () => {
        this.changeImageAlt(item)
      })
    })
  },
  methods: {
    showRegisterForm() {
      openRegistrationPage()
    },
    toggleBucket() {
      this.isOpen = !this.isOpen

      // let height
      // const {expandedEl} = this.$refs
      //
      // if (this.isOpen) {
      //   height = 0
      //   const increase = setInterval(() => {
      //     if (height >= expandedEl.scrollHeight) {
      //       clearInterval(increase)
      //     } else {
      //       height += 10
      //       this.$refs.expandedEl.style.height = `${height}px`
      //     }
      //   }, 10)
      // } else {
      //   height = expandedEl.scrollHeight
      //
      //   const decrease = setInterval(() => {
      //     if (height <= 0) {
      //       clearInterval(decrease)
      //     } else {
      //       height -= 10
      //       this.$refs.expandedEl.style.height = `${height}px`
      //     }
      //   }, 10)
      // }
    },
    toggleForm() {
      this.expanded = !this.expanded
    },
    changeImageAlt: function (item) {
      if (item.naturalWidth) {
        item.alt = 'icon'
        item.style.visibility = "visible"
      }
      return item
    },
    tinyIconUrl: function (dbShortcut) {
      return "/resource/logos-tiny/" + dbShortcut.toLowerCase() + ".png"
    },
    formatPrice: function (price) {
      return Number(price).toLocaleString('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
    }
  }
}
</script>