import {buildHistoryState} from "../builds";

export const buildPath = function (path) {
    let urlTopLevelNavigation = window.sharedState.urlTopLevelNavigation || window.sharedState.navigationTree?.at(0)?.urlSafeName;
    let urlSecondLevel = window.sharedState.urlSecondLevel;
    let urlThirdLevel = window.sharedState.urlThirdLevel;
    if (urlTopLevelNavigation !== undefined && urlTopLevelNavigation !== null) path = path + '/' + urlTopLevelNavigation;
    if (urlSecondLevel !== undefined && urlSecondLevel !== null && urlSecondLevel !== 'undefined') path = path + '/' + urlSecondLevel;
    if (urlThirdLevel && urlThirdLevel !== 'undefined') path = path + '/' + encodeURIComponent(urlThirdLevel);
    return path;
}

export const buildSearchParametersFromCurrentState = function () {
    let params = {
        requestText: window.sharedState.queryString
    };
    let advancedParams = {};
    if (window.sharedState.advancedSearchParams) {
        advancedParams = {
            advanced: window.sharedState.advancedSearchParams
        };
    }
    let searchSettingsParams = {};
    if (!!window.sharedState.offset) {
        searchSettingsParams['offset'] = window.sharedState.offset;
    }
    if (!!window.sharedState.pageSize) {
        searchSettingsParams['size'] = window.sharedState.pageSize;
    }
    if (!!window.sharedState.sort) {
        searchSettingsParams['sort'] = window.sharedState.sort;
    }
    if (!!window.sharedState.order) {
        searchSettingsParams['order'] = window.sharedState.order;
    }
    if (!!window.sharedState.resultListType) {
        searchSettingsParams['resultListType'] = window.sharedState.resultListType;
    }
    let activeFilterParams = {};
    Object.keys(window.sharedState.activeFilters).forEach(activeFilter => {
        if (activeFilter === "date") {
            activeFilterParams.date = [];
            const dates = window.sharedState.activeFilters.date;
            dates.forEach(date => {
                if (!!(date.from)) {
                    activeFilterParams.date.push("from_" + date.from);
                }
                if (!!(date.to)) {
                    activeFilterParams.date.push("to_" + date.to);
                }
            });
        } else {
            activeFilterParams[activeFilter] = window.sharedState.activeFilters[activeFilter];
        }
    });
    return {...params, ...advancedParams, ...activeFilterParams, ...searchSettingsParams};
}

export const buildSourceParametersFromCurrentState = () => {
    let searchSettingsParams = {};
    if (!!window.sharedState.offset) {
        searchSettingsParams['offset'] = window.sharedState.offset;
    }
    if (!!window.sharedState.pageSize) {
        searchSettingsParams['size'] = window.sharedState.pageSize;
    }

    return {...searchSettingsParams};
}
export const removeParams = key => {
    const {search} = window.location
    const queryParams = new URLSearchParams(search)
    const data = queryParams.getAll(key)
    data.forEach(() => queryParams.delete(key))
    window.history.pushState(buildHistoryState(), null, "?" + queryParams.toString());
}
