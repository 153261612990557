<template>
  <div class="teaser__under_title">
    <div v-if="isLoading" id="spinner">
      <spinner/>
    </div>

    <mini-list-component
        v-for="miniListData in miniLists"
        :key="miniListData.key"
        :index="miniLists.indexOf(miniListData)"
        :category="miniListData.key"
        :title="getMiniListTitle(miniListData.key)"
        :shared-state="sharedState"
        :sub-queries="miniListData.subQueries"
        :expandedListName="expandedListName"
        :userInteracted="userInteracted"
        :isPreview="isPreview"
        :restoredBundleDocumentId="restoredBundleDocumentId"
        :has-charts="hasCharts"
        :store="store"
        v-on="$listeners"
        @miniListLoaded="setMiniListLoaded"
        :update="update"
    />
  </div>
</template>

<script>

import MiniListComponent from "./MiniListComponent.vue";
import axios from "axios";
import spinner from "../styled/Spinner.vue";
import {fidoComponent, updateHasItemInListAction} from "../../../functions/components/fidoComponent.js";

export default {
  name: "FidoNavigationBoxesComponent",
  components: {
    MiniListComponent,
    spinner
  },
  props: {
    sharedState: Object,
    update: Boolean,
    restoredBundleDocumentId: {
      type: String,
      default: ""
    },
    userInteracted: Boolean,
    expandedListName: String,
    fidoDocumentId: String,
    hasCharts: Boolean,
    isPreview: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      miniLists: [
        {key: "FIRMENPROFIL", subQueries: [], loaded: false},
        {key: "HANDELSREGISTER", subQueries: [], loaded: false},
        {key: "JAHRESABSCHLUSS", subQueries: [], loaded: false},
        {key: "CHARTS", subQueries: [], loaded: false},
        {key: "BONITAETEN", subQueries: [], loaded: false},
        {key: "VERFLECHTUNGEN", subQueries: [], loaded: false},
        {key: "BRANCHENINFORMATIONEN", subQueries: [], loaded: false},
        {key: "PRESSE", subQueries: [], loaded: false}
      ]
    };
  },
  computed: {
    isLoading() {
      return !this.miniLists.every(item => item.loaded)
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.retrieveSubQueries();
    });
  },
  methods: {
    changeFidoBasicInfoAction(store, value) {
      store.fidoBasicInfo = value;
    },
    retrieveSubQueries() {
      let path = "/api/retrieveFidoInformation/" + this.fidoDocumentId;
      axios.get(path, {}).then((response) => {
        let data = response.data;
        let bundleQueriesMap = data.bundleQueries;
        if (!this.isPreview) {
          this.changeFidoBasicInfoAction(this.store, data);
        }
        for (let i in this.miniLists) {
          let queries = bundleQueriesMap[this.miniLists[i].key];

          this.miniLists[i].subQueries = queries;
          if (!this.isPreview) {

            if (queries === undefined) {
              //if we dont have sub queries - we dont have items in mini list
              updateHasItemInListAction(this.store, {category: this.miniLists[i].key, value: false});
            }
          }
        }
      }).catch(error => {
        console.log(error);
      })
    },
    getMiniListTitle(key) {
      return fidoComponent[key];
    },
    setMiniListLoaded(index) {
      this.miniLists[index].loaded = true;
    }
  },

  watch: {
    isPreview: function (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.retrieveSubQueries();
      }
    }
  }
};
</script>


<style scoped>
#spinner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
}
</style>
