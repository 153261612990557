import {buildHistoryState} from "../builds";

export const changeHistory = function (valuesToSet, push) {
    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    for (const [key, value] of Object.entries(valuesToSet))
        if (!value)
            urlParams.delete(key);
        else
            urlParams.set(key, value);

    let paramString = urlParams.toString();
    if (push)
        window.history.pushState(buildHistoryState(), null, paramString.length ? ("?" + paramString) : window.location.pathname);
    else
        window.history.replaceState(buildHistoryState(), null, paramString.length ? ("?" + paramString) : window.location.pathname);

}

//fake replacing links in history with preventDefault property
export const fakeVisitedLink = function ({current_url, desired_url}) {
    history.replaceState({}, "", desired_url)
    history.replaceState({}, "", current_url)
}
