import {createHost} from "./unzerConfig";

let $host;

const ensureHost = () => {
    if (!$host) $host = createHost();
}

export const setUnzerPage = async body => {
    ensureHost()
    try {
        const response = await $host.post(`/paypage`, body)
        return response.data
    } catch (e) {
        console.log("setUnzerPage => ", e)
        console.dir(e)
    }
}

export const authorizeUnzerPayment = async body => {
    ensureHost()
    try {
        const response = await $host.post(`/authorize`, body)
        return response
    } catch (e) {
        console.log("authorizeUnzerPayment => ", e)
        console.dir(e)
    }
}

export const getAuthInfo = async orderId => {
    ensureHost()
    try {
        const {data} = await $host.get(`/authorize?orderId=${orderId}`)
        return data
    } catch (e) {
        console.log("getAuthInfo => ", e)
        console.dir(e)
    }
}

//block for PayPal
export const setPaypal = async body => {
    ensureHost()
    try {
        const response = await $host.post(`/paypal`, body)
        return response
    } catch (e) {
        console.log("setPaypal:error => ", e)
        console.dir(e)
    }
}
//===============================

//block for Sofort
export const setSofort = async () => {
    ensureHost()
    try {
        const response = await $host.post(`/sofort`)
        return response
    } catch (e) {
        console.log("setSofort => ", e)
        console.dir(e)
    }
}

export const setSofortCharge = async body => {
    ensureHost()
    try {
        const response = await $host.post(`/charge`, body)
        return response
    } catch (e) {
        console.log("setSofortCharge => ", e)
        console.dir(e)
    }
}

export const getChargeInfo = async paymentId => {
    ensureHost()
    try {
        const response = await $host.get(`/charge/${paymentId}`)
        return response.data
    } catch (e) {
        console.log("getChargeInfo => ", e)
        console.dir(e)
    }
}

//===============================

export const setUnzerBasket = async body => {
    ensureHost()
    try {
        const response = await $host.post(`/basket`, body)
        return response.data
    } catch (e) {
        console.log("setUnzerBasket => ", e)
        console.dir(e)
    }
}

export const getUnzerBasket = async id => {
    ensureHost()
    try {
        const response = await $host.get(`/basket/${id}`)
        return response.data
    } catch (e) {
        console.log("getUnzerBasket => ", e)
        console.dir(e)
    }
}

export const checkUnzerStatus = async paymentId => {
    ensureHost()
    try {
        const response = await $host.get(`/query/${paymentId}`)
        return response.data
    } catch (e) {
        console.log("checkUnzerStatus => ", e)
        console.dir(e)
    }
}

export const setUnzerCharge = async ({paymentId, body}) => {
    ensureHost()
    try {
        const {data} = await $host.post(`/charge/${paymentId}`, body)
        return data
    } catch (e) {
        console.log("setUnzerCharge => ", e)
        console.dir(e)
    }
}

export const getBasicPaymentConfiguration = async () => {
    ensureHost()
    try {
        const {data} = await $host.get(`/keypair`)
        return data
    } catch (e) {
        console.log("getPaymentConfiguration => ", e)
        console.dir(e)
    }
}
