import axios from "axios";
import {buildPath} from "../utils/url_utils";

export const getSuggestions = async (requestText, type) => {
    try {
        const path = buildPath('/api/searchFilter') + window.location.search

        // use auth cookie as cache busting trick for user switching etc.
        const params = {
            requestText,
            type,
            // auth: getAuthCookieCacheBuster(),
        };
        const {data} = await axios.get(path, {params})
        return data;
    } catch (e) {
        console.log("getSuggestions:error => ", e)
        console.dir(e)
    }

}