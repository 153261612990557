<template>
  <div class="usage_limit__content">
    <component is="script" src="https://www.google.com/recaptcha/api.js" async defer/>
    <div
        class="g-recaptcha"
        data-sitekey="6LczRnkUAAAAAF22KbcjdQMRZ28m5S4i9qvLUyNg"
        data-callback="captchaCallback"
    />
  </div>
</template>

<script>

export default {
  name: "CaptchaComponent",
  props: {
    orderDataId: [String, Number]
  },
  mounted() {
    const {buy} = this?.$parent?.$parent
    const orderDataId = this.orderDataId
    window.captchaCallback = async function (token) {
      buy({token, params: {orderDataId}})
    }
  }
}
</script>
