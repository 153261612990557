<template>
  <standard-popup-component
      variant="standard"
      class="my-sources"
      :header="headTitle"
      v-click-outside="onClickOutside"
  >
    <my-sources-add-component
        v-if="currentSourceMode === SourceMode.ADD ||  currentSourceMode === SourceMode.DELETE"
        :shared-state="sharedState"
        :current-source-mode="currentSourceMode"
        :my-sources-data="mySourcesData"
        :primary-btn-text-code="primaryBtnTextCode"
        :text-code="textCode"
        :show-content="showContent"
        :is-loading="isLoading"
        :db-shortcut="dbShortcut"
        @update-mode="currentSourceMode = $event.mode;updateSourceValue($event)"
        @close-sources="onClickOutside(true)"
        @save-source="$emit('documents-saved')"
        @close-select-el="clickOutsideHandler"
    />
    <create-new-source
        v-else-if="currentSourceMode === SourceMode.CREATE || currentSourceMode === SourceMode.UPDATE"
        :source-value="sourceValue"
        :current-source-mode="currentSourceMode"
        :source-text-ids="sourceTextIds"
        :source-titles="sourceTitles"
        @close-sources="$emit('close-widget')"
        @update-mode="currentSourceMode = $event.mode"
        @update-source-value="sourceValue = ''"
    />
  </standard-popup-component>
</template>

<script>
import Vue from "vue";
import {Plugin} from "vue-fragment";
import vClickOutside from "v-click-outside";

import StandardPopupComponent from "../styled/StandardPopupComponent.vue";
import MySourcesAddComponent from "./MySourcesAddComponent.vue";
import CreateNewSource from "./CreateNewSource.vue";
import {SINGLE_POPUP_ID, SourceMode} from "../../../functions/components/mySourceComponent";
import ButtonDiv from "../styled/ButtonDiv.vue";
import i18n from "../plugins/Translations.vue";
import eventBus from "../../../eventBus";


//===== Plugins =====

Vue.use(Plugin)

//===================

export default {
  name: "MySources",
  components: {
    ButtonDiv,
    CreateNewSource,
    MySourcesAddComponent,
    StandardPopupComponent
  },
  props: {
    sharedState: {
      type: Object,
      required: true
    },
    dbShortcut: {
      type: String,
      default: null
    },
    mySourcesData: {
      type: Array,
      required: true
    },
    currentMode: {
      type: String,
      default: null
    },
    primaryBtnTextCode: {
      type: String,
      default: "genios.watchlist.add"
    },
    textCode: {
      type: String,
      default: "genios.mySources.docCount"
    },
    showContent: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      currentSourceMode: this.currentMode || SourceMode.ADD,
      sourceValue: '',
      vcoConfig: {
        isActive: true
      }
    }
  },
  mounted() {
    eventBus.$on("my-sources-destroy", this.onDestroy)
  },
  computed: {
    SourceMode() {
      return SourceMode;
    },
    headTitle() {
      switch (this.currentSourceMode) {
        case this.SourceMode.ADD:
          return i18n.t('genios.mySources.addTitle')
        case this.SourceMode.CREATE:
          return i18n.t('genios.mySources.createTitle')
        case this.SourceMode.UPDATE:
          return i18n.t('genios.mySources.updateTitle')
        case this.SourceMode.DELETE:
          return i18n.t('genios.mySources.removeTitle')
      }
    },
    sourceTextIds() {
      return this.sharedState.selectedSources.map(source => source.docId?.split("__")[0])
    },
    sourceTitles() {
      return this.sharedState.selectedSources.map(source => source.title)
    }
  },
  methods: {
    onDestroy() {
      eventBus.$off("my-sources-destroy")
      this.$destroy()
    },
    onClickOutside(isClickedFromBtn = false) {
      // block for case when open popup from tile
      //--------- start ---------
      const mySourcesTemplate = document.querySelector(`#${SINGLE_POPUP_ID}_${this.dbShortcut}`) || null
      const element = this.$el
      const parentTile = element.closest(`.media_tile.element[data-document-id='${element?.dataset?.mediaTileId}']`)

      let isTileFocused = !!mySourcesTemplate
      if (!!parentTile)
        isTileFocused = parentTile.matches(":focus-within") //check if element is in focus

      if (!!mySourcesTemplate && (isClickedFromBtn || !isTileFocused)) {
        const parent = mySourcesTemplate.parentNode
        parent.removeChild(mySourcesTemplate)
      }
      //--------- end ---------
      this.$emit('close-widget')
    },
    clickOutsideHandler() {
      this.vcoConfig.isActive = false
    },
    updateSourceValue(event) {
      if (event?.value) this.sourceValue = event.value
    }
  }
}
</script>

<style scoped>

</style>