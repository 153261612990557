import {fetchAttachment, fetchMySources} from "../../fetching";
import {checkSingleDocument} from "../../checks";
import {changeHistory, fakeVisitedLink} from "../../utils/history_utils";
import {rememberCheckedDocument, rememberCheckedSources} from "../../components/selectionComponent";
import {replaceSearchResult} from "../../replacing";
import updateSession from "../../updateSession";
import {closeAllPreviews, closeTransparentPopupModal} from "../../closing";
import {openFullArticleModal, openTransparentPopupModal} from "../../opens";
import {
    setupCompanyNotificationComponent,
    setupLoginComponent,
    setupPaymentFormComponent,
    setupPressNotificationComponent
} from "../vue-components";
import axios from "axios";
import {PaymentMode} from "../../components/payment";
import {
    closeAllMySourcesPopups, getMediaTiles,
    JS_HANDLER,
    setupLoaderToServerIcons,
    setupServerIcons,
    setupServerOutlineIcons,
    SINGLE_POPUP_ID,
    sourceIconState,
    SourceMode,
    standardSetupOfSinglePopup, toggleActivationMySourcesIcons
} from "../../components/mySourceComponent";
import eventBus from "../../../eventBus";

export const setupAttachmentDownloadHandler = function (element = document) {
    if (element) {
        const buttons = element.querySelectorAll('[js-handler*="download_attachment"]') || [];
        if (buttons.length) {
            buttons.forEach((button) => {
                const downloadAttachmentHandler = async function (event) {
                    event.preventDefault();
                    fetchAttachment(button);
                    button.classList.replace('button__primary', "button__secondary");
                    button.classList.add('visited');
                };
                button.removeEventListener('click', downloadAttachmentHandler);
                button.addEventListener('click', downloadAttachmentHandler);

                const buttonPlaceholder = element.querySelector('button.big_button_placeholder[data-legacy-doc-id="' + button.dataset.legacyDocId + '"][data-attachment-type="' + button.dataset.attachmentType + '"]');
                if (buttonPlaceholder) {
                    buttonPlaceholder.classList.remove('big_button_placeholder');
                    if (!button.isSameNode(buttonPlaceholder)) {
                        button.remove();
                    }
                }
            });
        }
    }
};

export const setupSearchPagingHandlers = function () {
    const searchResult = document.querySelector(".searchResult");
    if (searchResult) {
        const pagingLinks = document.querySelectorAll('[js-handler*="pagingClickHandler"]');
        pagingLinks.forEach(link => {
            const offset = link.getAttribute("offset");
            const pagingClickHandler = function (e) {
                e.preventDefault();
                e.stopPropagation();
                window.sharedState.offset = offset;
                replaceSearchResult();
            };
            link.removeEventListener('click', pagingClickHandler);
            link.addEventListener('click', pagingClickHandler);

        });
    }

};

export const setupCheckDocumentHandlers = function () {
    document.querySelectorAll('[js-handler*="articleCheckboxClickHandler"]').forEach(item => {
        item.closest('.element').classList[item.checked ? 'add' : 'remove']('selected');
        const articleCheckboxClickHandler = function (ev) {
            const checkBoxElement = ev.currentTarget;

            const parent = checkBoxElement.parentElement.parentElement;

            const price = parent.querySelector('.buy_button[js-handler*="updatePrices"]')?.getAttribute("data-price") || null;
            let db = checkBoxElement.getAttribute("data-db");

            const sourceIcon = "https://www.genios.de/resource/logos-small/" + db + ".png";
            // const sourceIcon = `/${parent.querySelector('.article__text__source-icon').src.split('/').filter(item => item).splice(2).join('/')}`

            let type = parent.querySelector('.article__img__tag_tile');
            if (!type) type = parent.querySelector('.article__img__tag_row');
            type = type.innerText;

            const docId = checkBoxElement.getAttribute("value");
            const title = checkBoxElement.getAttribute("data-title");
            const year = checkBoxElement.getAttribute("data-year");
            const source = checkBoxElement.getAttribute("data-source");
            const pageType = checkBoxElement.getAttribute("data-page-type");
            const priceCategory = checkBoxElement.getAttribute("data-price-category");
            //TODO: add missing correct image for emailsend

            checkSingleDocument(docId, ev.target.checked);

            if (pageType === "browse")
                rememberCheckedSources(docId, ev.target.checked, source);
            else
                rememberCheckedDocument(docId, ev.target.checked, title, source, price, sourceIcon, type, year, priceCategory, checkBoxElement.dataset?.mediaType);

        };

        item.removeEventListener('click', articleCheckboxClickHandler, false);
        item.addEventListener('click', articleCheckboxClickHandler, false);
    });
};

export const setupChangeViewHandlers = function () {

    const viewSwitcher = function (view, pushHistory) {
        const searchResultArticles = document.querySelector(".searchResult__articles");
        const hitListSwitcher = document.querySelector("#hit_list_switcher");
        if (!!searchResultArticles && !!hitListSwitcher) {
            if (view === 'grid' && searchResultArticles.classList.contains("list-active")) {
                searchResultArticles.classList.replace("list-active", "grid-active");
                hitListSwitcher.classList.replace("list-active", "grid-active");
                changeHistory({view: "grid"}, pushHistory);
                updateSession('grid');
            } else if (view === 'list' && searchResultArticles.classList.contains("grid-active")) {
                searchResultArticles.classList.replace("grid-active", "list-active");
                hitListSwitcher.classList.replace("grid-active", "list-active");
                changeHistory({view: "list", preview: ""}, pushHistory);
                updateSession('list');
                closeAllPreviews();
            }
        }
    };

    const listSwitchClickHandler = function (event) {
        event.preventDefault();
        viewSwitcher('list', true);
        window.sharedState.view = 'list';
    };
    const gridSwitchClickHandler = function (event) {
        event.preventDefault();
        viewSwitcher('grid', true);
        window.sharedState.view = 'grid';
    };
    const listButton = document.querySelector('[js-handler*="gridSwitchClickHandler"]');
    const gridButton = document.querySelector('[js-handler*="listSwitchClickHandler"]');
    if (typeof (listButton) !== 'undefined' && listButton !== null) {
        listButton.addEventListener('click', gridSwitchClickHandler);
        listButton.addEventListener('touchend', gridSwitchClickHandler);
    }
    if (typeof (gridButton) !== 'undefined' && gridButton !== null) {
        gridButton.addEventListener('click', listSwitchClickHandler);
        gridButton.addEventListener('touchend', listSwitchClickHandler);
    }

    if (listButton !== null || gridButton !== null) {
        //ensure view matches url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('view')) {
            const viewWanted = urlParams.get('view');
            viewSwitcher(viewWanted, false);
        } else {
            // we should add current view parameter
            const searchResultArticles = document.querySelector(".searchResult__articles");
            const currentView = (!!searchResultArticles &&
                searchResultArticles.classList.contains("list-active")) ? "list" :
                searchResultArticles.classList.contains("grid-active") ? "grid" : "";
            changeHistory({view: currentView}, false);
        }

        if (urlParams.get("bundleDocumentId"))
            changeHistory({bundleDocumentId: ""}, false);
        if (urlParams.get("expandedListName"))
            changeHistory({expandedListName: ""}, false);
    }
};

export const setupEbookAttachmentDownloadHandler = function (element = document.documentElement) {
    if (!element) element = document;
    const buttons = element.querySelectorAll('[js-handler*="download_ebook_attachment"], [js-handler*="download_ebook_chapter_attachment"]');

    buttons.forEach(button => {
        const downloadAttachmentHandler = async function (event) {
            event.preventDefault();
            fetchAttachment(button);
            button.classList.add('paid');
        };
        button.removeEventListener('click', downloadAttachmentHandler);
        button.addEventListener('click', downloadAttachmentHandler);
    });
};

export const setupTableOfContentLinks = function (tableOfContentsOrText, selectedDocId) {
    if (window.sharedState.page !== 'document') {
        const documentLinks = tableOfContentsOrText.querySelectorAll('[js-handler*="openArticleOnClick"]');
        // noinspection JSMismatchedCollectionQueryUpdate
        let documentIdList = [];
        if (!!documentLinks)
            documentIdList = Array.from(documentLinks).map(element => element.getAttribute("data-document-id"))
                .filter(ele => ele != null);

        const current_url = window.location.href
        const openArticleOnClick = async function (event) {
            event.preventDefault();

            const documentId = event.currentTarget.getAttribute("data-document-id");
            const forceConfirmPrice = event.currentTarget.hasAttribute("data-force-confirm-price");
            const linkHref = event.currentTarget.href;

            const {data} = await axios.post(`/api/retrieveDocuments`, {
                ids: [documentId],
                forceConfirmPrice: !!forceConfirmPrice,
            });

            if (data?.code) {
                await setupPaymentFormComponent(data, documentId, PaymentMode.fullDocument);
            } else {
                fakeVisitedLink({current_url, desired_url: linkHref})
                window.sharedState.fullArticleModalList = documentIdList;
                openFullArticleModal(documentId, true, forceConfirmPrice).then(() => {
                });
            }

        }

        documentLinks.forEach(link => {
            link.removeEventListener('click', openArticleOnClick);
            link.addEventListener('click', openArticleOnClick);
            if (selectedDocId && selectedDocId === link.getAttribute("data-document-id")) {
                link.classList.add("visited");
                fakeVisitedLink({current_url, desired_url: link.href});
                link.classList.replace("button__primary", "button__secondary");
            }
        });
    }
};

export const setupNotificationButton = function (tableOfContentsOrText) {
    const button = tableOfContentsOrText.querySelector("#press_notification_button");
    const monitoringHandler = function ({currentTarget}) {
        openTransparentPopupModal();
        const overlayContent = document.querySelector("#overlay_content");
        overlayContent.innerHTML = '<div id="s"></div>';
        setupPressNotificationComponent("#s", currentTarget);
    };
    if (button) {
        button.removeEventListener("click", monitoringHandler);
        button.addEventListener("click", monitoringHandler);
    }
};

export const setupCompanyNotificationButton = function (button) {
    const monitoringHandler = function ({currentTarget}) {
        openTransparentPopupModal();
        const overlayContent = document.querySelector("#overlay_content");
        overlayContent.innerHTML = '<div id="s"></div>';
        setupCompanyNotificationComponent("#s", currentTarget);
    };
    if (button) {
        button.removeEventListener("click", monitoringHandler);
        button.addEventListener("click", monitoringHandler);
    }
};

const onClickHandler = event => {
    event.stopPropagation()

    const item = event.target

    eventBus.$emit("close-my-sources-global", false)

    const isLoggedIn = window.sharedState.loginStatus.loggedIn;

    const tile = event.target.closest(".media_tile.element")

    if (isLoggedIn && window.sharedState.currentMySources.db === null) {
        window.sharedState.currentMySources.db = tile.dataset.db
    }

    const iconType = event.target.classList.contains("button-server-outline") ? sourceIconState.OUTLINE : sourceIconState.SERVER

    if (!isLoggedIn) {
        openTransparentPopupModal();
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login_send_email'></div>";
        setupLoginComponent("#embedded_login_send_email", "genios.login.reasons.login", function () {
            closeTransparentPopupModal();
            tile.focus()
        });
        eventBus.$emit("setup-my-sources-item", item)
    } else {
        setupMySourcesPopupToggleHandler(item, iconType).then()
    }

}

export const setupMySourceHandler = async () => {

    const mySourcesTiles = document.querySelectorAll(`[js-handler*="${JS_HANDLER}"]`) || []
    if (!!mySourcesTiles?.length) {
        mySourcesTiles.forEach(item => {

            const onClickHandler = event => {
                event.stopPropagation()

                eventBus.$emit("close-my-sources-global", false)

                const isLoggedIn = window.sharedState.loginStatus.loggedIn;

                const tile = event.target.closest(".media_tile.element")

                if (isLoggedIn && window.sharedState.currentMySources.db === null) {
                    window.sharedState.currentMySources.db = tile.dataset.db
                }

                const iconType = event.target.classList.contains("button-server-outline") ? sourceIconState.OUTLINE : sourceIconState.SERVER

                if (!isLoggedIn) {
                    openTransparentPopupModal();
                    const contentContainer = document.querySelector("#overlay_content");
                    contentContainer.innerHTML = "<div id='embedded_login_send_email'></div>";
                    setupLoginComponent("#embedded_login_send_email", "genios.login.reasons.login", function () {
                        closeTransparentPopupModal();
                        tile.focus()
                    });
                    eventBus.$emit("setup-my-sources-item", item)
                } else {
                    setupMySourcesPopupToggleHandler(item, iconType)
                }

            }

            item.removeEventListener("click", onClickHandler)
            item.addEventListener("click", onClickHandler)
        })
    }
}

export const setupMySourcesPopupToggleHandler = async (item, iconType = null) => {

    const parent = item.closest(".bookmark")

    const tile = item.closest(".media_tile.element")

    const mySourcesHandlerPopup = parent.querySelector(`#${SINGLE_POPUP_ID}_${tile.dataset.db}`) || null

    closeAllMySourcesPopups(mySourcesHandlerPopup)

    if (!!mySourcesHandlerPopup) {
        eventBus.$emit("my-sources-destroy")
        // parent.removeChild(mySourcesHandlerPopup)
    } else {

        const db = tile.dataset.db

        const mediaTiles = Array.from(getMediaTiles()).filter(element => element.dataset.db !== db)

        if (iconType === sourceIconState.SERVER)
            toggleActivationMySourcesIcons(mediaTiles, true)

        const shouldLoadData = [sourceIconState.SERVER, null].includes(iconType) && (db !== window.sharedState.currentMySources.db || window.sharedState.currentMySources.data.length === 0)

        if (shouldLoadData) {
            setupLoaderToServerIcons([tile]).then()
            fetchMySources(db)
                .then(data => {
                    if (data.length > 0) {
                        window.sharedState.currentMySources.db = tile.dataset.db
                        window.sharedState.currentMySources.data = data.length > 0 ? data : window.sharedState.mySources
                    }

                    if (iconType === null) {
                        iconType = data.length > 0 ? sourceIconState.SERVER : sourceIconState.OUTLINE
                    }

                    const info = standardSetupOfSinglePopup({data, tile, parent}, iconType)

                    //hide loader and show icon
                    if (info.currentMode === SourceMode.DELETE || data.length > 0) {
                        setupServerIcons([tile])
                    } else {
                        setupServerOutlineIcons([tile])
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    toggleActivationMySourcesIcons(mediaTiles, false)
                    eventBus.$emit("my-sources-loader", false)
                })
        } else {
            standardSetupOfSinglePopup({tile, parent}, iconType)
            toggleActivationMySourcesIcons(mediaTiles, false)
            eventBus.$emit("my-sources-loader", false)
        }
    }
}
