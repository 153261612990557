export function rememberCheckedDocument(
    docId,
    checked,
    title,
    source,
    price,
    sourceIcon,
    type,
    year,
    priceCategory,
    mediaType
) {
    if (!!docId) {
        handleSelection(window.sharedState.selectedDocuments, docId, checked, title, source, price, sourceIcon, type, year,
            priceCategory, mediaType);
        sessionStorage.setItem('selectedDocuments', JSON.stringify(window.sharedState.selectedDocuments));
    }
}

export function rememberCheckedSources(sourceId, checked, source) {
    if (!!sourceId) {
        handleSelection(window.sharedState.selectedSources, sourceId, checked, source, source);
        sessionStorage.setItem('selectedSources', JSON.stringify(window.sharedState.selectedSources));
    }
}

function handleSelection(selectedItems, docId, checked, title, source, price, sourceIcon, type, year,
                         priceCategory, mediaType) {
    if (checked) {
        //add if not exist
        if (selectedItems.filter(item => item.docId === docId).length === 0) {
            selectedItems.push({
                docId, title, source, price, sourceIcon, type, year,
                priceCategory,
                mediaType
            });
        }
    } else {
        // remove from array
        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].docId === docId) {
                selectedItems.splice(i, 1);
            }
        }
    }
}