import eventBus from "../../eventBus";

export const emailsGroupMixin = {
    data() {
        return {
            toggleEmailGroup: false
        }
    },
    methods: {
        modifyCheckboxFields(event, option) {
            option.fieldValue = event
        },
        openEmailGroupPopup() {
            this.toggleEmailGroup = !this.toggleEmailGroup
        },
        closePopup() {
            this.toggleEmailGroup = false
        }
    }
}

export const emailsDataGroupMixin = {
    data() {
        return {
            emailsGroupList: []
        }
    },
    mounted() {
        eventBus.$on("update-group-list", (emails) => {
            this.emailsGroupList = [...emails]
        })
    },
    computed: {
        emailsToSend() {
            return this.emailsGroupList.map(item => item.activeEmails).flat()
        }
    }
}

export const emailsComputedGroupMixin = {
    computed: {
        checkedEmailsGroup() {
            return this.emailsGroupList.filter(item => item.fieldValue)
        }
    }
}