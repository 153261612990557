<template>
  <form class="payment__template" action="/" @submit.prevent="order">
    <div class="payment__title">{{ $t('orderingProcess.requestReason.title') }}</div>
    <div class="payment__message">{{
        $t('orderingProcess.requestReason.message', {
          media: 'Bonitätsauskünften'
        })
      }}
    </div>

    <div class="payment__form" v-if="!ignoreRequestReason">
      <RadioGroupComponent
          :group="radioBtns"
          :initial-value="passedReason"
          @update="updateRadioValue"
      />
    </div>

    <FullInfoComponent
        v-if="useFullInfoComponent === true"
        :values="isValid ? this.validationOrderModel.receivedParameters : fullInfoForm"
        @update="updateFullInfoForm"
        @validation="updateFullInfoErrors"
        :errors="errors"
    />

    <AddendumComponent
        v-if="isRemoteAgencyMetadata"
        @update="updateAddendumForm"
        :values="isValid ? this.validationOrderModel.receivedParameters : fullInfoForm"
        @validation="updateAddendumFormErrors"
        :errors="errors"
    />

    <div class="payment__form__info">
      *{{ $t('orderingProcess.requestReason.form.info') }}
    </div>

    <div class="payment__errors">
      <p
          class="enter__error"
          v-for="(value, key) in errors"
          :key="key"
      >{{ value }}
      </p>
    </div>


    <div class="payment__actions">
      <Button
          type="button"
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
      />
      <Button
          type="submit"
          variant="primary"
          :text="primaryButtonText"
          :toggle-text="false"
      />
    </div>
    <div class="payment__hint" v-html="$t('orderingProcess.requestReason.hint')"/>
  </form>
</template>

<script>
import Button from "../../styled/Button.vue";
import RadioGroupComponent from "../../FormElements/radio/RadioGroupComponent.vue"
import i18n from "../../plugins/Translations.vue"
import FullInfoComponent from "./FullInfoComponent.vue";
import AddendumComponent from "./AddendumComponent.vue";
import {convertArrayToObject} from "../../../../functions/utils/array_utils";
import {isUndefined} from "lodash";

export default {
  name: "RequestReasonFormComponent",
  components: {AddendumComponent, FullInfoComponent, RadioGroupComponent, Button, i18n},
  props: {
    orderResponse: {
      type: Object,
      required: true
    },
    sharedState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      creditRatingReason: this.orderResponse?.requestReasonStepModel?.creditRatingReason,
      fullInfoForm: {},
      addendumForm: {},
      fullInfoErrors: {},
      addendumFormErrors: {},
      errors: {},
    }
  },
  computed: {
    form() {
      const form = {
        emailRequired: this.isRemoteAgencyMetadata,
        creditRatingReason: this.passedReason,
        ...this.fullInfoForm,
        ...this.addendumForm
      };
      if (!this.ignoreRequestReason && !isUndefined(this.creditRatingReason)) {
        form.creditRatingReason = this.creditRatingReason;
      }
      if(form.emailRequired && !form?.primaryEmail) {
        form.primaryEmail = this.sharedState.defaultUserEmail
      }
      return form;
    },
    orderDataId() {
      return this.orderResponse?.orderDataId;
    },
    primaryButtonText() {
      return this.stepModel?.showPrices ?
          i18n.t("orderingProcess.requestReason.buttonWithPrice") :
          i18n.t("orderingProcess.requestReason.buttonWithoutPrice")
    },
    documentPrice() {
      const price = this.orderResponse.orderDataModel.totalGross
      return Number(price).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
    },
    isValid() {
      return !!this.orderResponse.validationModel
    },
    validationOrderModel() {
      return this.orderResponse.validationModel
    },
    stepModel() {
      return this.orderResponse?.requestReasonStepModel || {};
    },
    passedReason() {
      if (this.isValid) {
        return this.validationOrderModel.receivedParameters.creditRatingReason
      }
      return this.stepModel?.creditRatingReason || null;
    },
    reasons() {
      return this.stepModel?.reasons || [];
    },
    ignoreRequestReason() {
      return !!(this.stepModel?.ignoreRequestReason);
    },
    isRemoteAgencyMetadata() {
      return !!(this.stepModel?.isRemoteAgencyMetadata && this.sharedState.loginStatus.loggedIn && !(this.stepModel?.isBasisUser || !this.stepModel?.isRealUser));
    },
    useFullInfoComponent() {
      return (!this.stepModel) || this.stepModel.isBasisUser || !(this.stepModel.isRealUser);
    },
    radioBtns() {
      return this.reasons.map(reason => {
        return {
          id: `radio_${reason}`,
          fieldLabel: i18n.t("orderingProcess.requestReason.reasons." + reason),
          value: reason
        }
      });
    }
  },
  created() {
    if (this.isValid) {
      const errors = convertArrayToObject(this.validationOrderModel.errors)
      for (const errorsKey in errors) {
        if (errorsKey === "postcode") {
          this.errors.zipcode = errors[errorsKey]
        } else {
          this.errors[errorsKey] = errors[errorsKey]
        }
      }
      if (!!this.errors.primaryEmail) {
        this.errors.primaryEmail = i18n.t('orderingProcess.requestReason.form.primaryEmail.error');
      }
      if (!!this.errors.alternativeEmail) {
        this.errors.alternativeEmail = i18n.t('orderingProcess.requestReason.form.alternativeEmail.error');
      }
    }
  },
  methods: {
    closeWindow() {
      this.$emit("closeWindow");
    },
    updateRadioValue(value) {
      this.creditRatingReason = value;
    },
    updateFullInfoForm(form) {
      this.fullInfoForm = form;
    },
    updateAddendumForm(value) {
      this.addendumForm = value;
    },
    updateFullInfoErrors(errors) {
      this.fullInfoErrors = {...errors};
    },
    updateAddendumFormErrors(errors) {
      this.addendumFormErrors = {...errors};
    },
    order() {
      if (this.checkErrors()) return;
      const {buy} = this.$parent
      const params = {
        orderDataId: this.orderDataId,
        ...this.form
      }

      buy({params})
    },
    checkErrors() {
      const isValuesEqual = this.compareFormValues(this.form)
      if (isValuesEqual) {
        if (this.useFullInfoComponent) {
          this.errors = {
            ...this.fullInfoErrors,
            ...this.addendumFormErrors
          };
        } else {
          this.errors = {};
        }
        return !!Object.keys(this.errors).length;
      }
      return true
    },
    compareFormValues(form) {
      if (this.validationOrderModel?.receivedParameters) {
        for (const [key] of Object.entries(this.errors)) {
          if (!isUndefined(form[key])) {
            if (form[key] === this.validationOrderModel.receivedParameters[key]) {
              return false
            }
          }
        }
      }
      return true
    }
  }
}
</script>
