<template>
  <div class="flex" role="status" tabindex="0" aria-label="`Tag ${ tagName }`">
    {{ tagName }}
  </div>
</template>

<script>
export default {
  name: "TagComponent.vue",
  props: {
    tagName: String,
  }
}
</script>