<template>
<div class="payment__header">
  <img class="payment__logo" @click="redirectToHomePage" src="/icons/GENIOS_LOGO.svg" alt="logo">
</div>
</template>

<script>
export default {
  name: "MainHeader",
  methods: {
    redirectToHomePage() {
      window.location.assign(`/`)
    }
  }
}
</script>