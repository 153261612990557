<template>
  <transition name="toggle">
    <div
        class="fixed heightMinusFooter"
        :class="sidePositionClass"
        v-show="openAdvancedSearchPanel"
    >
      <div class="advancedSearch">
        <div class="advancedSearch__header">
          <div class="advancedSearch__text">
            <p>{{ $t("genios.advancedSearch.name") }}</p>
          </div>
          <div class="advancedSearch__toggle">
            <Button
                name="show-button"
                type="button"
                variant="icon"
                @click="toggleOpenAdvancedSearchPanel"
                aria-label="advanced search"
            >
              <ion-icon v-if="closeButtonClass.type === 'chevron'" src="/icons/custom/chevron-back-outline.svg"/>
              <ion-icon v-else :name="closeButtonClass.value"/>
            </Button>
          </div>
        </div>
        <div class="border-line"></div>

        <form class="advancedSearch__form"
              @submit="checkForm"
              @reset="reset"
              autocomplete="off">
          <!--
          TEXT,
          DROPDOWN,
          CHECKBOX,
          CHECKBOX_GROUP,
          RANGE;
          -->
          <template
              v-for="element in searchMask.elements">
            <TextFormElement
                :key="element.fieldLabel"
                v-if="element.fieldType === 'TEXT'"
                :element="element"
                :passedValue="fields[element.fieldLabel]"
                :focus="focus"
                v-on:focus="focus = $event"
                v-on:modified="updateFields(element.fieldLabel, $event)"
            />
            <CheckboxFormElement
                :key="element.fieldLabel"
                v-else-if="element.fieldType === 'CHECKBOX'"
                :element="element"
                :passedValue="fields[element.fieldLabel]"
                :focus="focus"
                v-on:focus="focus = $event"
                v-on:modified="updateFields(element.fieldLabel, $event)"
            />
            <DropdownFormElement
                :key="element.fieldLabel"
                v-else-if="element.fieldType === 'DROPDOWN'"
                :element="element"
                :focus="focus"
                v-on:focus="focus = $event"
                v-on:modified="updateFields(element.fieldLabel, $event.text)"
                :passedValue="getAdvancedSearchOption(element)"
                :scroll-into-view="true"
            />
            <CheckboxGroupFormElement
                :key="element.fieldLabel"
                v-else-if="element.fieldType === 'CHECKBOX_GROUP'"
                :element="element"
                :passedValue="fields[element.fieldLabel]"
                :focus="focus"
                v-on:focus="focus = $event"
                v-on:modified="updateFields(element.fieldLabel, $event)"
            />
            <RangeFormElement
                :key="element.fieldLabel"
                v-else-if="element.fieldType === 'RANGE'"
                :element="element"
                :passedValue="fields[element.fieldLabel]"
                :focus="focus"
                v-on:focus="focus = $event"
                v-on:modified="updateFields(element.fieldLabel, $event)"
            />
          </template>
          <div class="advancedSearch__form__footer">
            <Button
                name="reset"
                :text="$t('genios.input.clear')"
                variant="secondary"
                border="none"
                :text-style="400"
                decoration="underline"
                type="reset"
                color="#757575"
                style="margin-right: 10px;"
                :toggle-text="false"
            />
            <Button
                name="submit"
                :text="$t('genios.words.search')"
                variant="primary"
                position="left"
                type="submit"
                icon="search-outline"
                :toggle-text="false"
                class="advanced_search_btn"
            />
          </div>
        </form>

      </div>
    </div>
  </transition>
</template>

<script>
import browser from 'browser-detect';

import eventBus from "../../../eventBus";
import TextFormElement from "../FormElements/TextFormElement.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import RangeFormElement from "../FormElements/RangeFormElement.vue";
import CheckboxGroupFormElement from "../FormElements/CheckboxGroupFormElement.vue";
import Button from "../styled/Button.vue";
import {fetchSearchMask} from "../../../functions/fetching";
import {replaceSearchResult} from "../../../functions/replacing";

// noinspection JSUnusedLocalSymbols
export default {
  name: "AdvancedSearchComponent",
  components: {
    Button,
    CheckboxGroupFormElement,
    RangeFormElement,
    DropdownFormElement,
    CheckboxFormElement,
    TextFormElement
  },
  props: {
    sidePanelPosition: String,
    sourceFilters: Array,
    categoryFilters: Array,
    advancedSearchParams: Array
  },
  data() {
    return {
      openAdvancedSearchPanel: false,
      searchMask: {},
      focus: null,
      fields: {},
      showOverlayScrollbarSection: false,
    };
  },
  computed: {
    closeButtonClass: function () {
      if (this.sidePanelPosition === 'open') {
        return {
          type: 'chevron'
        };
      }
      return {
        value: "close-outline",
        type: 'icon'
      };
    },
    sidePositionClass: function () {
      if (this.sidePanelPosition === 'open') {
        return "advancedSearchOverlay";
      }
      if (this.sidePanelPosition === 'closed') {
        return "advancedSearchOverlay-medium";
      }
    },
    elements: function () {
      if (!this.searchMask) return [];
      return this.searchMask.elements;

    },
    elementMap: function () {
      let map = {};
      this.elements.forEach(element => {
        map[element.fieldLabel] = element;
      });
      return map;
    },
    newAdvancedSearchParams: function () {
      let params = [];
      Object.keys(this.fields).forEach(key => {
        const element = this.elementMap[key];
        const field = this.fields[key];
        if (element && field) {
          if (typeof field === 'object') {
            Object.keys(field).forEach(key2 => {
              const value = field[key2];
              if (value) {
                params.push(`${element.id}-${element.fieldLabel}_${key2}_${value}`);
              }
            });
          } else {
            params.push(`${element.id}-${element.fieldLabel}_${field}`);
          }
        }
      });
      return params;
    }
  },
  mounted() {
    this.showOverlayScrollbarSection = true;

    eventBus.$on("toggleAdvancedSearch", (panelState) => {
      this.toggleOpenAdvancedSearchPanel(panelState);

      this.$nextTick(() => {
        const el = this.$el.querySelector(".advancedSearch__form")
        this.onFocusHandler(el)
      })
    });
    this.$nextTick(() => {
      this.loadSearchMask();
      this.setFieldsFromParams();
    });

    const browserResult = browser()
    if(browserResult.os.includes('OS X') && browserResult.mobile) {
      const el = this.$el.querySelector(".advancedSearch__form")
      if(!!el) {
        el.setAttribute("tabindex", "-1")
      }
    }

  },
  watch: {
    sourceFilters: function (newValue) {
      this.loadSearchMask(); // update
    },
    categoryFilters: function (newValue) {
      this.loadSearchMask(); // update
    },
    advancedSearchParams: function (newValue) {
      this.setFieldsFromParams();
    },
    elements: function (newValue) {
      if (!this.elements || this.elements.length === 0) {
        this.$emit("empty-search-masks", true);
        this.openAdvancedSearchPanel = false;
      } else {
        this.$emit("empty-search-masks", false);
      }
    },
    openAdvancedSearchPanel: function (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.calculateSize();
        });
      }
    }
  },
  methods: {
    onFocusHandler(element) {
      const el = element || null
      console.log(element)
      if (!!el) {
        el.focus()
      }
    },
    triggerSidenavPositionObserver() {
      if (!!window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.scroll(window.pageOverlayScrollbar.scroll().position.y + 2);
        window.pageOverlayScrollbar.scroll(window.pageOverlayScrollbar.scroll().position.y - 2);
      }
    },
    calculateSize() {
      console.log("calculateSize")
      const headerHeight = document.querySelector("header")?.clientHeight;
      const advancedSearch = document.querySelector(".heightMinusFooter");

      let advancedSearchBody = null;
      let advancedSearch__header = null;
      if (!!advancedSearch) {
        advancedSearchBody = advancedSearch.querySelector(".advancedSearch__form");
        advancedSearch__header = advancedSearch.querySelector(".advancedSearch__header").clientHeight;
      }

      const footRect = document.querySelector("footer").getBoundingClientRect();
      let inView = footRect.y <= (window.innerHeight || document.documentElement.clientHeight);
      if (inView) {
        const bottomDiff = window.innerHeight - footRect.y;
        if (!!advancedSearch) {
          let value = bottomDiff + headerHeight - 1;
          advancedSearch.style.height = "calc(100% - " + value + "px)";
          console.log(advancedSearch.style.height)
          if (!!advancedSearchBody)
            advancedSearchBody.style.height = "calc(100% - " + advancedSearch__header + "px)";
        }
      }


    },
    substringAfter(string, splitter) {
      return string.split(splitter).splice(1).join(splitter);
    },
    getAdvancedSearchOption(element) {
      const advancedParameter = window.sharedState.advancedSearchParams;
      if (advancedParameter.length) {
        const queryIndex = advancedParameter.findIndex(item => this.substringAfter(item, '_') === this.fields[element.fieldLabel]);
        if (queryIndex !== -1) {
          const queryParam = advancedParameter[queryIndex];
          const optionIndex = element.options.findIndex(item => item.text === this.substringAfter(queryParam, '_'));
          return optionIndex !== -1 ? element.options[optionIndex] : element.options[0];
        } else return element.options[0];
      } else return element.options[0];
    },
    reset: function () {
      this.fields = {};
    },
    performSearch: function () {
      window.sharedState.advancedSearchParams = this.newAdvancedSearchParams;
      window.sharedState.offset = 0;
      replaceSearchResult();
      this.openAdvancedSearchPanel = false;
    },
    setFocus: function (focussed) {
      this.focus = focussed;
    },
    loadSearchMask: function () {
      fetchSearchMask(this.setSearchMaskCallback);
    },
    setSearchMaskCallback: function (searchMask) {
      this.searchMask = searchMask;
    },
    toggleOpenAdvancedSearchPanel: function () {
      if (!this.openAdvancedSearchPanel) {
        this.triggerSidenavPositionObserver();          // makes sure the height is recalculated in respect of the footer
      }
      return this.openAdvancedSearchPanel = !this.openAdvancedSearchPanel;
    },
    updateFields: function (id, value) {
      let newFields = {...this.fields};
      newFields[id] = value;
      this.fields = newFields;
    },
    updateGroupFields: function (id, value) {
      let newFields = {...this.fields};
      newFields[id] = value;
      this.fields = newFields;
    },
    setFieldsFromParams: function () {
      let newFields = {};
      this.advancedSearchParams.forEach(param => {
        const paramSplit = param.split('_');
        const label = this.substringAfter(paramSplit[0], '-');
        const fromToIndexOrValue = paramSplit[1];
        if (paramSplit.length > 2) {
          if (newFields[label] === undefined) {
            newFields[label] = {};
          }
          newFields[label][fromToIndexOrValue] = paramSplit[2];
        } else {
          newFields[label] = fromToIndexOrValue;
        }
      });
      this.fields = newFields;
    },
    checkForm: function (e) {
      this.performSearch();
      e.preventDefault();
    }
  }
};
</script>

<style scoped>
.advanced_search_btn {
  padding: 6px 9px;
}
</style>
