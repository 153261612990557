<template>
<div class="payment__steps__container">
  <slot/>
</div>
</template>

<script>
export default {
  name: "PaymentContainer"
}
</script>