import qs from "qs";
import Vue from "vue";
import moment from "moment";
import Vue2TouchEvents from 'vue2-touch-events';
import OverlayScrollbars from 'overlayscrollbars';
import VueSimpleAlert from "vue-simple-alert";
import browser from 'browser-detect';
import "swiped-events";
import i18n from './vue/components/plugins/Translations.vue';
import tocca from 'tocca';

import {getUser} from "./functions/getters";
import {showMoreLess, styleSidenavPosition} from "./functions/stylization";
import {closeAllPreviews, closeFullPopupModal, closeTransparentPopupModal} from "./functions/closing";
import {
    setupBrowsePagingFunctionality,
    setupFullDocumentFunctionality,
    setupHeaderNavigationButton,
    setupMiniPopup,
    setupPreviewFunctionality,
    setupRemoteAgencyDocDownloadButton,
    setupResizeHandler
} from "./functions/setups";
import {checkLogin, checkStoredSelectedItems} from "./functions/checks";
import {
    fetchHigherResultListDocumentIds,
    fetchLowerResultListDocumentIds,
    fetchNavigationTree,
    fetchTableOfContents
} from "./functions/fetching";
import updatePrices from "./functions/updatePrices";
import updateSession from "./functions/updateSession";
import {
    setupArchiveMonthPickerComponent,
    setupEPaperDownloadButtonComponent,
    setupFidoComponent,
    setupFullDocumentComponent,
    setupLoginButtonsComponent,
    setupLoginComponent,
    setupNavigationSelectorComponent,
    setupPaymentFormComponent,
    setupResultVueComponents,
    setupSearchComponent,
    setupSidePanelComponent,
    setupSourceFilterComponent,
    setupSourceSearcherComponent,
    setupWebUserRegistrationFormComponent
} from "./functions/setups/vue-components";
import {
    setupAttachmentDownloadHandler,
    setupChangeViewHandlers,
    setupCheckDocumentHandlers,
    setupCompanyNotificationButton,
    setupEbookAttachmentDownloadHandler,
    setupSearchPagingHandlers
} from "./functions/setups/handlers";
import {horizontalScrollRecommendations, setupNotImplementedAlert} from "./functions/temp";
import {
    openFullArticleModal,
    openMultipleArticleModal,
    openPreview,
    openQueryPopup,
    openTransparentPopupModal
} from "./functions/opens";
import {watchlistAddAllButtons} from "./functions/components/watchlist";
import {changeSortingOrderHistory} from "./functions/components/searchSettingComponent";
import {autoSelectedFilteredSource, restorePageFromPagingState} from "./functions/replacing";
import {setupSendEmailButtons} from "./functions/components/sendEmailComponent";

import "../sass/main.scss";
import {setupShareButton} from "./functions/components/shareButton";
import {changeHistory} from "./functions/utils/history_utils";
import {renderVideo} from "./functions/components/videoComponent";
import {setupFirstLoadModuleOfMySourcesFunctionality} from "./functions/components/mySourceComponent";

const start = Date.now();

// HMR implementation with Self-Accepted Modules
// https://blog.nativescript.org/deep-dive-into-hot-module-replacement-with-webpack-part-two-handling-updates/
if (module?.hot) {
    let mainCSSArray = document.head.querySelectorAll('link[href="/css/main.css"]');
    mainCSSArray.forEach(item => document.head.removeChild(item));
    module.hot.accept();
}

//make moment library use browser locale
moment.locale(window.navigator.language);

//handle browser back and forward buttons
window.onpopstate = restorePageFromPagingState;
window.onunload = function () {
};

window.openFullArticleModalGlobal = function (docId) {
    openFullArticleModal(docId.id).then();
};

window.captchaCallback = function (value) {
    alert("default implementation of captcha callback: " + value);
};

window.showMoreLess = function (that) {
    showMoreLess(that);
};

window.openTransparentPopupModal = openTransparentPopupModal;

window.onload = function ({target: {body}}) {
    //TODO: Friedrich wanted to remove focus from search field on index page =)
    // let onBrowserPage = document.querySelector(".browse_page");
    // if (!onBrowserPage) {
    //     let input = document.getElementById('search_subject');
    //     if (input) input.focus();
    // }
    updateSession(null, null, null, localStorage.getItem('mode'));

    // attachEvents();

    if (body.classList.contains("demeter")) {
        const content = document.querySelector('.demeter .content');
        OverlayScrollbars(content, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            },
            overflowBehavior: {x: 'hidden'}
        });
    }


};
// var lastActiveElement = document.activeElement;
//
// function detectBlur() {
//     if(!isSameActiveElement()){
//         console.log("blur change: ", document.activeElement);
//
//     }
//
// }
//
// function isSameActiveElement() {
//     var currentActiveElement = document.activeElement;
//     if(lastActiveElement != currentActiveElement) {
//         lastActiveElement = currentActiveElement;
//         return false;
//     }
//
//     return true;
// }
//
// function detectFocus() {
//     if(!isSameActiveElement()){
//         console.log("focus change: ",document.activeElement);
//
//     }
// }

// function attachEvents() {
//     window.addEventListener ? window.addEventListener('focus', detectFocus, true) : window.attachEvent('onfocusout', detectFocus);
//
//     window.addEventListener ? window.addEventListener('blur', detectBlur, true) : window.attachEvent('onblur', detectBlur);
// }


Vue.config.devtools = true;
Vue.config.ignoredElements = ['ion-icon'];
Vue.use(VueSimpleAlert);
Vue.use(Vue2TouchEvents);

//use touch and swipe library tocca
tocca.constructor();
// console.log(window.tocca());


const path = window.location.pathname;
const splitPath = path.split('/');
const pathRoot = decodeURIComponent(splitPath.length > 0 ? splitPath[1] : '');
const urlTopLevelNavigation = splitPath.length > 2 ? decodeURIComponent(path.split('/')[2]) : undefined;
const urlSecondLevel = splitPath.length > 3 ? decodeURIComponent(path.split('/')[3]) : undefined;
const urlThirdLevel = splitPath.length > 4 ? decodeURIComponent(path.split('/')[4]) : undefined;
const urlForthLevel = splitPath.length > 5 ? decodeURIComponent(path.split('/')[5]) : undefined;


const queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true}).requestText;
const offset = qs.parse(window.location.search, {ignoreQueryPrefix: true}).offset || 0;
const size = parseInt(qs.parse(window.location.search, {ignoreQueryPrefix: true}).size);
const order = qs.parse(window.location.search, {ignoreQueryPrefix: true}).order || 'desc';
const sort = qs.parse(window.location.search, {ignoreQueryPrefix: true}).sort || 'BY_DATE';
    const view = qs.parse(window.location.search, {ignoreQueryPrefix: true}).view || window?.sharedState?.view || "list";

function ensureArray(thing) {
    if (thing === undefined) return [];
    return thing.constructor === Array ? thing : [thing];
}

function definePage() {
    return window.pageType;

}

function setupActiveFilters() {
    const categories = qs.parse(window.location.search, {ignoreQueryPrefix: true}).category;
    const sources = qs.parse(window.location.search, {ignoreQueryPrefix: true}).source;
    const industries = qs.parse(window.location.search, {ignoreQueryPrefix: true}).industry;
    const companies = qs.parse(window.location.search, {ignoreQueryPrefix: true}).company;
    const topics = qs.parse(window.location.search, {ignoreQueryPrefix: true}).topic;
    const regions = qs.parse(window.location.search, {ignoreQueryPrefix: true}).region;
    const people = qs.parse(window.location.search, {ignoreQueryPrefix: true}).person;
    const authors = qs.parse(window.location.search, {ignoreQueryPrefix: true}).author;
    const instructors = qs.parse(window.location.search, {ignoreQueryPrefix: true}).instructor;
    const paramDates = qs.parse(window.location.search, {ignoreQueryPrefix: true}).date;


    let dates = [];
    let newDate = {};
    ensureArray(paramDates).forEach(date => {
        if (date.startsWith("from_")) {
            if (!!newDate.from) {
                dates.push(newDate);
                newDate = {};
            }
            newDate.from = date.substring(date.indexOf("_") + 1);
        }
        if (date.startsWith("to_")) {
            if (!!newDate.to) {
                dates.push(newDate);
                newDate = {};
            }
            newDate.to = date.substring(date.indexOf("_") + 1);
        }
    });
    if (Object.keys(newDate).length) {
        dates.push(newDate);
    }
    return {
        date: dates,
        category: ensureArray(categories),
        source: ensureArray(sources),
        industry: ensureArray(industries),
        company: ensureArray(companies),
        topic: ensureArray(topics),
        region: ensureArray(regions),
        person: ensureArray(people),
        author: ensureArray(authors),
        instructor: ensureArray(instructors)
    };
}

const setupBrowseActiveFilters = () => {
    const res = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    window.sharedState.browseFilters.forEach(browseFilter => {
        const key = browseFilter.name;
        res[key] = urlSearchParams.getAll(key) || [];
    });
    return res;
};

function setupAdvancedSearchParams() {
    const advancedSearchParams = qs.parse(window.location.search, {ignoreQueryPrefix: true}).advanced;
    return ensureArray(advancedSearchParams);
}

// get initial login status from rendered header template
const initialLoginStatus = () => {
    const loginHeaderSection = document.querySelector("#header__login");
    let status = {
        loggedIn: false
    };
    if (!!loginHeaderSection) {
        status = {
            loggedIn: loginHeaderSection.getAttribute("auth") === "true"
        };
    }
    return status;
};

//console.log("before sharedState: " + (Date.now() - start))

window.sharedState = {
    loginStatus: initialLoginStatus(),
    date: "test string",
    loadedData: false,
    navigationTree: [],
    queryString: queryString ? queryString : '',
    path: path,
    pathRoot: pathRoot,
    urlTopLevelNavigation: urlTopLevelNavigation,
    urlSecondLevel: urlSecondLevel,
    urlThirdLevel: urlForthLevel || urlThirdLevel,
    textIdCountMap: {},
    filters: {
        category: {}
    },
    fullFilters: {},
    userInfo: {},
    page: definePage(),
    activeFilters: setupActiveFilters(),
    filtersAvailable: {},
    openCheckboxFilter: '',
    advancedSearchParams: setupAdvancedSearchParams(),
    offset: offset,
    fullArticleModalList: [],
    selectedBrowseSource: {
        name: '',
        type: ''
    },
    sort: sort || "BY_DATE",
    pageSize: size || "10",
    order: order || "desc",
    resultListType: "DEFAULT",
    selectedDocuments: JSON.parse(sessionStorage.getItem("selectedDocuments")) || [],
    selectedSources: JSON.parse(sessionStorage.getItem("selectedSources")) || [],
    watchlistDocumentIds: JSON.parse(sessionStorage.getItem("watchlistDocumentIds") || null),
    lastPaidBundleDocument: "",
    fidoCategory: "",
    defaultUserEmail: sessionStorage.getItem("defaultUserEmail"),
    mySources: [],
    view,
    ...window.sharedState // take already set values if set by backend
};

if (window.sharedState.page === "browse") {
    window.sharedState.browseActiveFilters = setupBrowseActiveFilters();
    window.sharedState.currentMySources = {
        db: null,
        data: []
    };
}


//console.log("after sharedState: " + (Date.now() - start))

setupNavigationSelectorComponent();

//console.log("after navigation: " + (Date.now() - start))

setupSidePanelComponent();

//console.log("after sidePanel: " + (Date.now() - start))


//replace static login buttons with vue component
setupLoginButtonsComponent();

const loginComponentElement = document.querySelector("#login_component");
if (!!loginComponentElement) {
    //we need to build the login component for the login page
    let redirectUrl = document.referrer;
    if (redirectUrl.includes('login') || redirectUrl.includes('logout') || !redirectUrl) {
        redirectUrl = '/';
    }
    setupLoginComponent("#login_component", '', function () {
        setTimeout(function () {
            window.location.replace(redirectUrl);
        }, 3000);
    });
}

const withdrawalLinkElement = document.querySelector("#withdrawalLink");
if (!!withdrawalLinkElement) {
    withdrawalLinkElement.addEventListener("click", function (evt) {
        const {loggedIn} = window.sharedState.loginStatus;
        if (!loggedIn) {
            evt.preventDefault();
            closeFullPopupModal(false, false);
            const contentContainer = document.querySelector("#overlay_content");
            contentContainer.innerHTML = "<div id='embedded_login'></div>";
            openTransparentPopupModal();
            setupLoginComponent("#embedded_login", "genios.login.reasons.withdrawal", function () {
                closeTransparentPopupModal();
                window.open(withdrawalLinkElement.href, "_blank");
            });
        }
    });
}

let availableFilters = localStorage.getItem('availableFilters');
if (!!availableFilters) {
    window.sharedState.filtersAvailable = JSON.parse(availableFilters);
}


fetchNavigationTree(true).then(() => {
    setupSearchComponent();
});


checkLogin().then();


//periodically check login status
setInterval(function () {
    checkLogin().then();
}, 30000);

getUser().then();

//periodically check and add touch listeners to all buttons and links due to safari on ios problems
const browserResult = browser();
// console.log(browserResult);
const clickMe = function (event) {
    event.preventDefault();
    event.stopPropagation();
    event.target.click();
};
// console.log('navigator.maxTouchPoints: ', navigator.maxTouchPoints);
if (browserResult.os.includes('OS X') && navigator.maxTouchPoints > 1) {
    console.log("OS X touch device detected");
    setInterval(function () {
        const links = document.querySelectorAll('a:not(.tap_activated)');
        links.forEach(element => {
            element.classList.add('tap_activated');
            element.removeEventListener('tap', clickMe);
            element.addEventListener('tap', clickMe);
        });
        const checkboxes = document.querySelectorAll(".form_element__input-checkbox:not(.tap_activated)");
        checkboxes.forEach(checkbox => {
            const label = checkbox.closest(".form_element__label");
            checkbox.classList.add('tap_activated');

            label.querySelectorAll('span').forEach(span => {
                span.addEventListener('tap', () => {
                });
            });
            label.addEventListener('tap', clickMe);
        });

    }, 700);
}


//automatically log out if no click for an hour
// noinspection JSUnusedLocalSymbols
document.addEventListener("click", function (event) {
    //console.log("Refreshing logout timeout");
    if (!!window.lastClickTimeout) {
        clearTimeout(window.lastClickTimeout);
    }
    window.lastClickTimeout = setTimeout(async function () {
        const response = await fetch('/login/logout?embedded=true');
        window.sharedState.loginStatus = await response.json();
    }, 3600000);

    const clickedElement = event.target;
    const tooltipWasClicked = clickedElement?.offsetParent?.classList.contains('tooltip');
    if (!tooltipWasClicked) {
        const activeTooltips = document.querySelectorAll('.tooltip-active');
        activeTooltips.forEach(elem => {
            elem.classList.remove('tooltip-active');
        });
    }
});

setupSourceSearcherComponent();

function setupNavigationBubbleScroll() {
    const media_type_selector = document.querySelector("#media_type_selector");
    if (!!media_type_selector) {
        media_type_selector.classList.add("os-host-flexbox");
        const navBubbleOverlayScrollbar = OverlayScrollbars(media_type_selector, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });
        const selected = media_type_selector.querySelector(".media_type_selector__media_type.selected");
        if (selected) setTimeout(() => {
            // console.log("scrolling selected...")
            //selected.scrollIntoView({ block: 'end', inline: 'center' });
            if (navBubbleOverlayScrollbar) navBubbleOverlayScrollbar.scroll({
                el: selected,
                scroll: {y: "never", x: "ifneeded"}
            });
        }, 100);
    }
}

function setupNavigationBubbleMessage() {
    const browsePage = !!document.querySelector(".browse");
    if (!browsePage) {
        const media_type_discover_msg = document.querySelector("#media_type_discover_msg");
        const media_types = document.querySelectorAll(".media_type_selector__media_type");
        let timer;
        if (!!media_type_discover_msg && !!media_types) {
            media_types.forEach(media_type => {
                media_type.addEventListener('mouseenter', () => {
                    clearTimeout(timer);
                    media_type_discover_msg.style.opacity = "1";
                });
                media_type.addEventListener('mouseout', () => {
                    timer = setTimeout(function () {
                        media_type_discover_msg.style.opacity = "0";
                    }, 500);
                });
            });
        }
    }
}

function openDocumentModalIfNeeded(urlParams) {
    //check if we need to open a document from url
    if (urlParams.has('document')) {
        const documentsToOpen = urlParams.getAll('document');
        let documentToOpen;
        if (documentsToOpen.length > 1) {
            openMultipleArticleModal(documentsToOpen).then();
        } else {
            documentToOpen = documentsToOpen[0];
            if (!!documentToOpen) {
                const articles = document.querySelectorAll('article');

                if (!!articles) {
                    const getIdFromArticleElement = function (articleIdElement) {
                        return articleIdElement.substr(6);
                    };

                    let documentIdList = Array.from(articles).map(element => getIdFromArticleElement(element.getAttribute("id")));
                    window.sharedState.fullArticleModalList = documentIdList;
                    window.sharedState.documentListOffset = window.sharedState.offset;
                    window.fetchHigherDocumentIds = fetchHigherResultListDocumentIds;
                    window.fetchLowerDocumentIds = fetchLowerResultListDocumentIds;

                    const pushHistory = true;
                    if (documentIdList.indexOf(documentToOpen) < 0) {
                        if (window.sharedState.offset > 0) {
                            //try getting lower ids up to current offset
                            fetchLowerResultListDocumentIds(window.sharedState.offset).finally(() => {
                                if (documentIdList.indexOf(documentToOpen) < 0) {
                                    //still not in list - try getting higher ids
                                    fetchHigherResultListDocumentIds(200).finally(() => {
                                        //open in any case
                                        openFullArticleModal(documentToOpen, pushHistory).then();
                                    });
                                } else {
                                    openFullArticleModal(documentToOpen, pushHistory).then();
                                }
                            });
                        } else {
                            fetchHigherResultListDocumentIds(200).finally(() => {
                                //open in any case
                                openFullArticleModal(documentToOpen, pushHistory).then();
                            });
                        }
                    } else {
                        openFullArticleModal(documentToOpen, pushHistory).then();
                    }
                }
            }
        }
    }
}

function openRegistrationModalIfNeeded(urlParams) {
    if (urlParams.has('registrationMessage')) {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div class='enter'><div class='enter__header'>" + i18n.t("user.registration.header") + "</div><div class='enter__content'>" + i18n.t(urlParams.get("registrationMessage"), urlParams.get("registrationMessage")) + "</div></div>";
        openTransparentPopupModal(() => {
            changeHistory({registrationMessage: undefined}, false);
        });
    }
}

function openLoginModalIfNeeded(urlParams) {
    if (urlParams.has('targetUrl')) {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        let displayReason;
        let showFurtherInfo = true;
        if (urlParams.has('displayReason')) {
            displayReason = urlParams.get('displayReason');
            showFurtherInfo = false;
        } else {
            displayReason = "genios.login.reasons.follow";
        }
        setupLoginComponent("#embedded_login", displayReason, function () {
            window.location.assign(urlParams.get("targetUrl"));
        }, showFurtherInfo);
    }
}

const setUpMode = () => {
    const mode = localStorage.getItem('mode') === "dark";
    if (mode)
        document.body.classList.add('dark-theme');
};

const eBibLogin = (urlParams) => { //entry point ebib login page
    setupLoginComponent("#ebib_login", "genios.login.reasons.login", function () { window.location.reload() }, false, "ebib_login_form");
};

function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        callbackFunc();
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc);
    } else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callbackFunc();
            }
        });
    }
}

const scrollHandler = () => {
    if (document.activeElement.id === "search_subject") {
        document.activeElement.blur();
    }
};


ready(async function () {
    await setupPaymentFormComponent();
    //check if we should open a preview on page load
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('preview')) {
        const previewToOpen = urlParams.get('preview');
        if (!!previewToOpen) {
            setTimeout(function () {
                openPreview(previewToOpen, false);
            }, 2000);
        }
    }
    eBibLogin(urlParams)
    openDocumentModalIfNeeded(urlParams);
    openRegistrationModalIfNeeded(urlParams);
    openLoginModalIfNeeded(urlParams);
    setUpMode();
    updatePrices();
    styleSidenavPosition();
    await setupResizeHandler();
    setupHeaderNavigationButton();
    closeAllPreviews();
    setupPreviewFunctionality();
    setupSearchPagingHandlers();
    setupBrowsePagingFunctionality();
    horizontalScrollRecommendations();

    if (!browser().mobile) {
        window.pageOverlayScrollbar = OverlayScrollbars(document.body, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });
    } else {
        // window.removeEventListener("scroll", scrollHandler);
        // window.addEventListener("scroll", scrollHandler);
    }

    setupResultVueComponents();
    // setupBrowseDropdownHandler();
    setupChangeViewHandlers();
    setupCheckDocumentHandlers();
    changeSortingOrderHistory(); //searchResult only
    setupFullDocumentFunctionality();
    setupAttachmentDownloadHandler();
    setupEbookAttachmentDownloadHandler();
    setupMiniPopup(document.body);
    setupRemoteAgencyDocDownloadButton(document.body);
    setupNavigationBubbleScroll();
    setupNavigationBubbleMessage();
    checkStoredSelectedItems();
    watchlistAddAllButtons();
    setupSendEmailButtons();
    setupFidoComponent();
    renderVideo();

    setupCompanyNotificationButton(document.querySelector('#company_notification_button'));

    openQueryPopup();

    const queryPopupId = urlParams.get('queryPopupId');
    await setupFullDocumentComponent(queryPopupId);

    if (window.sharedState.page === 'document') {
        const documentId = window.sharedState.urlTopLevelNavigation;
        setupEPaperDownloadButtonComponent(document.body);
        const tableOfContentsOrText = document.querySelector(".table_of_contents_or_text");
        if (!!tableOfContentsOrText) {
            const sourceShortcut = tableOfContentsOrText.getAttribute("sourceShortcut");
            const issueId = tableOfContentsOrText.getAttribute("issueId");
            const tableOfContentsContainer = document.querySelector(".table_of_contents");
            if (!tableOfContentsContainer) {
                fetchTableOfContents(sourceShortcut, tableOfContentsOrText, documentId, issueId).then();
            }
        }
    } else if (window.sharedState.page === 'browse') {
        setupArchiveMonthPickerComponent();
        setupSourceFilterComponent();
        autoSelectedFilteredSource();
        setupFirstLoadModuleOfMySourcesFunctionality().then();
        OverlayScrollbars(document.querySelector('.browse_page__title__dropdown__list__flex'), {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });
    }

    setupShareButton(document);

    setupNotImplementedAlert(document);
    if (window.sharedState.loginStatus.supportsWebUser) {
        setupWebUserRegistrationFormComponent();
    }
});



