export const meinGeniosMenuItems = [
    {
        title: 'Account',
        subItems: [
            {
                title: 'Registrierungsdaten',
                to: '/meinGenios/account/registrationData'
            },
            {
                title: 'Kontübersicht',
                to: '/meinGenios/account/accountOverview'
            },
            {
                title: 'Zahlungsdaten',
                to: '/meinGenios/account/paymentData'
            }
        ],
        expanded: false,
    },
    {
        title: 'Grundeinstellungen',
        to: "/meinGenios/settings"
    },
    {
        title: 'Merklisten',
        to: "/meinGenios/bookmark"
    },
    {
        title: 'Suchverlauf',
        to: "/meinGenios/searchHistory"
    },
    {
        title: 'Documentverlauf',
        to: "/meinGenios/documentHistory"
    },
    {
        title: 'Monitoring & TrendReports',
        to: "/meinGenios/monitoringTrendReports"
    },
    {
        title: 'Meine Quellen',
        to: "/meinGenios/sources"
    },
    {
        title: 'Affiliate-Programm',
        to: "/meinGenios/partnerProgram"
    }
];
