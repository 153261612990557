<template>
    <div class="source" v-click-outside="onClickOutside">
        <div class="source__desktop" v-if="screenWidth > 680">
            <source-filter-element
                    v-for="filter in filterList"
                    v-if="filter.active"
                    :key="filter.name"
                    :element="filter"
                    :show-form="showForm"
                    :shared-state="sharedState"
                    @update-show-form="showForm = $event"
            />
        </div>

        <!--    only for or less $breakpoints: small  -->

        <div class="source__mobile">
            <Button
                    variant="secondary"
                    :text="$t('genios.filter')"
                    icon="filter-outline"
                    class="source__button"
                    position="left"
                    :toggle-text="false"
                    @click="toggleMobileFilters"
            />
            <div v-show="showForm === 'filters'" class="source__mobile-popup" @click="redirectFocus" tabindex="0"
                 ref="myPopup">
                <div class="source__mobile-popup__container" tabindex="0" ref="filter">
                    <mobile-source-filter-element
                            v-for="filter in filterList"
                            v-if="filter.active"
                            :key="filter.name"
                            :shared-state="sharedState"
                            :element="filter"
                            :show-form="showMobileForm"
                            @update-show-form="showMobileForm = $event"
                    />
                    <div class="source__mobile-action-bar">
                        <Button
                                variant="secondary"
                                :text="$t('genios.closeWindow')"
                                :toggle-text="false"
                                @click="closeFilterPopup"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!------------------------------------->

    </div>
</template>

<script>
import Vue from "vue";
import {Plugin} from "vue-fragment"
import vClickOutside from "v-click-outside";

import _ from "lodash"

import SourceFilterElement from "./SourceFilterElement.vue";
import Button from "../../styled/Button.vue";
import eventBus from "../../../../eventBus";
import MobileSourceFilterElement from "./MobileSourceFilterElement.vue";

Vue.use(Plugin)

export default {
    name: "SourceFilterComponent",
    components: {MobileSourceFilterElement, SourceFilterElement, Button},
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        sharedState: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showForm: '',
            showMobileForm: '',
            filterList: this.generateFilterList(),
            screenWidth: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResizeHandler)
    },
    methods: {
        onResizeHandler: _.debounce(function (event) {
            this.screenWidth = event?.currentTarget?.innerWidth || event.target.innerWidth

            if (this.screenWidth > 680 && this.showForm === 'filters') {
                this.showForm = ""
                const el = Array.from(document.body.children).find(item => item.className === "source__mobile-popup") || null
                if (!!el) {
                    document.body.removeChild(el)
                }
            }
        }, 500),
        onClickOutside() {
            if (this.showForm !== 'filters')
                this.showForm = ''
        },
        generateFilterList() {
            let res = this.sharedState.browseFilters || []
            const sessionBrowseFilters = this.sharedState.updatedBrowseFilters || []

            if (!_.isEqual(sessionBrowseFilters, res)) {
                res = this.updateSession(sessionBrowseFilters, res)
            }

            return res
        },
        updateSession(session, newValue) {
            session.forEach(sessionItem => {
                const currentElement = newValue.find(item => item.name === sessionItem.name)

                for (const sessionKey in sessionItem.filterList) {

                    const currentSessionItem = sessionItem.filterList[sessionKey]
                    const valueToUpdate = currentElement.filterList[sessionKey]

                    if (!!valueToUpdate) {
                        currentSessionItem.count = valueToUpdate.count
                    } else {
                        currentSessionItem.count = 0
                    }
                }

            })

            return session
        },
        //mobile section
        toggleMobileFilters() {
            const myEl = this.$refs.myPopup

            this.showForm = "filters"

            const header = document.querySelector(".header.sticky")
            this.$refs.filter.style.height = `calc(100% - ${header.clientHeight + 1 + "px"}`

            document.body.append(myEl)

            this.$nextTick(() => {
                myEl.children[0].focus()
            })
        },
        closeFilterPopup() {
            document.body.removeChild(this.$refs.myPopup)
        },
        redirectFocus(event) {
            event.stopPropagation()

            event.target.focus()
        }
    },
    watch: {
        "sharedState.updatedBrowseFilters"(newValue, oldValue) {
            if (!(_.isEqual(newValue, oldValue))) {
                const arr = this.updateSession(this.filterList, newValue)

                this.filterList = _.cloneDeep(arr)
            }

            eventBus.$emit("update-loader", false)
        }
    }
}
</script>
