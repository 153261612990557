<script>
import axios from "axios";

export default {
  name: "SignupContentComponent",
  props: {
    solutionName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      signupContent : ""
    };
  },
  created() {
    this.fetchContent()
  },
  methods: {
    async fetchContent() {
      const response = await axios.get(`/api/signupContent?solutionName=${this.solutionName}`);
      if(response.status === 200){
        this.signupContent = response.data;
      }
    }
  }
}
</script>

<template>
  <transition name="fade">
    <template v-if="signupContent">
      <div id="signup" v-html="signupContent" class="further-info-content" ></div>
    </template>
  </transition>
</template>

<style>
.further-info-content {
  transition: opacity 0.3s ease;
}

#signup li {
  justify-content: flex-start !important;
}

</style>