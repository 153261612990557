<template>
  <div
      :class="`button__${variant}${variant !== 'icon' && !text || width <= 680 ?` icon-null`:''}${position?` ${position}`:''}${decoration?` decoration`:''}`"
      :id="id"
      :style="cssProps"
      @click="click($event)"
  >
    <span
        v-if="text"
        :class="toggleText ? 'btn-text' : ''"
        :style="textStyle ? `font-weight: ${textStyle};` : ''"
    >
      {{ text }}
    </span>
    <ButtonLoader
        v-if="loading"
        :size="!icon ? $el.clientHeight - 6 : $el.clientHeight + 4"
    />
    <span :style="`${(width <= 680 && !icon) ?'display: none': ''}`" v-else-if="icon">
      <ion-icon :name="icon[0] !== '/' && icon" :src="icon[0] === '/' && icon"/>
    </span>
    <slot v-else/>
  </div>
</template>

<script>
import ButtonLoader from "./ButtonLoader.vue";

export default {
  name: "ButtonDiv",
  components: {ButtonLoader},
  data() {
    return {
      width: window.innerWidth
    }
  },
  props: {
    loading: {
      type: Boolean
    },
    text: {
      type: String,
      default: null
    },
    ariaLabel: {
      type: String,
      required: false
    },
    id: [String, Number],
    icon: {
      type: [Object, String],
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    position: {
      type: String,
      default: null
    },
    height: {
      type: [String, Number]
    },
    border: {
      type: [String]
    },
    decoration: [String],
    textStyle: {
      type: [String, Number],
      default: 600
    },
    color: {
      type: [String],
      default: null
    },
    toggleText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cssProps() {
      if (this.border && this.height && this.color) return `height: ${this.height}px;border: ${this.border}; color: ${this.color}`
      else if (this.height && this.color) return `height: ${this.height}px';color: ${this.color}`
      else if (this.border && this.color) return `border: ${this.border};color: ${this.color}`
      else if (this.height) return `height: ${this.height}px`
      else if (this.border) return `border: ${this.border}`
      else if (this.color) return `color: ${this.border}`
      else return ''
    },
    slots() {
      if (this.$slots.default) return this.$slots.default
      return []
    }
  },
  created() {
    window.addEventListener('resize', this.updateWidth)
  },
  methods: {
    click(event) {
      this.$emit('click', event)
    },
    updateWidth() {
      this.width = window.innerWidth
    },
  }
}
</script>

<style scoped>
.icon-null {
  padding: 5px;
}

.left {
  flex-direction: row-reverse;
}

.right {
  flex-direction: row;
}

.decoration:hover {
  text-decoration: underline;
}
</style>