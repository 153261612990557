<template>
  <overlay-scrollbars
      v-if="showOverlayScrollbarSection"
      class="search_sources"
      :options="{
    scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
    overflowBehavior: { y: 'hidden'}
     }"
  >
    <div id="sources-menu" class="topmenu " role="navigation">
      <span class="topmenu__item" v-for="source in sharedState.navigationTree" :class="matching(source.urlSafeName)">
        <navigation-selector-item
            :name="source.name"
            :url-safe-name="source.urlSafeName"
            :count="textIdCountMap[source.textId]"
            :path-root="pathRoot"
            :nav-root="navRoot"
            :request-text="requestText"
            :date-filter="sharedState.activeFilters.date"
        />
      </span>
    </div>
  </overlay-scrollbars>
</template>

<script>
import NavigationSelectorItem from "./NavigationSelectorItem.vue";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';

export default {
  name: "NavigationSelector",
  components: {NavigationSelectorItem, 'overlay-scrollbars': OverlayScrollbarsComponent},
  props: {
    sharedState: Object
  },
  data() {
    return {
      message: '',
      showOverlayScrollbarSection: false
    };
  },
  mounted() {
    //This is only because overlayscrollbars take ages to initialise
    this.showOverlayScrollbarSection = true;
  },
  computed: {
    pathRoot: function () {
      return this.sharedState.pathRoot;
    },
    navRoot: function () {
      return this.sharedState.urlTopLevelNavigation;
    },
    urlMediaType: function () {
      const urlSecondLevel = this.sharedState.urlSecondLevel;
      if (!urlSecondLevel)
        return this.sharedState.urlTopLevelNavigation;
      return urlSecondLevel;
    },
    requestText: function () {
      return this.sharedState.queryString;
    },
    textIdCountMap: function () {
      return this.sharedState.textIdCountMap;
    }
  },
  updated() {
    this.$nextTick(function () {
      document.querySelector('.selected-item')?.scrollIntoView({block: 'nearest'})
    });
  },
  methods: {
    matching: function (source) {
      return source === this.urlMediaType ? 'selected-item' : '';
    }
  }
};
</script>
<!--<style src="overlayscrollbars/css/OverlayScrollbars.css"/>-->
<style scoped>
</style>
