<template>
  <div class="main-container">
    <NavigationDrawerComponent
        title="Menu"
        :menu-items="meinGeniosMenuItems"
        class="nav"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import NavigationDrawerComponent from '../styled/NavigationDrawerComponent.vue';
import {meinGeniosMenuItems} from '../../../functions/constants/menuItem';

export default {
  name: 'MeinGeniosMain',
  components: {
    NavigationDrawerComponent,
  },
  props: {
    sharedState: Object
  },
  data() {
    return {
      isLoading: false,
      meinGeniosMenuItems,
    };
  },
}
</script>

<style scoped>
.main-container {
  display: flex;
  align-items: flex-start;
}

.nav {
  margin-top: 26px;
}
</style>
