<template>
  <button
      class="payment__methods__body__button"
      :class="activeBtn === name ? 'primary_border' : ''"
      @click="clickHandler($event)"
      :name="name">
        <span>
          <img v-for="icon in icons" :src="icon" alt="icon">
        </span>

  </button>
</template>

<script>
export default {
  name: "PaymentButton",
  props: {
    activeBtn: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icons: {
      type: Array,
      required: false
    },
    text: {
      type: String,
      required: false
    }
  },
  methods: {
    clickHandler(event){
      this.$emit('execute', event)
    }
  }
}
</script>