<template>
  <div
      class="multi_document_container"
      ref="documentContainer"
  >
    <div class="multi_document_container__overlay"
         v-if="!loaded"
    ></div>
    <div class="document__navigation">
      <div class="tooltip tooltip-icon">
        <button
            type="button"
            id="print_multi_document"
            aria-label="print"
            @click="shareMe"
            class="button__icon"
            :data-legacy-doc-id="legacyDocIds"
            :data-print="true"
        >
          <ion-icon name="print-outline"></ion-icon>
        </button>

        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.print")}}</div>
      </div>

      <div class="tooltip tooltip-icon">
        <button
            type="button"
            id="pdf_multi_document"
            aria-label="open pdf"
            :data-legacy-doc-id="legacyDocIds"
            @click="shareMe"
            class="button__icon">
          <ion-icon src="/icons/custom/PDF-document-outline.svg"></ion-icon>
        </button>
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.openPdf")}}</div>
      </div>
      <div class="grow"></div>
      <div>
        <button
            type="button"
            id="closer"
            aria-label="close"
            @click="closeMe"
            class="button__icon">
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div
        class="multi_document_container__title_list"
        :class="loaded ? '':'multi_document_container__title_list--hidden'"
        v-if="articles.length > 1"
    >
      <div class="multi_document_container__title_list__header">{{ $t("genios.multiDocument.titleListHeader") }}</div>
      <div
          v-for="(article, index) in articles"
          class="multi_document_container__title_list__item"
          v-if="showMore || index < 5"
      >
        <div class="multi_document_container__title_list__item__source">
          <img alt="" width="16px" height="16px" :src="'/resource/logos-tiny/' + article.dbShortcut.toLowerCase() + '.png'">
          <span>{{article.sourceName}}</span>
        </div>
        <a :href="'#article_' + article.id">{{ article.title }}</a>
      </div>
      <button
          v-if="articles.length > 5"
          type="button"
          class="multi_document_container__title_list__show_more_less"
          @click="showMore = !showMore"
      >
        <span v-if="showMore">{{$t("genios.showLess")}}</span>
        <span v-else>{{$t("genios.showMore")}}</span>
        &ensp;
        <span class="filter__show-more__toggle">
          <ion-icon src="/icons/custom/chevron-down-outline.svg"  :class="showMore?'rotate-180':''"></ion-icon>
        </span>
      </button>
    </div>
    <div
        v-for="article in articles"
        :id="'article_' + article.id"
        class="multi_document_container__single_article"
        :data-document-title="article.title"
        :data-document-id="article.id"
        v-html="article.html"
    >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {closeFullPopupModal} from "../../functions/closing";
import {checkShowMoreShowLessSections} from "../../functions/checks";
import {setupMiniPopup, setupRemoteAgencyDocDownloadButton} from "../../functions/setups";
import {setupEPaperDownloadButtonComponent, setupFidoComponent} from "../../functions/setups/vue-components";
import {setupNotImplementedAlert} from "../../functions/temp";
import {setupAttachmentDownloadHandler, setupEbookAttachmentDownloadHandler} from "../../functions/setups/handlers";
import updatePrices from "../../functions/updatePrices";
import {setupShareButton, shareHandler} from "../../functions/components/shareButton";
import {fetchTableOfContents} from "../../functions/fetching";
import {watchlistAddAllButtons} from "../../functions/components/watchlist";
import {setupSendEmailButtons} from "../../functions/components/sendEmailComponent";

export default {
  name: "MultiArticle",
  props: {
    articleIds: Array,
  },
  data() {
    return {
      articles: this.articleIds.map(id => {
        return {
          id: id,
          dbShortcut: id.split('__')[0],
          sourceName: "--",
          title: "--",
          html: "<div class='loading' style='width:100%;height:240px'><div class='loader'></div></div>"
        };
      }),
      legacyDocIds: "",
      showMore: false,
      loaded: false
    };
  },
  async mounted() {
    const documentContainer = this.$refs.documentContainer;
    const promises = [];

    this.articles.forEach(article => {
      const fetchUrl = '/document/' + article.id + '?embed=true';
      let merged = {};
      promises.push(axios.post(fetchUrl, merged)
          .then(response => {
            //TODO: we have function for parsing string to HtmlObj
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(response.data, 'text/html');

            const container = htmlDoc.querySelector('.document_and_metadata_container');
            article.title = container?.dataset?.documentTitle;
            article.sourceName = container?.dataset?.sourceName;
            //FW does not want right-hand side shown for multi-document view ¯\_(ツ)_/¯
            //remove line below of we want to show it again
            container.querySelector('.document-metadata')?.remove();
            article.html = htmlDoc.documentElement.outerHTML;
          })
          .catch(error => {
                article.html = "--";
                console.log(error);
                console.dir(error)
              }
          ));
    });

    Promise.all(promises)
        .then(() => {
          this.extractLegacyDocIds();
          this.setupHandlers();
          this.loaded = true;
        });

    documentContainer
        .parentNode
        .appendChild(
            document.querySelector(".footer").cloneNode(true)
        );
  },
  methods: {
    closeMe() {
      closeFullPopupModal(true);
    },
    shareMe(event) {
      shareHandler(event);
    },
    extractLegacyDocIds() {
      const documentContainer = this.$refs.documentContainer;
      const allLegacyDocIds = documentContainer.querySelectorAll('.document_and_metadata_container');
      let legacyDocIdList = [];
      allLegacyDocIds.forEach(element => legacyDocIdList.push(element.dataset.legacyDocId));
      this.legacyDocIds = [...new Set(legacyDocIdList)].filter(item => !!item).join();
    },
    setupHandlers() {
      const documentContainer = this.$refs.documentContainer;
      checkShowMoreShowLessSections(documentContainer);

      const tableOfContentsOrTextSections = documentContainer.querySelectorAll(".table_of_contents_or_text");
      tableOfContentsOrTextSections.forEach(tableOfContentsOrText => {
        const sourceShortcut = tableOfContentsOrText.getAttribute("sourceShortcut");
        const issueId = tableOfContentsOrText.getAttribute("issueId");
        const tableOfContentsContainer = tableOfContentsOrText.querySelector(".table_of_contents");
        if (!tableOfContentsContainer) {
          const documentId = tableOfContentsOrText.dataset.documentId;
          fetchTableOfContents(sourceShortcut, tableOfContentsOrText, documentId, issueId).then(() => {
          });
        }
      });

      setupMiniPopup(documentContainer);
      setupEPaperDownloadButtonComponent(documentContainer);
      setupRemoteAgencyDocDownloadButton(documentContainer);

      setupNotImplementedAlert(documentContainer);

      setupAttachmentDownloadHandler(documentContainer);
      setupEbookAttachmentDownloadHandler(documentContainer);
      updatePrices(documentContainer);
      watchlistAddAllButtons();
      setupShareButton(documentContainer);
      setupSendEmailButtons(this.$el);
      setupFidoComponent();
    }
  }
};
</script>
