<template>
  <div :key="video.partialId">
    <p><b>{{ video.title }}</b></p>
    <div v-if="isScript" class="video__frame" :class="database === 'STUF' ? 'studyFlix': ''">
      <component
          is="script"
          ref="videoScript"
          :src="script.src"
          :data-id="script.dataset.id"
          :data-partner="script.dataset.partner"
          async
          defer
      />
    </div>
    <iframe v-else :src="video.url" class="video__frame"/>
  </div>
</template>

<script>
import {convertStringToHtml} from "../../../functions/utils/string_utils";

export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: Object,
      required: true
    },
    database: String
  },
  computed: {
    isScript() {
      if (!!this.video.url) {
        const url = this.video.url.toString()
        return url.startsWith("<")
      }
      return false
    },
    script() {
      return convertStringToHtml(this.video.url, {script: true})[0]
    }
  }
}
</script>