<template>
  <div class="monitoring_form__layout_preview_main_container">
    <div class="popup-arrow-up" v-if="showLayoutPreview">

    </div>
    <div class="monitoring_form__layout_preview " v-if="showLayoutPreview">
      <div class="monitoring_form__layout_preview__left">
        <div class="monitoring_form__layout_preview__title"> {{ $t('genios.monitoring.layout.newsletter') }}:</div>
        <div class="monitoring_form__layout_preview__description">
          {{ $t('genios.monitoring.layout.newsletterDescription') }}
        </div>
        <div class="monitoring_form__layout_preview__image_container">
          <img src="/figures/monitoring/newsletter-view.png" class="monitoring_form__layout_preview__fit_image">
        </div>
      </div>
      <div class="monitoring_form__layout_preview__right">
        <div class="monitoring_form__layout_preview__title">{{ $t('genios.monitoring.layout.list') }}:</div>
        <div class="monitoring_form__layout_preview__description">
          {{ $t('genios.monitoring.layout.listDescription') }}
        </div>
        <div class="monitoring_form__layout_preview__image_container">
          <img src="/figures/monitoring/list-view.png" class="monitoring_form__layout_preview__fit_image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LayoutPreview",
  props: ["showLayoutPreview"],
}
</script>