<template>
  <button type="button" class="filter__show-more"
       v-if="length > 5"
       v-on:click="toggleShowMore()"
  >
    <div class="filter__show-more__name" v-if="showMore">
      {{ $t("genios.showLess") }}
    </div>
    <div class="filter__show-more__name" v-else>
      {{ $t("genios.showMore") }}
    </div>
    <div class="filter__show-more__toggle">
      <ion-icon src="/icons/custom/chevron-down-outline.svg"  :class="showMore?'rotate-180':''"></ion-icon>
    </div>
  </button>
</template>

<script>
export default {
  name: "ShowMore",
  props: {
    length: Number,
    showMore: Boolean
  },
  methods: {
    toggleShowMore: function () {
      this.$emit('toggle-show-more');
    }
  }
}
</script>

<style scoped>

</style>
