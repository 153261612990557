<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import enMessages from '../../../../locale/messages/en.json'
import deMessages from '../../../../locale/messages/de.json'

import enErrors from "../../../../locale/messages/validation/en_errors.json"
import deErrors from "../../../../locale/messages/validation/de_errors.json"

Vue.use(VueI18n)

const enTranslation = {
  ...enMessages, ...enErrors
}

const deTranslation = {
  ...deMessages,
  ...deErrors
}

const messages = {
  'de': deTranslation,
  'de-DE': deTranslation,
  'en': enTranslation,
  'en-US': enTranslation,
  'en-GB': enTranslation
}

const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'de-DE',
  messages
})

export default i18n
</script>
