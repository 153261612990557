<template>
  <label class="radio-container" :class="radioPosition && `radio-${radioPosition}`">
    {{ element.fieldLabel }}
    <input
        :id="element.id"
        class="form_element__input-radio"
        type="radio"
        name="radio-group"
        @change="setValue"
        :value="element.value"
    />
    <ion-icon v-if="isChecked" name="radio-button-on-outline"/>
    <ion-icon v-else name="radio-button-off-outline"/>
  </label>
</template>

<script>
export default {
  props: {
    element: Object,
    groupValue: [String, Number],
    radioPosition: {
      type: String,
      default: 'left'
    }
  },
  data(){
    return {
    }
  },
  computed: {
    isChecked() {
      return this.groupValue === this.element.value
    }
  },
  methods: {
    setValue() {
      this.$emit('modified', this.element.value)
    }
  }
}
</script>

<style>
.radio-left {
  flex-direction: row-reverse;
  padding-right: 10px;
}

.radio-right {
  flex-direction: row;
  padding-left: 10px;
}
</style>
