<template>
  <fragment>
    <div class="source__spinner" v-if="loading">
      <spinner/>
    </div>
    <text-form-element
        v-if="filter.filterList.length > 5 && filter.search"
        :passed-value="searchForm.value"
        :element="searchForm"
        :focus="focus"
        :prefix-icon-name="searchIcon"
        @modified="updateForm({...searchForm, value: $event});valueForSearchIcon = $event;setValue($event);"
        @focus="focus = $event"
        @keyup.enter="onEnter"
    />
    <div is="transition-group" name="flip-list" class="source__checkbox-form" v-if="code404 === ''">
      <checkbox-form-element
          v-for="(option, index) in options"
          :class="{empty: option.count <= 0, checked: option.value}"
          :key="option.id"
          :element="option"
          :ref="'option' + index"
          :passedValue="option.value"
          :focus="focus"
          :data-count="option.count"
          tabindex="0"
          @focus="focus = $event"
          @modified="choose($event, option)"
      />
    </div>

    <p class="source__404" v-else v-html="$t(code404)"/>

    <div class="source__desktop__action-bar">
      <div
          v-show="currentPage !== pageQuantity && code404 === ''"
          @click="loadMore"
          @keyup.enter="loadMore"
          class="source__show-more"
          tabindex="0"
      >
        <span>{{ $t("genios.showMore") }}</span>
        <ion-icon src="/icons/custom/chevron-down-outline.svg"/>
      </div>
      <span @click="$emit('close-window')" class="source__close-btn">{{$t("genios.closeWindow")}}</span>
    </div>
  </fragment>
</template>

<script>
import Spinner from "../../styled/Spinner.vue";
import TextFormElement from "../../FormElements/TextFormElement.vue";
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";
import {formMixin} from "../../../../functions/mixins/form";
import _ from "lodash";
import i18n from "../../plugins/Translations.vue";
import {toggleBrowseResultActiveFilter} from "../../../../functions/components/filters";
import {getSuggestions} from "../../../../functions/services/searchApi";
import eventBus from "../../../../eventBus";

export default {
  name: "SourceContent",
  components: {CheckboxFormElement, TextFormElement, Spinner},
  mixins: [formMixin],
  props: {
    filter: {
      type: Object,
      required: true
    },
    sharedState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      focus: null,
      form: {},
      loading: false,
      valueForSearchIcon: '',
      searchForm: this.generateField({
        id: `sourceSearchForm`,
        field: `sourceSearchForm`,
        fieldLabel: i18n.t("source-filters.textInputPlaceholder", {filterName: i18n.t(this.filter.title)}),
        fieldType: "text",
        value: ""
      }),
      debounceValue: '',
      options: [],
      currentPage: 0,
      pageQuantity: 0,
      pages: [],
      code404: ""
    }
  },
  computed: {
    searchIcon() {
      const hasValue = !!this.valueForSearchIcon
      return hasValue ? '' : 'search-outline'
    }
  },
  mounted() {
    this.initOptions()
    eventBus.$on("update-loader", (value) => {
      this.loading = value
    })
    eventBus.$on("update-active-filter", obj => {
      if (obj.type === this.filter.name) {
        const activeFilters = window.sharedState.browseActiveFilters[this.filter.name]

        this.options = this.options.map(item => this.updateActiveFilter(activeFilters, item, obj))

        this.$nextTick(async () => {
          this.pages = _.chunk(_.flatten(this.pages).map(item => this.updateActiveFilter(activeFilters, item, obj)), this.filter.maxNumber)
        })
      }
      this.loading = false
    })
  },
  methods: {
    setValue: _.debounce(function (event) {
      this.debounceValue = event
    }, 500),
    initOptions() {
      const pages = _.chunk(this.filter.filterList, this.filter.maxNumber)
      this.options = pages[this.currentPage] || []
      this.pageQuantity = pages.length - 1
      this.pages = [...pages]
    },
    loadMore(event) {
      event.preventDefault()
      this.currentPage += 1
      this.options = [...this.options, ...this.pages[this.currentPage]]

      //====== scrolling to the filter ================
      const firstEl = this.pages[this.currentPage][0]
      const indexOfFirstEl = this.options.findIndex(item => item.id === firstEl.id)
      this.$nextTick(() => {
        const elToScroll = this.$refs[`option` + indexOfFirstEl][0]
        this.scrollToElement(elToScroll)
      })
      //=================================================
    },
    scrollToElement(elToScroll) {
      let parentScrollTop = this.$el.parentNode.scrollTop
      const scrollInterval = setInterval(() => {

        if (parentScrollTop < elToScroll.$el.offsetTop - 110) {
          parentScrollTop += 50
          this.$el.parentNode.scrollTop = parentScrollTop
        } else {
          clearInterval(scrollInterval)
        }
      }, 50)
    },
    async searchSources(value) {
      this.loading = true
      this.code404 = ""
      try {
        const data = await getSuggestions(value, this.filter.name)
        if (Object.keys(data).length < 1) {
          this.code404 = "general.404"
          return
        }
        const dataArr = this.convertObjToArray(data)

        this.currentPage = 0
        let allOptions = [...dataArr]
        allOptions = _.chunk(allOptions, this.filter.maxNumber)

        this.options = allOptions[this.currentPage]
        this.pageQuantity = allOptions.length - 1
        this.pages = [...allOptions]
      } catch (e) {
        console.log("searchSources:error => ", e)
        console.dir(e)
      } finally {
        this.loading = false
      }
    },
    choose(value, option) {
      this.loading = true
      option.value = value

      if (option.value)
        window.sharedState.browseActiveFilters[this.filter.name].push(option.id)
      else {
        const currentFilterArr = window.sharedState.browseActiveFilters[this.filter.name]
        window.sharedState.browseActiveFilters[this.filter.name] = currentFilterArr.filter(item => item !== option.id)
      }

      toggleBrowseResultActiveFilter(option.id, this.filter.name)
      // this.debounceValue = ""
      // this.searchForm.value = ""
      // this.valueForSearchIcon = ""
    },
    onEnter() {
      if (this.options.length === 1) {
        const option = this.options[0]
        this.choose(!option.value, option)
      }
    },
    convertObjToArray(obj) {
      const res = [];
      const activeFilters = window.sharedState.browseActiveFilters[this.filter.name]
      for (const key in obj) {
        const objValue = obj[key]
        res.push({
          count: objValue?.count || 0,
          field: key,
          fieldLabel: key,
          id: key,
          value: activeFilters.includes(key)
        })
      }

      const checkedArr = _.sortBy(res.filter(item => item.value), ["value", (el) => el.id.toLowerCase(), "count"])
      const notCheckedArr = _.sortBy(res.filter(item => !item.value), ["value", (el) => el.id.toLowerCase(), "count"])

      return [...checkedArr, ...notCheckedArr]
    },
    updateFilter(activeFilters, item, newValue) {
      const element = newValue.filterListMap[item.id]
      return {
        ...item,
        // value: activeFilters.includes(item.id),
        count: element?.count || 0
      }
    },
    updateActiveFilter(activeFilters, item, obj) {
      return {
        ...item,
        value: item.id === obj.value ? false : item.value
      }
    }
  },
  watch: {
    filter(newValue) {
      const activeFilters = window.sharedState.browseActiveFilters[this.filter.name]

      this.options = this.options.map(item => this.updateFilter(activeFilters, item, newValue))

      this.$nextTick(async () => {
        this.pages = _.chunk(_.flatten(this.pages).map(item => this.updateFilter(activeFilters, item, newValue)), this.filter.maxNumber)
      })

      this.loading = false
    },
    debounceValue(newValue) {
      if (!newValue?.length || newValue.length > 1)
        this.searchSources(newValue)
    }
  }
}
</script>