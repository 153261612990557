<template>
  <mein-genios-template :pageTitle="$t('meinGenios.bookmarks.title')" :page-info="$t('meinGenios.bookmarks.info')">
    <template v-slot:variants>
      <div v-if="bookmarkList.length > 0">
        <DropdownFormElement
            :element="{
            fieldLabel: $t('meinGenios.bookmarks.title'),
            options: bookmarkList
          }"
            :focus="focus"
            :passed-value="bookmarkList[0]"
        />
      </div>
    </template>
    <template v-slot:content>
      <div class="list-view-wrapper" v-if="bookmarksArray.length > 0">
        <div class="list-container list-view-switcher list-view">
          <ArticleElementComponent
              v-for="(item, index) in bookmarksArray"
              :key="item.documentId + index"
              :document="item"
              :index="index.valueOf()"
          />
        </div>
        <pagination-component
            v-if="pagesQuantity > 1"
            :pages="pagesQuantity"
            :selected="currentPage"
            @update-page="currentPage = $event"
        />
      </div>
    </template>
  </mein-genios-template>
</template>

<script>
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import axios from "axios";
import ArticleElement from "../styled/ArticleElementComponent.vue";
import ArticleElementComponent from "../styled/ArticleElementComponent.vue";
import ActionBarComponent from "../ActionBar/ActionBarComponent.vue";
import Spinner from "../styled/Spinner.vue";
import PaginationComponent from "../styled/PaginationComponent.vue";
import MeinGeniosTemplate from "./MeinGeniosTemplate.vue";

export default {
  name: "BookmarkComponent.vue",
  components: {
    MeinGeniosTemplate,
    PaginationComponent,
    Spinner,
    ActionBarComponent,
    ArticleElementComponent,
    ArticleElement,
    DropdownFormElement,
  },
  data() {
    return {
      currentIndex: 0,
      bookmarkList: [],
      bookmarksArray: [],
      focus: null,
      loading: true,
      errorCode: null,
      pagesQuantity: 10,
      currentPage: 1,
      offset: 0,
    }
  },
  mounted() {
    this.loadUsersWatchlistsForBookmark();
  },
  created() {
    this.loadUsersWatchlists();
    this.loadUsersWatchlistsForBookmark();
  },
  methods: {
    loadUsersWatchlists() {
      axios.get("/api/watchlists/all", {}).then(response => {
        if (response.data?.length > 0) {
          this.bookmarkList = response.data.map(item => ({
            text: item.name,
            id: item.id,
            creationDate: item.creationDate
          }));
        }
        this.errorCode = null;
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorLoad";
        console.error("Load-Watchlists " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      });
    },
    loadUsersWatchlistsForBookmark() {
      try {
        axios.get("/api/watchlist_bookmarks/docs", {}).then(response => {
          if (response.data?.length > 0) {
            this.bookmarksArray = response.data.map(item => ({
              ...item,
              isBookmark: true
            }));
            updateMeinGeniosSharedState(this.bookmarksArray.map(item => {
              return {
                documentId: item.documentId,
                legacyDocId: item.legacyDocId,
                database: item.database,
                priceCategory: item.priceCategory,
                infoPrice: item.infoPrice
              }
            }));
          }
          this.errorCode = null;
        })
      } catch (e) {
        this.errorCode = "genios.watchlist.errorLoad";
        console.error("Load-Watchlists " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
