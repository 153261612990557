import {changeHistory} from "../utils/history_utils";
import updateSession from "../updateSession";
import {getAttributeSortBy, getAttributeSortOrder} from "../getters";

export const changeSortingOrderHistory = function () {
    const menuButton = document.getElementById("settings_menu_button")
    if (!!menuButton) {
        //ensure sorting order matches url
        const queryString = window.location.search; //requestText=&partial=false&view=grid&sort=BY_RELEVANCE&order=desc
        const urlParams = new URLSearchParams(queryString);

        const sortValue = urlParams.get('sort') || getAttributeSortBy() || "BY_RELEVANCE"
        const orderValue = urlParams.get('order') || getAttributeSortOrder() || "desc"

        changeHistory({order: orderValue, sort: sortValue}, false);
        updateSession(null, sortValue, orderValue);

        window.sharedState.sort = sortValue;
        window.sharedState.order = orderValue;
    }
}