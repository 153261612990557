<template>
  <div class="payment__template">
    <div class="payment__title">
      {{
        limitUsageStepModel && limitUsageStepModel.headerText ? limitUsageStepModel.headerText : $t('orderingProcess.limitUsage.header')
      }}
    </div>
    <div v-if="code === 'ERROR_INVALID_CAPTCHA'">
      <p class="error">{{ $t("orderingProcess.limitUsage.captcha_error") }}</p>
    </div>
    <captcha-component
        v-if="['REQUEST_CAPTCHA', 'ERROR_INVALID_CAPTCHA'].includes(code)"
        :order-data-id="orderDataId"
    />
    <div class="payment__message" v-else>
      {{
        limitUsageStepModel && limitUsageStepModel.contentText ? limitUsageStepModel.contentText : $t('orderingProcess.limitUsage.content')
      }}
    </div>
    <div class="payment__actions">
      <Button
          type="button"
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
    </div>
  </div>
</template>

<script>
import CaptchaComponent from "./CaptchaComponent.vue";
import Button from "../../styled/Button.vue";

export default {
  name: "LimitUsageFormComponent",
  components: {CaptchaComponent, Button},
  props: {
    limitUsageStepModel: Object,
    code: String,
    orderDataId: [String, Number]
  }
}
</script>
