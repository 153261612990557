<template>
  <a
      class="video__link"
      :href="href"
      :class="!!currentItem.url && currentItem.partialId === item.partialId ? 'selected__link' : ''"
      :data-video-legacy-doc-id="videoLegacyId"
      js-handler="updateVideoPrices"
      @click="updateVideo($event)"
  >
    <div class="video__link__play">
      <button-loader v-if="isLoading" size="32" color="#F98F17"/>
      <ion-icon v-else name="play-circle"/>
      <span>{{ index }}</span>
      <span class="video__link__play__title">{{ item.title }}</span>
    </div>
    <span>{{ item.length }}</span>
  </a>
</template>

<script>

import ButtonLoader from "../styled/ButtonLoader.vue";
import {setupVideoFrame} from "../../../functions/setups";

export default {
  name: "VideoPlayList",
  components: {ButtonLoader},
  props: {
    item: {
      type: Object,
      required: true
    },
    currentItem: {
      type: Object,
      required: true
    },
    documentUrl: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async updateVideo(event) {
      event.preventDefault()
      if (!this.item.url || this.currentItem.partialId !== this.item.partialId) {
        this.isLoading = true
        try {
          const params = {
            clientType: "Beta",
            type: "-1000",
            videoId: this.videoLegacyId,
            documentId: this.documentId
          }
          await setupVideoFrame(params, {forceConfirmPrice: false})
        } catch (e) {
          console.log("VideoPlayList:updateVideo:error => ", e)
          console.dir(e)
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  computed: {
    href() {
      return this.documentUrl + `?video=${this.videoLegacyId}`
    },
    database() {
      return this.documentId.split("__")[0]
    },
    videoLegacyId() {
      return `${this.database}__${this.item.partialId}`
    }
  }
}
</script>

<style scoped>

</style>