<template>
    <div :class="`popup__${variant}`" @keydown.esc="closePopup">
        <p v-if="header" class="my-sources__header">{{ header }}</p>
        <slot/>
    </div>
</template>

<script>
export default {
    name: "StandardPopupComponent",
    props: {
        variant: {
            type: String,
            required: true
        },
        header: {
            type: String,
            required: false
        }
    },
    methods: {
        closePopup() {
            this.$emit("close-popup")
        }
    }
}
</script>