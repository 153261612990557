<template>
  <div class="alternative_article" id="alternative_grid">
    <div class="alternative_article__item">
      <p class="alternative_article__paragraph alternative_article__absolute">{{ docType }}</p>
      <img :src="img" alt="img">
    </div>
    <div class="alternative_article__item">
      <p class="alternative_article__paragraph">{{ sourceDescription }}</p>
      <a :href="`/document/${documentId}`" class="alternative_article__link">{{ name }}</a>
      <div class="alternative_article__actions">
        <Button
            variant="primary"
            :text="buttonText"
            :toggle-text="false"
            @click="$emit('buy-article')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";

export default {
  name: "AlternativeArticlesGrid",
  components: {
    Button
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    sourceDescription: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    sourceImg: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>

</style>