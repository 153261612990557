import {setupWatchlistBookmarkToggleComponent} from "../setups/vue-components";
import {fetchUsersWatchlistBookmarks} from "../fetching";

export const watchlistAddAllButtons = function () {
    const watchlistBookmarkToggleButtons = document.getElementsByClassName("watchlistBookmarkToggleButton");
    if (typeof (watchlistBookmarkToggleButtons) !== 'undefined' && watchlistBookmarkToggleButtons.length > 0) {
        fetchUsersWatchlistBookmarks()
        Array.from(watchlistBookmarkToggleButtons).forEach((el) => {
            const docId = el.getAttribute('data-document-id');
            const docTitle = el.getAttribute('data-document-title');
            const readOnly = !!el.getAttribute('data-read-only');
            const vueComponent = setupWatchlistBookmarkToggleComponent(docId, docTitle, readOnly);
            vueComponent.$mount(el);
        });
    }
}
