import updatePrices from "../updatePrices";
import {changeHistory} from "../utils/history_utils";

export const closeAllPreviews = function (removeScroll) {
    window.sharedState.selectedBrowseSource = {
        name: '',
        type: ''
    };

    let gridViewContainerElem = document.querySelector('.browse_page');
    if (!gridViewContainerElem) {
        gridViewContainerElem = document.querySelector('.result-div');
    }

    if (removeScroll && gridViewContainerElem !== null) {
        gridViewContainerElem.classList.remove("own_scroll");
    }

    document.querySelectorAll('[js-handler*="closeAllPreviews"]').forEach(function (item) {
        item.classList.add('width_zero')
        item.classList.remove('full_preview')

    });
    if (document.querySelector('.main')) {
        document.querySelector('.main').classList.remove('preview_opened');
    }

    document.querySelectorAll('.media_tile.opened').forEach(function (item) {
        item.classList.remove('opened');
        // item.classList.remove('full_preview')
        // item.classList.remove('preview__mobile');

        if (removeScroll) {
            setTimeout(function () {
                item.scrollIntoView({block: "nearest", inline: "nearest"});
            }, 100);
        }
    });

    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if(urlParams.get("bundleDocumentId"))
        changeHistory({bundleDocumentId: ""}, false);
    if(urlParams.get("expandedListName"))
        changeHistory({expandedListName: ""}, false);
}

export const closeFullPopupModal = function (updateThePrices = true, pushState = true) {
    if (window.sharedState.page === "login")
        window.location.assign('/');

    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if (urlParams.get("document"))
        changeHistory({document: ""}, pushState);
    if(urlParams.get("bundleDocumentId"))
        changeHistory({bundleDocumentId: ""}, pushState);
    if(urlParams.get("expandedListName"))
        changeHistory({expandedListName: ""}, pushState);

    //reset document list state
    window.sharedState.fullArticleModalList = [];
    window.sharedState.documentListOffset = undefined;
    window.fetchHigherDocumentIds = undefined;
    window.fetchLowerDocumentIds = undefined;

    if (window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.options("overflowBehavior.y", "scroll");
    }
    if (!!window.popupScrollbar) {
        window.popupScrollbar.destroy();
    }

    const popup = document.querySelector('[js-handler*="closeFullPopupModal"]');
    popup.style = "";

    const transparentPopup = document.querySelector("#transparentPopUp");
    if (transparentPopup.querySelector(".enter")) {
        setTimeout(function () {
            document.body.style.overflow = 'auto';
            const docBody = transparentPopup.querySelector("#overlay_content");
            if (docBody) {
                transparentPopup.style.visibility = "hidden";
                docBody.innerHTML = ""; // clear content
            }
        }, 100);
    }
    setTimeout(function () {
        document.body.style.overflow = 'auto';
        const docBody = document.querySelector("#modal_content");
        if (docBody) {
            docBody.innerHTML = ""; // clear content
        }
    }, 500);

    if (updateThePrices)
        updatePrices();

}

export const closeTransparentPopupModal = function () {
    if (window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.options("overflowBehavior.y", "scroll");
    }
    if (!!window.popupScrollbar) {
        window.popupScrollbar.destroy();
    }

    const popup = document.querySelector("#transparentPopUp");
    popup.style = "";

    setTimeout(function () {
        document.body.style.overflow = 'auto';
        const docBody = document.querySelector("#overlay_content");
        docBody.innerHTML = ""; // clear content
    }, 500);

}
