<template>
  <fieldset
      class="form_element checkbox_group">
    <legend class="form_element__label">
      {{element.fieldLabel}}
    </legend>
    <div style="clear: both;"></div>
    <CheckboxFormElement
        v-for="(subElement, index) in element.options"
        :key="subElement.name + '_' + index"
        :element="{id:(element.id + '_' + index), field: element.field, fieldValue: subElement.value, fieldLabel: subElement.text}"
        :passedValue="fields[index]"
        :focus="focus"
        v-on:focus="$emit('focus', element.id)"
        v-on:modified="updateFields(index, $event)"
    />
  </fieldset>
</template>

<script>
import CheckboxFormElement from "./CheckboxFormElement.vue";

export default {
  name: "CheckboxGroupFormElement",
  components: {CheckboxFormElement},
  props: {
    element: Object,
    passedValue: Object,
    focus: [String, Number]
  },
  data() {
    return {
      fields: {...this.passedValue}
    };
  },
  watch: {
    passedValue: function (val) {
      this.fields = {...val};
    }
  },
  methods: {
    updateFields: function (id, value) {
      let newFields = {...this.fields};
      if(value) {
        newFields[id] = this.element.options[id].text;
      } else {
        newFields[id] = null;
      }
      this.fields = newFields;
      this.$emit('modified', this.fields);
    }
  }
}
</script>

<style scoped>

</style>
