<template>

  <div class="sourceFilterArea" v-click-outside="onClickOutside">

    <div class="filters">

      <div class="filter__item_container">
        <div class="filter__item_search">
          <div class="filter__item_search__box">
            <input
                name="sourceSearchTextBox"
                ref="searchBox"
                type="text"
                :placeholder="$t('genios.filterSources')"
                v-model="searchInput"
                v-on:keyup.enter="onEnter"
                @focus="showResult=true"
            >
            <Button
                type="button"
                v-if="searchInput || sourceSelection.length > 0"
                variant="icon"
                icon="close-outline"
                @click="clear"
                @keydown.enter.prevent="searchInput = ''"
                @keydown.space="searchInput = ''"
                alt="clear"
                border="none"
            />
            <Button
                v-else
                type="button"
                variant="icon"
                icon="search-outline"
                border="none"
            />
          </div>
        </div>
      </div>


      <transition name="fade">
        <div class="resultBorder" v-if="fullLength()>0 || this.searchInput.length>1" v-show="showResult">

          <div is="transition-group" name="flip-list" class="filter__item_container">
            <checkbox-form-element
                v-for="(entityId, name, index) in suggestionList"
                v-if="showMore || index < initItemSize"
                :key="entityId"
                :name="name"
                :element="{id:entityId, fieldLabel: name}"
                :ref="'item_'+index"
                :passed-value="sourceSelection.includes(name)"
                v-on:modified="selected(name, $event)" />

          </div>

          <div v-if="noSourcesFound" class="count-color-zero" id="infoSourceSearcherActivity">
            {{ $t("genios.browseSources.nothingFound") }}
          </div>

          <Button
              v-if="openSourceBrowsingPage && sourceSelection.length>0"
              @click="openSourceBrowsePage"
              type="button"
              variant="primary"
              :text="$t('genios.loadSelection')"
              height="30"
              style="margin: 0 10px 5px auto;padding: 5px 14px !important;"
              :toggle-text="false"
          />

          <button v-if="fullLength()>initItemSize" type="button" class="filter__show-more"
                  v-on:click="showMore = !showMore">
            <div v-if="showMore" class="filter__show-more__name">
              {{ $t("genios.showLess") }}
            </div>
            <div v-else class="filter__show-more__name">
              {{ $t("genios.showMore") }} (+{{ fullLength() - initItemSize }})
            </div>
            <div class="filter__show-more__toggle">
              <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="showMore?'rotate-180':''"></ion-icon>
            </div>
          </button>

          <button type="button" class="filter__show-more hideButton" :class="pushUp" v-on:click="showResult = false">
            <div class="filter__show-more__name">
              {{ $t("genios.closeWindow") }}
            </div>
          </button>

        </div>
      </transition>

    </div>

  </div>

</template>


<script>

import axios from "axios";
import vClickOutside from 'v-click-outside';
import {debounce} from "lodash/function";
import Button from "../styled/Button.vue";
import {buildPath} from "../../../functions/utils/url_utils";
import {replaceBrowseResult} from "../../../functions/replacing";
import {getAuthCookieCacheBuster} from "../../../functions/utils/cookie_utils";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";


export default {
  name: "SourceSearcher",
  components: {
    CheckboxFormElement,
    Button
  },
  props: {
    sharedState: Object,
    explicitRoot: String,
    openSourceBrowsingPage: Boolean           // e.g. when used in the navigation bar;
  },
  created() {
    if (!this.openSourceBrowsingPage) {
      this.checkPageRequestParameter();
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      searchInput: '', // new URLSearchParams(window.location.search).get("sourceFilterQuery"),
      debouncedSearchInput: '',
      suggestionList: {},
      showMore: false,
      suggestionsCancelSource: null,
      sourceSelection: [],
      initItemSize: 7,
      showResult: true,
      noSourcesFound: false
    }
  },
  computed: {
    pushUp() {
      return (this.fullLength() > this.initItemSize) ? '' : 'marginUp';
    }
  },
  watch: {
    searchInput: debounce(function (newVal) {
      this.debouncedSearchInput = newVal;
      this.noSourcesFound = false;
    }, 400),
    debouncedSearchInput(val) {
      if (val.length < 2) {
        this.setSuggestionList({});
      } else {
        this.getSuggestions(val);
      }
    }
  },
  methods: {
    onEnter() {
      this.$refs.searchBox.blur();
      let currentList = Object.entries(this.suggestionList);
      if (currentList && currentList.length > 0) {
        let firstDb = currentList[0][1];
        !this.sourceSelection.includes(firstDb);
        this.$refs.item_0[0].selected();
      }
    },
    clear() {
      this.searchInput = '';
      this.sourceSelection = [];
      this.suggestionList = {};
      this.reloadSourceList();
      this.noSourcesFound = false;
    },
    onClickOutside() {
      this.showResult = false;
    },
    selected(name, checked) {
      if (checked) {
        this.sourceSelection.push(name)
      } else {
        this.sourceSelection = this.sourceSelection.filter(item => item !== name)
      }
      this.reloadSourceList();
    },
    reloadSourceList() {
      if (!this.openSourceBrowsingPage) {
        window.sharedState.offset = null;
        if (this.sourceSelection.length > 0) {
          window.sharedState.browseActiveFilters.source = this.buildFilterParam().getAll("source");
        } else {
          window.sharedState.browseActiveFilters.source = null;
        }
        replaceBrowseResult();
      }
    },
    openSourceBrowsePage() {
      // location.href = "/browse?sourceFilterQuery=" + encodeURIComponent(this.buildFilterParam());
      // console.log(encodeURIComponent(this.buildFilterParam()))
      location.href = "/browse/" + window.sharedState.navigationTree[0].urlSafeName + "?" + this.buildFilterParam().toString()
    },
    buildFilterParam() {
      const urlParams = new URLSearchParams();
      const key = "source"
      this.sourceSelection.forEach(value => {
        urlParams.append(key, value)
      })

      return urlParams
    },
    fullLength() {
      return Object.keys(this.suggestionList).length;
    },
    setSuggestionList(valueMap, checkForNoSourceMessage = false) {
      let newValueList = Object.entries(valueMap);
      if (checkForNoSourceMessage && newValueList.length === 0) {
        this.noSourcesFound = true;
      }
      let oldValueList = Object.entries(this.suggestionList);
      if (this.sourceSelection.length > 0 && oldValueList.length > 0) {
        let oldValueSelected = oldValueList.filter(([key, value]) => this.sourceSelection.includes(value));
        let newValueListWithoutExisting = newValueList.filter(([key, value]) => !this.sourceSelection.includes(value));
        this.suggestionList = Object.fromEntries(oldValueSelected.concat(newValueListWithoutExisting));
      } else {
        this.suggestionList = valueMap;
      }
    },
    getSuggestions(val) {
      let apiPath = "/api/sourceSearch";
      let path = this.explicitRoot ? apiPath + "/" + this.explicitRoot : buildPath("/api/sourceSearch")
      let params = {
        requestText: val,
        auth: getAuthCookieCacheBuster(),
      };
      if (this.suggestionsCancelSource) {
        this.suggestionsCancelSource.cancel();              // cancel old request
      }
      this.suggestionsCancelSource = axios.CancelToken.source();
      axios.get(path, {
        params: params,
        cancelToken: this.suggestionsCancelSource.token
      }).then(response => {
        this.setSuggestionList(response.data, true);
      }).catch(errors => {
        if (axios.isCancel(errors)) {
          console.log('Request canceled: ' + val + "   ");
        } else {
          console.error("Source-Searching failed. " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
          console.log("Error: " + errors);
        }
      });
    },
    checkPageRequestParameter() {
      let sourceFilterParam = new URLSearchParams(window.location.search).get("sourceFilterQuery")
      if (sourceFilterParam) {
        let paramList = sourceFilterParam.substring(3).split("|+|");
        paramList.forEach(sourceKey =>
            this.sourceSelection.push(sourceKey)
        );
        this.getSuggestions(sourceFilterParam);
        this.showResult = false;
      }
    }
  }
}

</script>


<style scoped>

.myNavOverlay .resultBorder {
  top: 68px;
  left: 6px;
  right: 7px;
}

.filters .filter__show-more {
  display: inline-flex;
  width: auto;
  padding: 0 4px;
  float: left;
}

.filters .hideButton {
  float: right;
}

.filters .marginUp {
  margin-top: -10px;
}

.filters .hideButton div {
  font-size: 13px;
  padding-right: 5px;
  padding-top: 5px;
}

.button__primary {
  font-size: 13px;
  margin: 5px 0 10px 158px;
}

#infoSourceSearcherActivity {
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: normal;
  white-space: normal;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.filter__item_container .checkbox .form_element__label span{
  white-space: normal;
}
</style>
