<template>
  <div class="filter__title">
    <p v-on:click="toggleShow()">
      {{ title }}
    </p>
    <button
        type="button"
        class="filter__title__button"
        v-on:click="toggleShow()"
        v-if="hasToggle"
        :aria-label="title"
        :aria-expanded="show ? 'true' : 'false'"
    >
      <ion-icon src="/icons/custom/chevron-down-outline.svg" :class=" (show)? 'rotate-180' : ''"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "FilterTitle",
  props: {
    title: String,
    show: Boolean,
    hasToggle: Boolean
  },
  methods: {
    toggleShow: function () {
      this.$emit('toggle-show');
    }
  }
}
</script>

<style scoped>

</style>
