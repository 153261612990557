<template>
  <div class="video__footer">
    <p><span class="moduleDocumentRights">Alle Rechte vorbehalten: (c) {{ copyright }}</span></p>
    <div class="video__footer__logo">
      <img :src="geniosLogo" alt="">
      <span class="official-company-name">© GBI-Genios Deutsche Wirtschaftsdatenbank GmbH</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoFooter",
  props: {
    copyright: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      geniosLogo: `/assets/logos/logo_genios.png`,
    }
  }
}
</script>

<style scoped>

.official-company-name {
  padding-right: 5px;
  hyphens: auto;
}
</style>