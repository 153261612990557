export const isArraysEqual = (arr1 = [], arr2 = []) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false

    if (arr1.length !== arr2.length) return false

    const isSimple1 = arr1.every(item => typeof item === 'string' || typeof item === 'number')
    const isSimple2 = arr2.every(item => typeof item === 'string' || typeof item === 'number')

    if (!isSimple1 && !isSimple2) return false

    return arr1.every((item, index) => item === arr2[index])
}

export const convertArrayToObject = (array = []) => {
    let res = {}
    array.forEach(({field, message}) => res[field] = message)
    return res
}