import i18n from "../vue/components/plugins/Translations.vue";

const BASE_URL = "https://www.gbi-genios.de/de"

const linkTargetType = Object.freeze({
  self: "_self",
  blank: "_blank",
  parent: "_parent",
  top: "_top",
})

export const menuNavigationLinks = (solution) => [ //TODO: use solution settings in future: FUNKTIONEN-11613
  {
    labelCode: "genios.help",
    src: solution === "ebib" ? `/de/hilfe/ebib/genios-ebib-hilfe?solution=genios+ebib` : solutionsList.includes(solution) ? `/de/hilfe/${solution}/genios-hilfe?solution=${solution}` : `/de/hilfe/firmensolution/genios-hilfe?solution=firmensolution`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.faq",
    src: solution === "ebib" ? `/corporate/de/${solution}/faq` : `${BASE_URL}/faq-haeufig-gestellte-fragen`,
    target: linkTargetType.self
  },
  "line",
  {
    labelCode: "genios.companyWebsiteLink.text",
    src: `${BASE_URL}`,
    target: linkTargetType.blank
  },
  {
    labelCode: "genios.contact",
    src: solution === "ebib" ? `/corporate/de/${solution}/kontakt` : "/corporate/de/genios/kontakt",
    target: linkTargetType.self
  },
  {
    labelCode: "genios.imprint",
    src: `${BASE_URL}/impressum-geniosde`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.agb",
    src: `${BASE_URL}/allgemeine-geschaeftsbedingungen-geniosde`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.privacy",
    src: `${BASE_URL}/datenschutzerklaerung-geniosde`,
    target: linkTargetType.self
  }
];


export const passwordValidationCriteria = (validationObject) => [
  {
    text: i18n.t('passwordValidation.length'),
    validation: () => validationObject?.length >= 8,
  },
  {
    text: i18n.t('passwordValidation.upperCaseLowerCase'),
    validation: () =>
        /[A-Z]/.test(validationObject) && /[a-z]/.test(validationObject),
  },
  {
    text: i18n.t('passwordValidation.number'),
    validation: () => /\d/.test(validationObject),
  },
  {
    text: i18n.t('passwordValidation.specialCharacter'),
    validation: () => /[!@#$%^&*(),.?":{}|<>§$%&/*!?#+_-]/.test(validationObject),
  },
];

export const AttachmentType = Object.freeze({
  VIDEO: "VIDEO"
})

export const MediaType = Object.freeze({
  VIDEOS: "Videos",
  EBOOK: "eBook"
})

export const PaymentType = Object.freeze({
  CARD: "card",
  PAYPAL: "paypal",
  SOFORT: "sofort"
})

export const solutionsList = ["genios", "wiso"];
