<template>
  <div class="error" v-html="error"/>
</template>

<script>
export default {
  name: "Error",
  props: {
    error: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.error {
  color: #CC1D33;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
</style>