<template>
  <a
      class="video__attachment"
      @click="downloadPdf($event)"
      ref="button"
      href="#"
      :data-legacy-doc-id="attachment.docId"
      :data-attachment-download-link="attachment.link"
      :download-display-name="attachment.fileName"
  >

    <div class="tooltip tooltip-icon">
    <div class="video__attachment__title">
      <!--      src="/icons/custom/PDF-document-outline.svg"-->
      <ion-icon name="download"/>
      <span class="video__attachment__text">{{ title }}</span>
    </div>
      <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">
        <div class="video__attachment__text" style="width: 105px">
          {{ $t("documentMetadata.attachment.download") }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import {fetchAttachment} from "../../../functions/fetching";

export default {
  name: "AttachmentsList",
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {

      //extract filename from thr path
      let lastIndex = this.attachment.fileName.split("/").length - 1
      let filenameFull = this.attachment.fileName.split("/")[lastIndex];

      let filename = filenameFull.split(".")[0];
      let fileExtension = filenameFull.split(".")[1]

      let maxLength = 50;
      if(filename.length <= maxLength)
      {
        return filenameFull;
      }

      let slicingIndex = (maxLength/2) - 1;

      //slice the filename to a maximum length of 54 characters
      return filename.slice(0, slicingIndex) + '...' + filename.slice(filename.length-slicingIndex, filename.length)+"."+fileExtension
    }
  },
  methods: {
    downloadPdf(event) {
      event.preventDefault();
      fetchAttachment(this.$refs.button);
    }
  }
}
</script>

<style scoped>

</style>