<template>
  <div class='loading' id="documentLoader">
    <div class='loader'/>
  </div>
</template>

<script>
export default {
  name: "DocumentLoader"
}
</script>

<style scoped>
#documentLoader {
  width: 100%
}
</style>