<template>
  <article
      class="article element"
      :id="'list__' + document.documentId"
      :data-document-id="document.documentId"
      js-handler="checkSingleDocument"
      tabindex="0"
      role="article"
      :aria-labelledby="'articleTextTitle__' + document.documentId"
      :class="displayMeinGeniosBlock ? 'refresh-solution' : ''"
  >
    <template v-if="document.itemType.includes('Videos')">
      <div class="article-element__img__video fake_video img-container">
        <img alt="" :loading="'lazy'" :src="`/resource/logos-small/${document.database.toLowerCase()}.png`"/>
        <div class="article-element__img__video__tag"><p>{{document.itemType}}</p></div>
      </div>
    </template>
    <template v-else>
      <div class="article__img">
        <img
            v-if="!(displayMeinGeniosBlock)"
            alt=""
            :loading="index > 5 ? 'lazy' : 'eager'"
            class="preview-image"
            :src="document.coverUri"
        />
        <CheckboxFormElement
            :element="{
              ...defaultCheckboxElement,
              id: document.documentId,
              name: document.documentTitle,
              value: isChecked,
            }"
            :passed-value="isChecked"
            @focus="focus = $event"
            @modified="checkSingleDocument(document.documentId, $event); isChecked=$event"
            class="article__checkbox"
            :class="displayMeinGeniosBlock ? 'refresh-solution' : ''"
        />
        <div v-if="!(displayMeinGeniosBlock)"
             class="article__img__tag_row"
        >{{document.itemType}}</div>
      </div>
    </template>
    <div class="article__text" :class="displayMeinGeniosBlock ? 'refresh-solution' : ''">
      <div class="article__img"
           :class="displayMeinGeniosBlock ? 'refresh-solution' : ''">
        <img
            v-if="!(displayMeinGeniosBlock)"
            alt=""
            :loading="index > 5 ? 'lazy' : 'eager'"
            class="preview-image"
            :src="document.coverUri"
        />
        <CheckboxFormElement
            :element="{
              ...defaultCheckboxElement,
              id: document.documentId,
              name: document.documentTitle,
              value: isChecked,
            }"
            :passed-value="isChecked"
            @focus="focus = $event"
            @modified="checkSingleDocument(document.documentId, $event); isChecked=$event"
            class="article__checkbox"
        />
        <div class="article__img__tag_row">{{document.itemType}}</div>
      </div>
      <a
          :href="`/document/${document.documentId}`"
          class="article__text__panelHeader"
          tabindex="-1"
          :data-document-id="document.documentId"
          :class="displayMeinGeniosBlock ? 'refresh-solution' : ''"
      >
        <div class="article__img__source" :class="displayMeinGeniosBlock ? 'article__logo' : ''">
          <img
              alt=""
              loading="lazy"
              class="article__text__source-icon"
              :src="`/resource/logos-tiny/${document.database.toLowerCase()}.png`"
          />
          <span v-if="!(displayMeinGeniosBlock)">{{ document.sourceName }}</span>
        </div>
        <template v-if="document.displayRow.field_A">
          <div class="article__text__source">{{document.displayRow.field_A}}</div>
        </template>
        <template v-if="document.displayRow.field_B">
          <div class="article__text__supertitle">{{document.displayRow.field_B}}</div>
        </template>
      </a>
      <div class="article__text__panelBody">
        <a
            :href="`/document/${document.documentId}`"
            class="article__text__panelBody__details js_open_full_document_modal"
            tabindex="-1"
            :data-document-id="document.documentId"
            @click="openArticleOnClick($event, document.documentId)"
        >
          <template v-if="document.displayRow.field_C">
          <div class="article__text__title tooltip">
            <div v-html="document.displayRow.field_C"></div>
            <template v-if="document.mediaType.includes('Firmeninformationen') && document.displayRow.field_C_tooltip">
              <div
                  class="tooltiptext tooltip-top"
                  v-html="formatTooltip(document.displayRow.field_C_tooltip)"
              ></div>
            </template>
          </div>
          </template>
          <template v-if="document.displayRow.field_D">
            <div class="article__text__subtitle" v-html="document.displayRow.field_D"></div>
          </template>
          <template v-if="document.displayRow.field_E">
            <div class="article__text__text" v-html="document.displayRow.field_E"></div>
          </template>
          <template v-if="document.displayRow.field_F">
            <div class="article__text__trefferumgebung" v-html="document.displayRow.field_F"></div>
          </template>
        </a>
        <div class="article__text__bar" :class="displayMeinGeniosBlock ? 'refresh-solution' : ''">
          <div class="article__text__bar__number_pages">
            <div v-if="displayMeinGeniosBlock && document.itemType" class="refresh-solution">{{ document.itemType }}</div>
            <template v-if="document.itemType.includes('eBook')">
              {{ document.displayRow.field_G }} {{ $t('genios.document.pages') }} {{ document.displayRow.field_G_1 }} {{ $t('genios.document.words') }}
            </template>
            <template v-else>
              <span v-if="document.displayRow.field_G">{{ document.displayRow.field_G }} {{ $t('genios.document.words') }}</span>
            </template>
          </div>
          <div v-for="fileType in document.fileTypes" :key="fileType" class="article__text__bar__attachment media_preview__main_content__button_bar__attachment">
            <TagComponent :tag-name="fileType"/>
          </div>
          <div class="article__img__tag_row" :class="displayMeinGeniosBlock ? 'refresh-solution' : ''">{{ document.itemType }}</div>
          <div class="article__text__bar__actions">
            <div class="tooltip tooltip-button">
              <a
                  :href="`/document/${document.documentId}`"
                  :data-document-id="document.documentId"
                  js-handler="openArticleOnClick updatePrices"
                  :data-legacy-doc-id="document.legacyDocId"
                  @click="openArticleOnClick($event, document.documentId)"
                  class="button__primary buy_button js_open_full_document_modal"
                  :class="document.infoPrice ? '' : 'hide' + ' button__primary buy_button js_open_full_document_modal'"
              > {{document.infoPrice ? $t('genios.search.button.infosAndPrices') : document.priceDisplayText || '...'}}
              </a>
              <template v-if="document.displayRow.field_C_tooltip">
                <div
                    class="tooltiptext tooltip-top tooltip-top-button"
                    v-html="formatTooltip(document.displayRow.field_C_tooltip)"
                ></div>
              </template>
            </div>
            <div class="article__functions containsDialogMarker" id="action_bar_marker">
              <div v-if="!(document.isBookmark)"
                   class="watchlistBookmarkToggleButton"
                   :data-document-id="document.documentId"
                   :data-document-title="document.documentTitle"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="article__source" :class="displayMeinGeniosBlock ? 'refresh-solution' : ''">
      <div v-if="displayMeinGeniosBlock && document.displayRow.field_H">
        <div class="article__source__date article__date--icon">
          <ion-icon v-if="document.isBookmark" name="bookmark-outline" aria-label="bookmark outline" role="img"/>
          <ion-icon v-if="document.isDocumentHistory" name="document-outline" aria-label="document outline" role="img"/>
          <div v-html="document.displayRow.field_H"/>
        </div>
      </div>
      <div class="article__source__date article__date--icon" v-if="document.displayRow.field_H">
        <ion-icon v-if="displayMeinGeniosBlock" name="earth-outline" aria-label="created on" role="img"/>
        <div v-html="document.displayRow.field_H"/>
      </div>
      <div v-if="!(displayMeinGeniosBlock)" class="article__source__image">
        <img
            alt=""
            :loading="index > 5 ? 'lazy' : 'eager'"
            class="article__text__source-icon"
            :src="`/resource/logos-big/${document.database.toLowerCase()}.png`"
        />
      </div>
    </div>
  </article>
</template>

<script>

import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import TagComponent from "./TagComponent.vue";
import Button from "./Button.vue";
import {checkSingleDocument} from "../../../functions/checks";
import {openFullArticleModal} from "../../../functions/opens";
import {setupPaymentFormComponent} from "../../../functions/setups/vue-components";
import updatePrices from "../../../functions/updatePrices";
import axios from "axios";
import {PaymentMode} from "../../../functions/components/payment";
import {fakeVisitedLink} from "../../../functions/utils/history_utils";
import {watchlistAddAllButtons} from "../../../functions/components/watchlist";

export default {
  name: 'ArticleElementComponent',
  components: {Button, CheckboxFormElement, TagComponent},
  data() {
    return {
      isChecked: false,
      focus: '',
      defaultCheckboxElement: {
        fieldLabel: '',
        field: false,
        error: false,
      },
      displayMeinGeniosBlock: false,
    }
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  mounted() {
    watchlistAddAllButtons();
    this.$nextTick(() => {
      updatePrices(document.body, this.document.documentId, true);
    });
    this.displayMeinGeniosBlock = this.document.isBookmark || this.document.isDocumentHistory;
  },
  methods: {
    checkSingleDocument,
    formatTooltip(tooltip) {
      return tooltip
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#39;')
          .replace(/&amp;/g, '&');
    },
    async openArticleOnClick(event, documentId) {
      if (window.sharedState.page !== 'document') {
        event.preventDefault();

        const forceConfirmPrice = event.currentTarget.hasAttribute("data-force-confirm-price");
        const linkHref = event.currentTarget.href;

        const {data} = await axios.post(`/api/retrieveDocuments`, {
          ids: [documentId],
          forceConfirmPrice: !!forceConfirmPrice,
        });

        if (data?.code) {
          await setupPaymentFormComponent(data, documentId, PaymentMode.fullDocument);
        } else {
          fakeVisitedLink({current_url: window.location.href, desired_url: linkHref})
          window.sharedState.fullArticleModalList = documentId;
          openFullArticleModal(documentId, true, forceConfirmPrice).then(() => {
          });
        }
        this.$nextTick(() => {
          updatePrices(document.body, documentId, true);
        });
      }
    },
  },
};
</script>