<template>
  <fragment>

    <video-text-content :title="title" :abstract="abstract">
      <video-player v-show="showVideo" :video="currentVideo" :database="database"/>
    </video-text-content>

    <video-footer :copyright="copyright"/>

  </fragment>
</template>

<script>
import Vue from "vue";
import {Plugin} from "vue-fragment"

import VideoPlayer from "./VideoPlayer.vue";
import VideoFooter from "./VideoFooter.vue";
import VideoTextContent from "./VideoTextContent.vue";
import eventBus from "../../../eventBus";

//============================================

Vue.use(Plugin)

//============================================

export default {
  name: "VideoComponent",
  components: {VideoTextContent, VideoFooter, VideoPlayer},
  props: {
    title: {
      type: String,
      required: true
    },
    abstract: {
      type: String,
      default: null
    },
    videoContent: {
      type: [Array, String],
      required: true
    },
    sharedState: {
      type: Object,
      required: true
    },
    videoPartialId: {
      type: String,
      required: false
    },
    database: String,
    copyright: String
  },
  data() {
    return {
      currentVideo: this.videoContent.find(item => item.partialId === this.videoPartialId) || this.videoContent[0],
    }
  },
  computed: {
    showVideo(){
      return !!this.currentVideo.url
    }
  },
  methods: {
    initTitle() {
      if (this.videoContent.length <= 1) {
        const video = this.videoContent[0]
        if (this.title === video?.title)
          this.$set(video, "title", "")
      }
    }
  },
  created() {
    this.initTitle()
  },
  mounted() {
    eventBus.$on("update-current-video", video => {
      this.currentVideo = video
    })
  }
}
</script>

<style scoped>
</style>