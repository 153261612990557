<template>
  <div class="payment__methods" @keydown.enter="submitForm">
    <component is="script" src="https://static.unzer.com/v1/checkout.js" async defer/>
    <h1 class="payment__title">{{ title }}</h1>
    <div class="payment__methods__body">
      <PaymentMethodElement
          v-if="availablePaymentTypes.includes(PaymentType.CARD)"
          :icons="['/icons/visa_logo.svg', '/icons/mastercard_logo.svg', '/icons/american_express_logo.svg']"
          :active-btn="activeBtn"
          :name="PaymentType.CARD"
          :checkbox-obj="checkboxCard"
          :errors="errors"
          @activate="setActive($event)"
          @updateForm="getForm"
          :form="form"
      />
      <PaymentMethodElement
          v-if="availablePaymentTypes.includes(PaymentType.PAYPAL)"
          :icons="['/icons/paypal_logo.svg']"
          :active-btn="activeBtn"
          :name="PaymentType.PAYPAL"
          :checkbox-obj="checkboxPaypal"
          :errors="errors"
          @activate="setActive($event)"
          @updateForm="getForm"
          :form="form"
      />
      <PaymentMethodElement
          v-if="availablePaymentTypes.includes(PaymentType.SOFORT)"
          :icons="['/icons/sofort_logo.svg']"
          :active-btn="activeBtn"
          :name="PaymentType.SOFORT"
          :checkbox-obj="checkboxSofort"
          :errors="errors"
          @activate="setActive($event)"
          @updateForm="getForm"
          :form="form"
      />
      <div class="payment__information flex space_above" :class="{hide: activeBtn}">
        <ion-icon name="information-circle-outline"/>
        {{$t("orderingProcess.paymentProviderInfo")}}
      </div>
    </div>
    <div class="payment__steps__bottom">
      <p class="payment__steps__bottom__description" >
        {{
          $t('orderingProcess.mainForm.paymentMethod.footerText')
        }}
      </p>
      <Button
          variant="primary"
          name="submit"
          type="button"
          ref="submitFormBtn"
          :text="$t('orderingProcess.mainForm.button')"
          @click="submitForm"
          :toggle-text="false"
          :disabled="isLoading || !activeBtn"
          position="left"
          :class="isLoading ? 'disabled': ''"
      >
        <ButtonLoader
            v-if="isLoading"
            :size="$refs.submitFormBtn.$el.clientHeight"
        />
      </Button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {isUndefined} from "lodash";

import i18n from "../../plugins/Translations.vue";
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";
import Button from "../../styled/Button.vue";
import ButtonLoader from "../../styled/ButtonLoader.vue";
import InvoiceFormElement from "./InvoiceFormElement.vue";
import PaymentButton from "./PaymentButton.vue";
import PaymentMethodElement from "./PaymentMethodElement.vue";

import {
  getBasicPaymentConfiguration, setPaypal,
  setUnzerPage,
  setUnzerBasket,
  authorizeUnzerPayment, setSofort
} from "../../../../functions/payment/unzerService";

import {countryVatPrefix, isCountryGermany} from "../../../../functions/components/payment";
import {isCountryWithVatId} from "../../../../functions/components/payment";
import {vatIdRegex} from "../../../../functions/components/payment";
import {buildPaymentApiCall} from "../../../../functions/builds";
import {PaymentType} from "../../../../functions/consts";

export default {
  name: "PaymentMethodComponent",
  computed: {
    PaymentType() {
      return PaymentType
    }
  },
  components: {
    PaymentMethodElement,
    PaymentButton,
    i18n,
    Button,
    CheckboxFormElement,
    InvoiceFormElement,
    ButtonLoader
  },
  props: {
    orderDataId: {
      type: [String, Number],
      required: true
    },
    documentIds: {
      type: Array,
      required: true
    },
    orderResponse: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      orderResponseCopy: _.cloneDeep(this.orderResponse),
      checkboxCard: {
        id: 'checkboxCard',
        fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.invoice'),
        passedValue: false
      },
      checkboxPaypal: {
        id: 'checkboxPaypal',
        fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.invoice'),
        passedValue: false
      },
      checkboxSofort: {
        id: 'checkboxSofort',
        fieldLabel: i18n.t('orderingProcess.mainForm.paymentMethod.invoice'),
        passedValue: false
      },
      availablePaymentTypes: '',
      activeBtn: '',
      form: {
        countryCode: 'DE'
      },
      errors: []
    };
  },
  async mounted() {
    const {availablePaymentTypes} = await getBasicPaymentConfiguration()
    this.availablePaymentTypes = availablePaymentTypes
  },
  methods: {
    setActive(name) {
      this.activeBtn = name;
      switch (this.activeBtn) {
        case 'card':
          this.checkboxPaypal.passedValue = false
          this.checkboxSofort.passedValue = false
          this.errors = []
          this.form = {
            countryCode: 'DE'
          }
          break
        case 'paypal' :
          this.checkboxCard.passedValue = false
          this.checkboxSofort.passedValue = false
          this.errors = []
          this.form = {
            countryCode: 'DE'
          }
          break
        case 'sofort':
          this.checkboxCard.passedValue = false
          this.checkboxPaypal.passedValue = false
          this.errors = []
          this.form = {
            countryCode: 'DE'
          }
          break
        default:
          this.checkboxCard.passedValue = false
          this.checkboxPaypal.passedValue = false
          this.checkboxSofort.passedValue = false
          this.errors = []
      }
    },
    updateForm(value) {
      this.$emit('update', value);
    },
    getForm({name, value}) {
      this.form[name] = value;
      if (name === "countryCode") {
        this.changeOrderResponseByCountryCode(value)
      }
    },
    changeOrderResponseByCountryCode(countryCode) {
      if (isCountryGermany(countryCode)) {
        this.orderResponse.orderDataModel.totalGross = this.orderResponseCopy.orderDataModel.totalGross
        this.orderResponse.orderDataModel.totalTax = this.orderResponseCopy.orderDataModel.totalTax
      } else {
        const tmp = this.orderResponse
        tmp.orderDataModel.totalGross = this.orderResponse.orderDataModel.totalNet
        tmp.orderDataModel.totalTax = 0
        this.orderResponse = tmp
      }
    },
    async submitForm() {
      this.isLoading = true

      if (this.checkboxCard.passedValue || this.checkboxPaypal.passedValue || this.checkboxSofort.passedValue)
        this.validateForm(this.form)
      else this.errors = []

      if (!this.errors.length) {
        const {mode} = this.$root
        const amount = this.orderResponse.orderDataModel.totalGross
        const basketItems = this.orderResponse.orderDataModel.items.map((item, index) => {
          return {
            unit: item.database,
            type: item.docType,
            amountGross: item.priceGross,
            amountNet: item.priceNet,
            quantity: 1,
            amountVat: item.tax,
            title: item.sourceName,
            subTitle: item.title,
            basketItemReferenceId: this.documentIds[index]
          }
        })

        const basketId = await this.createBasket(
            {
              amountTotalGross: this.orderResponse.orderDataModel.totalGross,
              currencyCode: "EUR",
              orderId: this.orderDataId,
              basketItems,
              amountTotalDiscount: this.orderResponse.orderDataModel.totalNet, //totalNet
              amountTotalVat: this.orderResponse.orderDataModel.totalTax
            }
        )

        //create reservation for transaction
        const {id, paymentId, redirectUrl} = await this.authorizePayment({amount, resources: {basketId}})

        let params = {
          orderDataId: this.orderDataId,
          transactionId: paymentId,
          ...this.form,
          email: sessionStorage.getItem('defaultUserEmail'),
          hasBillingAddress: this.checkboxCard.passedValue || this.checkboxPaypal.passedValue || this.checkboxSofort.passedValue
        }

        switch (this.activeBtn) {
          case "card":
            params.stepName = "CREATE_CREDIT_CARD_PAYMENT"
            break
          case 'paypal':
            params.stepName = "CREATE_PAYPAL_PAYMENT"
            break
          case 'sofort':
            params.stepName = "CREATE_SOFORT_PAYMENT"
            break
          default:
            break
        }

        if (!paymentId) {
          this.$emit('update-step', {
            ...this.orderResponse,
            code: 'TRANSACTION_IN_PROGRESS',
            sofortId: id,
            params,
            basketId
          })
        } else {
          let nextStep

          try {

            const {
              fetchUrl,
              params: buildParams,
              config
            } = buildPaymentApiCall(this.documentIds, mode, this.orderResponse, params)

            const {data} = await axios.post(fetchUrl, buildParams, config)

            nextStep = data.code

            if (nextStep === "ERROR_CREDIT_CARD_PAYMENT_VALIDATION" || nextStep === 'ERROR_PAYPAL_PAYMENT_VALIDATION' || nextStep === 'ERROR_SOFORT_PAYMENT_VALIDATION') {
              this.errors = data.validationModel.errors.map(item => {
                if (item.field === "firstname") {
                  item.field = "firstName"
                }
                return {
                  object: item.field,
                  text: item.message
                }
              })
            }
          } catch (e) {
            console.log("error =>", e)
            console.dir(e)
          }

          if (nextStep === 'CREDIT_CARD_PAYMENT_CREATED' || nextStep === 'PAYPAL_PAYMENT_CREATED') {
            //for saving data for redirection
            const orderQuantity = JSON.parse(sessionStorage.getItem('paymentId'))?.orderQuantity || 0
            sessionStorage.setItem('paymentId', JSON.stringify({
              paymentId,
              orderQuantity: parseInt(orderQuantity) || 0,
              mode,
              issueDate: this.orderResponse?.issueDate,
              attachmentType: this.orderResponse?.attachmentType
            }))

            if (nextStep === 'PAYPAL_PAYMENT_CREATED') {
              window.location.assign(redirectUrl)
            } else {
              const checkout = new window.checkout(id)

              const {status} = await checkout.init()
              if (status === 'ok')
                checkout.open()

              checkout.abort(this.abortPayment);

              checkout.success();

              checkout.error(function (error) {
                checkout._hideIframe()
              });
            }
          }
        }
      }
      this.isLoading = false
    },
    validateForm(form) {
      this.errors = []
      const {
        company,
        department,
        streetNumber,
        city,
        firstName,
        zipcode,
        lastname,
        salutation,
        street,
        countryCode,
        taxIdNumber
      } = form

      //TODO: use de.json for setting {field: '...'} instead of now

      if (isUndefined(countryCode) || !countryCode.trim()) {
        this.errors.push({
          object: 'countryCode',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihr Land'})
        })
      }

      if (isUndefined(streetNumber) || !streetNumber.trim()) {
        this.errors.push({
          object: 'streetNumber',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihre Hausnummer'})
        })
      }

      if (isUndefined(city) || !city.trim()) {
        this.errors.push({
          object: 'city',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'einen Ort'})
        })
      }

      if (isUndefined(firstName) || !firstName.trim()) {
        this.errors.push({
          object: 'firstName',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihren Vornamen'})
        })
      }

      if (isUndefined(zipcode) || !zipcode.trim()) {
        this.errors.push({
          object: 'zipcode',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'eine PLZ'})
        })
      }

      if (isUndefined(lastname) || !lastname.trim()) {
        this.errors.push({
          object: 'lastname',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihren Nachname'})
        })
      }

      if (isUndefined(salutation) || !salutation.trim()) {
        this.errors.push({
          object: 'salutation',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'eine Anrede'})
        })
      }

      if (isUndefined(street) || !street.trim()) {
        this.errors.push({
          object: 'street',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihre Straße'})
        })
      }

      if (isCountryWithVatId(countryCode) && (isUndefined(company) || !company.trim())) {
        this.errors.push({
          object: 'company',
          text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihren Firmennamen'})
        })
      }

      if (isCountryWithVatId(countryCode)) {
        if (isUndefined(taxIdNumber) || !taxIdNumber.trim()) {
          this.errors.push({
            object: 'taxIdNumber',
            text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'Ihre Umsatzsteueridentifikationsnummer'})
          });
        } else if (!vatIdRegex.test(taxIdNumber) || !taxIdNumber.startsWith(countryVatPrefix(countryCode))) {
          console.info("VAT-ID is not valid: " + taxIdNumber);
          this.errors.push({
            object: 'taxIdNumber',
            text: i18n.t('orderingProcess.mainForm.paymentMethod.form.error', {field: 'eine korrekte Umsatzsteueridentifikationsnummer'})
          });
        }

      }


    },
    async createBasket(body) {
      const data = await setUnzerBasket(body)
      return data.id
    },
    async authorizePayment({amount, resources}) {
      if (this.activeBtn === 'paypal') {
        return await this.authorizePaypal({amount, resources})
      } else if (this.activeBtn === 'sofort') {
        return await this.authorizeSofort({amount, resources})
      } else if (this.activeBtn === "card") {
        return await this.authorizeUnzerPage({amount, resources})
      }
    },
    async authorizeUnzerPage({amount, resources}) {
      const {id, resources: {paymentId}, redirectUrl} = await setUnzerPage({
        amount,
        currency: "EUR",
        returnUrl: window.location.href, //required
        orderId: this.orderResponse.orderPaymentId,
        paymentReference: this.orderDataId,
        resources,
        excludeTypes: this.availablePaymentTypes.filter(item => item !== this.activeBtn)
      })
      return {id, paymentId, redirectUrl}
    },
    async abortPayment() {
      this.isLoading = true
      const {mode} = this.$root
      const iframe = document.querySelector('iframe[id^="unzer-checkout-iframe"]')
      iframe.parentNode.removeChild(iframe)

      sessionStorage.setItem('paymentId', JSON.stringify({paymentId: null, orderQuantity: 0, mode}))

      try {
        const category = window.sharedState.fidoCategory,
            fidoContainer = document.querySelector(".fido_component_container");

        let fidoUid, requestBody = {
          email: sessionStorage.getItem('defaultUserEmail')
        }

        if (!!fidoContainer)
          fidoUid = fidoContainer.getAttribute("data-fido-id");

        const documentId = this.documentIds.find(item => item === fidoUid) || null

        if (!!fidoUid && !!category && documentId && documentId.indexOf("FIDO") === -1) {
          requestBody.masterUid = fidoUid;
          requestBody.category = category;
        }

        const {
          fetchUrl,
          params: buildParams,
          config
        } = buildPaymentApiCall(this.documentIds, mode, this.orderResponse, requestBody)

        const {data} = await axios.post(fetchUrl, buildParams, config)
        this.$emit('update-step', data)
      } catch (e) {
        console.log("abortPayment:error => ", e)
        console.dir(e)
      }
      this.isLoading = false
    },
    async authorizePaypal({amount, resources}) {
      const {data} = await setPaypal({
        amount,
        currency: "EUR",
        returnUrl: window.location.href, //required
        orderId: this.orderResponse.orderPaymentId,
        resources,
      })

      const bodyParams = {
        amount,
        currency: "EUR",
        returnUrl: window.location.href, //required
        orderId: this.orderResponse.orderPaymentId,
        paymentReference: this.orderDataId,
        resources: {
          ...resources,
          typeId: data.id
        }
      }

      const {data: paypalData} = await authorizeUnzerPayment(bodyParams)

      const {mode} = this.$root
      const orderQuantity = JSON.parse(sessionStorage.getItem('paymentId'))?.orderQuantity || 0
      sessionStorage.setItem('paymentId', JSON.stringify({
        paymentId: paypalData.resources.paymentId,
        orderQuantity: parseInt(orderQuantity) || 0,
        mode
      }))
      return {id: data.id, paymentId: paypalData.resources.paymentId, redirectUrl: paypalData.redirectUrl}
    },
    async authorizeSofort() {
      const {data} = await setSofort()
      return {id: data.id, paymentId: null, redirectUrl: null}
    }
  }
}

</script>
