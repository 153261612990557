<template>
  <div class="searchResult__pagination">
    <nav>
      <ul class="pagination">
        <li
            v-for="page in renderedPages"
            class="page-item"
            :class="`${page === selected?'selected':''}${page !== dots?' page-number':''}`"
            @click="updatePage(page !== dots, page)">
          <ion-icon v-if="page === dots" name="ellipsis-horizontal-outline"/>
          <span v-else>{{ page }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    pages: {
      type: Number,
      required: true
    },
    selected: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      maxPages: 7,
      lowIndex: 0,
      highIndex: 5,
      dots: 'dots'
    }
  },
  computed: {
    indexRange() {
      let lowIndex = 0, highIndex = 5

      if (this.arrayFromNumber[4] > this.selected) {
        lowIndex = 0;
        highIndex = 5;
      } else if (this.arrayFromNumber.at(-5) < this.selected) {
        lowIndex = this.arrayFromNumber.length - 5;
        highIndex = this.arrayFromNumber.length;
      } else {
        lowIndex = this.selected - 2;
        highIndex = this.selected + 1;
      }

      return {lowIndex, highIndex}
    },
    arrayFromNumber() {
      const pageArr = []
      for (let i = 0; i < this.pages; i++) pageArr.push(i + 1)
      return pageArr
    },
    renderedPages() {
      if (this.arrayFromNumber.length <= this.maxPages) {
        return this.arrayFromNumber
      } else {
        const {lowIndex, highIndex} = this.indexRange

        const res = [], showArr = this.arrayFromNumber.slice(lowIndex, highIndex)

        if (showArr.length === 3) {
          //middle
          res.push(this.arrayFromNumber[0])
          res.push("dots")
          res.push(...showArr)
          res.push("dots")
          res.push(this.arrayFromNumber.at(-1))
        } else {
          if (this.arrayFromNumber[showArr.length - 1] === showArr.at(-1)) {
            //first
            res.push(...showArr)
            res.push("dots")
            res.push(this.arrayFromNumber.at(-1))
          } else {
            //last
            res.push(this.arrayFromNumber[0])
            res.push("dots")
            res.push(...showArr)
          }
        }

        return res
      }

    }
  },
  methods: {
    updatePage(isEmit, page) {
      if (isEmit)
        this.$emit('update-page', page)
    }
  }
}
</script>

<style scoped>
.page-number {
  cursor: pointer;
}
</style>