function getPermissionMap(permission) {
    if (permission === "SHOW_PERSONALIZATION_ICONS") {
        return ["source", "monitoring", "bookmark", "settings"]
    }
    if (permission === "BACKOFFICE") {
        return ["demeter_settings"]
    }
    if (permission === "ACCOUNT_REGISTRATION") {
        return ["can_edit_registration_data"]
    }
    return [];
}

export const hasPermission = function (name) {
    let permissionList = window.sharedState.permissions

    let i = 0;

    while (i < permissionList.length) {
        if(getPermissionMap(permissionList[i]).includes(name))
        {
            return true;
        }

        i++;
    }
    return false;
 }