import axios from "axios";
import isIOSDevice from '../utils/isIOSDevice';
import {addLoader} from "../stylization";

export const shareHandler = async function (event) {
    event.preventDefault();
    const button = this || event.currentTarget;
    let documentId = button.dataset.legacyDocId || button.dataset.documentId;
    const print = button.dataset.print;
    let downloadLink = "/stream/saveDocuments?saveFormat=PDF";
    /* left this in case we want to switch back to demeter side rendering of PDFs
     if (documentId.includes("FIDO")) {
     downloadLink = "/stream/saveDocuments?saveFormat=PDF&uids=" + documentId;
     } else {
     downloadLink = "/exportPdfDownload/" + documentId;
     }
     */

    documentId.split(",").forEach(uid => downloadLink = downloadLink + "&uids=" + uid);
    await addLoader(event, "icon", async () => {
        const {data} = await axios.get(downloadLink, {responseType: 'blob'});
        const blob = new Blob([data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        if (print) {
            const iframe = document.createElement('iframe'); //load content in an iframe to print later
            iframe.style.position = "fixed";
            iframe.style.right = "0";
            iframe.style.bottom = "0";
            iframe.style.width = "0";
            iframe.style.height = "0";
            iframe.style.border = "0";
            iframe.src = url;
            iframe.onload = function () {
                const iframeRef = this;
                setTimeout(function () {
                    iframeRef.contentWindow.focus(); // Required for IE
                    iframeRef.contentWindow.print();
                }, 1000);
                setTimeout(function () {
                    document.body.removeChild(iframeRef);
                }, 60000);
            };
            document.body.appendChild(iframe);

            URL.revokeObjectURL(blob);
        } else {
            //window.open(url, "_blank");
            // Downloading the file and opening in a new tab
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;

            link.setAttribute('download', downloadLink);

            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // setTimeout(() => {
            //     // For firefax it is necessary to delay revoking the ObjectURL
            //     window.URL.revokeObjectURL(url);
            // }, 100);

            // link.href = url;
            // if (! isIOSDevice()) {
            //     link.target = "_blank";
            // }
            // //link.setAttribute('download', fileName);
            // document.body.appendChild(link);
            // link.click();
            // setTimeout(function () {
            //     document.body.removeChild(link);
            // }, 10000);
        }
    });
};


export const setupShareButton = function (node, docId) {
    const buttons = node.querySelectorAll('[js-handler*="articleContentShare"]');
    buttons.forEach(button => {
        if (!!button && !button.classList.contains('disabled') && !button.classList.contains("hasShareHandler")) {
            let documentId = docId || button.dataset.legacyDocId || button.dataset.documentId;
            if (!!documentId) {
                button.removeEventListener('click', shareHandler);
                button.addEventListener('click', shareHandler);
                button.classList.add("hasShareHandler");
            }
        }
    });
};
