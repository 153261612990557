import axios from "axios";
import {API_LOGIN, API_LOGIN_WEB_USER} from "./endpoints";

export const login = async data => {
    try {
        const {data: restData} = await axios.post(API_LOGIN, data, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer',
        });
        return restData;
    } catch (e) {
        console.log("authApi: login: error while log in ", e);
    }
}

export const loginWebUser = async data => {
    try {
        const {data: restData} = await axios.post(API_LOGIN_WEB_USER, data, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer',
        });
        return restData;
    } catch (e) {
        console.log("authApi: login: error while log in ", e);
    }
}