<template>
  <div class="payment__methods__body__element">
    <PaymentButton
        :icons="icons"
        :active-btn="activeBtn"
        :name="name"
        @execute="setActive($event)"
    />
    <CheckboxFormElement
        v-if="activeBtn === name"
        :element="checkboxObj"
        :bold-on-selection="true"
        :passedValue="checkboxObj.passedValue"
        @modified="checkboxObj.passedValue = $event"
    />
    <InvoiceFormElement
        v-show="checkboxObj.passedValue"
        :errors="errors"
        @update="updateForm"
    />
  </div>
</template>

<script>
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";
import InvoiceFormElement from "./InvoiceFormElement.vue";
import PaymentButton from "./PaymentButton.vue";

export default {
  name: "PaymentMethodElement",
  components: {
    CheckboxFormElement,
    InvoiceFormElement,
    PaymentButton
  },
  props: {
    activeBtn: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icons: {
      type: Array,
      required: true
    },
    checkboxObj: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  methods: {
    setActive({currentTarget}) {
      this.$emit("activate", currentTarget.name)
    },
    updateForm({name, value}) {
      this.$emit("updateForm", {name, value})
    },
  }
}
</script>