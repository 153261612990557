import axios from "axios";
import {API_GET_DOCUMENT_PRICES, API_GET_VIDEO_PRICES} from "./endpoints";
import {isUndefined} from "lodash";

const documentPricesPreparation = documentsList => {
    const isConfirmed = documentsList.every(item => !isUndefined(item.database) && !isUndefined(item.documentId) && !isUndefined(item.priceCategory))
    if (!isConfirmed)
        throw new ReferenceError("Missing required key [database, documentId, priceCategory]")

    return documentsList.map(item => {
        return {
            database: item.database,
            documentId: item.documentId,
            priceCategory: item.priceCategory
        }
    })
}

export const getDocumentPrices = async (documentsList) => {
    const filteredArr = documentPricesPreparation(documentsList)
    try {
        const {data} = await axios.post(API_GET_DOCUMENT_PRICES, filteredArr)
        return data
    } catch (e) {
        console.log("getDocumentPrices:method => ", e)
        console.dir(e)
    }
}

export const getDocumentPricesAsync = (documentsList, callback) => {
    const filteredArr = documentPricesPreparation(documentsList)
    axios
        .post(API_GET_DOCUMENT_PRICES, filteredArr)
        .then(({data}) => {
            callback(data)
        })
        .catch(e => {
            console.log("getDocumentPricesAsync:method => ", e)
            console.dir(e)
            return false
        })
    return true
}

export const getVideoPrices = async (request) => {
    try {
        const {data} = await axios.post(API_GET_VIDEO_PRICES, request);
        return data;
    } catch (e) {
        console.log("getVideoPrices:error => ", e.message)
        console.dir(e)
    }
}

export const getTableOfContents = async (requestPath, useDate) => {
    try {
        const {data} = await axios.get(requestPath, {
            params: {
                useDateForTableOfContents: useDate || "0"
            }
        });
        return data;
    } catch (e) {
        console.log("getTableOfContents:error => ", e.message)
        console.dir(e)
    }
}