<template>
  <div class="payment__steps">
    <div class="payment__steps__element" v-for="step in steps">
      <Button
          :text="step.text"
          :name="step.name"
          class="payment__step"
          :class="currentStep === step.name && 'active'"
          position="left"
          type="button"
          :disabled="step.disabled"
          @click="toggleActiveClass($event)"
      >
        <span class="circle">{{ step.index + 1 }}</span>
      </Button>
      <hr v-if="step.index !== steps.at(-1).index"
          :class="currentStepIndex > step.index && !step.disabled &&  'active'"/>
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";

export default {
  name: "Steps",
  components: {Button},
  props: {
    code: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: String,
      require: true
    }
  },
  computed: {
    currentStepIndex() {
      const enableSteps = this.steps.filter(item => !item.disabled)
      return enableSteps.length - 1
    }
  },
  methods: {
    toggleActiveClass(event) {
      const {currentTarget} = event
      const buttons = Array.from(this.$el.children).filter(item => item.tagName !== "HR")
      buttons.map(el => el.classList.remove('active'))
      currentTarget.classList.add('active')
      this.$emit('set-step', currentTarget.name)
    }
  }
}
</script>